import React from "react";
import ListGroup from "react-bootstrap/ListGroup"

import "../../App.css";

const UListGroup = ({
  items,
  selectedItem,
  textProperty,
  valueProperty,
  onItemSelect,
  horizontal
}) => {
  return (
    <ListGroup horizontal={horizontal?"sm":null} className="cursor-pointer">
      {items.map((item) => (
        <ListGroup.Item
          active={
            item === selectedItem
              ? "true"
              : null
          }
          onClick={() => onItemSelect(item)}
          key={item.name}
          style={{fontWeight: "bold", width: "100%", textAlign: "center"}}
        >
          {item[textProperty]}
      </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

UListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id",
  horizontal: null
};

export default UListGroup;
