import React from "react";
import Joi from "joi";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UForm from "./common/uform";

import SimpleSelect from "./common/simpleSelect";

import TagService from "../services/tagService";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

class TagAddFormModal extends UForm {
  state = {
    data: {
      name: "",
      description: "",
    },
    dev_types: [],
    selectedDevType: "",
    errors: {},
  };

  schema = Joi.object({
    name: Joi.string().min(1).max(100).required().optional().label("Nazwa").messages({
      "string.empty": `Nazwa Tagu nie może być pusta`,
      "string.max": `Nazwa może posiadać maksymalnie {#limit} znaków`,
      "any.required": `Nazwa Tagu nie może być pusta`,
    }),
    description: Joi.string()
      .empty()
      .max(200)
      .optional()
      .label("Opis")
      .allow(null)
      .allow("")
      .messages({
        "string.max": `"Nazwa może posiadać maksymalnie {#limit} znaków`,
      }),
  });

  componentDidMount = async () => {
    // const data = this.state.data;
    // this.setState({ data });
  };

  handleShow = () => {
    const { tagToEdit } = this.props;
    const data = this.state.data;

    let dev_types = [];

    if (tagToEdit) {
      dev_types = _.cloneDeep(tagToEdit.dev_types);
      data.name = tagToEdit.name;
      data.description = tagToEdit.description;
    } else {
      data.name = "";
      data.description = "";
    }

    const errors = {};
    const selectedDevType = "";
    this.setState({ data, selectedDevType, dev_types, errors });
  };

  doSubmit = async () => {
    const { data, dev_types } = this.state;
    const { tagToEdit } = this.props;
    console.log("Submit TAG!!!");

    const dataToSend = {
      name: data.name,
      dev_types,
      description: data.description,
    };

    try {
      if (tagToEdit) {
        const { data: updatedTag } = await TagService.update(tagToEdit._id,dataToSend);
        toast.success("TAG " + updatedTag.name + " został zmieniony!");
      } else {
        const { data: newTag } = await TagService.add(dataToSend);
        toast.success("Nowy TAG " + newTag.name + " został dodany!");
      }
      this.props.onHide();
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        toast.error(errors);
      }
    }
  };

  handleRemoveDevType = (dev_type) => {
    const { dev_types } = this.state;
    const index = dev_types.indexOf(dev_type);

    if (index === -1) return;
    dev_types.splice(index, 1);

    this.setState({ dev_types });
  };

  handleAddDevType = () => {
    const { selectedDevType, dev_types } = this.state;

    if (selectedDevType === "") return;

    const result = dev_types.find((dev_type) => dev_type === selectedDevType);
    if (result) return;
    dev_types.push(selectedDevType);
    this.setState({ dev_types });
  };

  handleSelectedDevTypeChange = (value) => {
    const { devTypes } = this.props;

    const selected = devTypes.find((type) => type.type === value);

    if (selected) {
      this.setState({ selectedDevType: selected.type });
    }
  };

  prepareOptions = () => {
    const { dev_types } = this.state;

    const result = [];
    this.props.devTypes.forEach((type) => {
      const x = dev_types.find((dev_type) => dev_type === type.type);
      if (!x) {
        if (type.type !== "") result.push(type);
      }
    });
    return result;
  };

  render() {
    //const { searchQuery, filteredShops: shops } = this.state;

    const { show, onHide, devTypes, tagToEdit } = this.props;
    const { data, dev_types, selectedDevType } = this.state;

    const options = this.prepareOptions();

    return (
      <Modal
        show={show}
        onShow={this.handleShow}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Dodaj Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            {this.renderInput("name", "Nazwa:")}

            {this.renderInput("description", "Opis:")}

            <div className="mt-3">
              <Form.Label>Typy urządzeń:</Form.Label>
              <div className="tag-container">
                {dev_types.map((dev_type) => {
                  return (
                    <div className="tag" key={dev_type}>
                      <div className="tag__name">{dev_type}</div>

                      <FontAwesomeIcon
                        onClick={() => this.handleRemoveDevType(dev_type)}
                        className="tag__delete"
                        icon={faTimes}
                      />
                    </div>
                  );
                })}
              </div>
              {options.length ? (
                <div className="tag-container">
                  <SimpleSelect
                    value={selectedDevType}
                    optionName="name"
                    optionKey="type"
                    options={this.prepareOptions()}
                    onChange={(e) => this.handleSelectedDevTypeChange(e.target.value)}
                  />

                  <FontAwesomeIcon
                    onClick={this.handleAddDevType}
                    className="tag__add ms-1"
                    size="2x"
                    icon={faPlusSquare}
                  />
                </div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} variant="primary">
              Anuluj
            </Button>
            <Button
              disabled={this.validate() || dev_types.length === 0 ? true : false}
              variant="primary"
              onClick={this.doSubmit}
            >
              {tagToEdit ? "Edytuj" : "Dodaj"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default TagAddFormModal;
