import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";

import { isAdminOrIsService, isAdmin } from "../../../services/authService";
import * as AuthDeviceService from "../../../services/authDeviceService";
import SimpleSelect from "../simpleSelect";
import ConfirmationIcon from "../confirmationIcon";
import dictionary from "../../../PL.json";

import ShopContext from "../../../contexts/shopContext";
import { prepareDateWithSec, prepareDate } from "../../../utils/dateFormat";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faTrash,
  faEye,
  faEyeSlash,
  faPlusSquare,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";

export default function CardDeviceWifiView({ device, wifis, refreshData }) {
  const shopContext = useContext(ShopContext);

  const [selectedWifi, setSelectedWifi] = useState("");
  const [edit, setEdit] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [pass, setPass] = useState(null);

  useEffect(() => {
    // setWifi(device?.tags);
  }, [device]);

  const handleVisibility = async () => {
    if (visibility === false)
      if (pass === null) {
        try {
          const { data } = await AuthDeviceService.getWifi(device._id);
          if (data) setPass(data.wifi_settings.pass);
        } catch (err) {
          toast.error("Błąd komunikacji z serwerem");
        }
      }
    setTimeout(() => {
      setVisibility(false);
    }, 2000);

    setVisibility(!visibility);
  };

  const handleSaveChanges = async () => {
    // try {
    //   const { data } = await AuthDeviceService.updateTags({
    //     _id: device._id,
    //     tags,
    //   });
    //   refreshData();
    //   setEdit(false);
    // } catch (err) {
    //   toast.error("Błąd komunikacji z serwerem");
    // }
  };

  const handleDelete = (_id) => {};

  const handleEdit = (newInfo) => {
    // if (newInfo.length < 100) setInfo(newInfo);
  };

  //   const infoDiffrent = info !== device.info;

  const prepareOptions = () => {
    const { wifis } = shopContext;

    const _wifisOptions = [];
    wifis.forEach((wifi) => {
      const _wifi = _.cloneDeep(wifi);
      _wifi.selectDescriptor = prepareDateWithSec(wifi.addedAt) + " " + _wifi.ssid;
      _wifisOptions.push(_wifi);
    });
    return _wifisOptions;
  };

  const handleRemove = async () => {
    try {
      const { data } = AuthDeviceService.clearWifi(device._id);
      refreshData();
      setEdit(false);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const handleTryAgain = async () => {
    try {
      const { data } = AuthDeviceService.retryWifi(device._id);
      refreshData();
      setEdit(false);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const addWifi = () => {
    if (selectedWifi === "") return false;

    try {
      const { data } = AuthDeviceService.updateWifi(device._id, selectedWifi);
      refreshData();
      setEdit(false);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const borderWifiInfo = () => {
    const style = { borderColor: "#888888" };
    if (
      device?.wifi_settings?.confirmed ||
      device.wifi_settings === null ||
      device.wifi_settings === undefined
    ) {
      style.backgroundColor = "rgb(10, 143, 17)";
      style.color = "rgb(255, 255, 255)";
    } else {
      style.backgroundColor = "rgb(208, 0, 0)";
      style.color = "rgb(255, 255, 255)";
    }

    return style;
  };

  const borderWifiSettings = () => {
    const style = {
      borderColor: "#888888",
      color: "rgb(255,255,255)",
      backgroundColor: "rgb(0,0,0)",
    };

    if (device?.wifi_settings?.switchingError === true) {
      style.backgroundColor = "rgb(208, 0, 0)";
      style.color = "rgb(255, 255, 255)";
    }

    return style;
  };

  return (
    <React.Fragment>
      <div className="flex-container container__center mt-2">
        <div className="me-2 my-auto fw-bold h-100">WIFI:</div>
        <div className="flex-container flex-container__column container__center">
          <div className="wifi" style={borderWifiInfo()}>
            <span className="fw-bold me-3">SSID: </span>
            <em className="me-3">{device?.wifi_info ? device.wifi_info?.ssid : device.ssid}</em>
            <em className="me-3">
              {device?.wifi_info?.addedAt && prepareDateWithSec(device?.wifi_info?.addedAt)}
            </em>
          </div>
          {device?.wifi_settings !== null && device?.wifi_settings !== undefined && (
            <div className="wifi" style={borderWifiSettings()}>
              <span className="fw-bold me-3">SSID: </span>{" "}
              <em className="me-3">{device?.wifi_settings?.ssid}</em>
              <em className="me-3">
                {device?.wifi_settings?.addedAt &&
                  prepareDateWithSec(device?.wifi_settings?.addedAt)}
              </em>
              {isAdmin() && (
                <React.Fragment>
                  <span className="fw-bold me-3">Hasło: </span>{" "}
                  <p className="p-0 m-0">{visibility ? pass : "••••••••••"}</p>
                  <FontAwesomeIcon
                    className="ms-2 "
                    onClick={() => handleVisibility()}
                    style={{ cursor: "pointer", margin: "auto" }}
                    size="1x"
                    icon={visibility ? faEyeSlash : faEye}
                  />
                  <em className="me-3">{device?.wifi_settings?.pass}</em>
                  <ConfirmationIcon
                    label={dictionary.shopView.cardDevice.wifiView.removeButton_confirmLabel}
                    yesLabel={dictionary.shopView.cardDevice.wifiView.removeButton_confirmYesLabel}
                    noLabel={dictionary.shopView.cardDevice.wifiView.removeButton_confirmNoLabel}
                    onClick={handleRemove}
                    className="tag__delete ms-1 me-2"
                    icon={faTrash}
                    size="1x"
                  />{" "}
                </React.Fragment>
              )}
              {isAdmin() && device?.wifi_settings?.switchingError === true && (
                <ConfirmationIcon
                  label={dictionary.shopView.cardDevice.wifiView.tryAgainButton_confirmLabel}
                  yesLabel={dictionary.shopView.cardDevice.wifiView.tryAgainButton_confirmYesLabel}
                  noLabel={dictionary.shopView.cardDevice.wifiView.tryAgainButton_confirmNoLabel}
                  onClick={handleTryAgain}
                  className="icon__retry ms-2"
                  icon={faRetweet}
                  size="1x"
                />
              )}
            </div>
          )}
        </div>

        {isAdmin() && (
          <FontAwesomeIcon onClick={() => setEdit(!edit)} className="tag__edit" icon={faEdit} />
        )}
      </div>
      {shopContext.tags.length && edit ? (
        <div className="tag-container flex-container__center w-100 mb-3">
          <SimpleSelect
            style={{ width: "auto" }}
            value={selectedWifi}
            optionName="selectDescriptor"
            optionKey="_id"
            options={prepareOptions()}
            onChange={(e) => setSelectedWifi(e.target.value)}
          />

          <ConfirmationIcon
            label={dictionary.shopView.cardDevice.wifiView.addButton_confirmLabel}
            yesLabel={dictionary.shopView.cardDevice.wifiView.addButton_confirmYesLabel}
            noLabel={dictionary.shopView.cardDevice.wifiView.addButton_confirmNoLabel}
            onClick={addWifi}
            className="tag__add ms-1"
            icon={faPlusSquare}
            size="2x"
          />
        </div>
      ) : null}
    </React.Fragment>
  );
}
