import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import CardDeviceHeader from "./cardDevice/cardDeviceHeader";
import CardDeviceButtonsPanel from "./cardDevice/cardDeviceButtonsPanel";
import CardDeviceNameView from "./cardDevice/cardDeviceNameView";
import CardDeviceTagView from "./cardDevice/cardDeviceTagView";
import CardDeviceWifiView from "./cardDevice/cardDeviceWifiView";
import CardDeviceFooter from "./cardDevice/cardDeviceFooter";

import VolumeSettingsBlockOld from "./confControl/volumeSettingsBlockOld";
import VolumeSettingsBlock from "./confControl/volumeSettingsBlock";

import * as wsCommunication from "../../utils/wsCommunication";

import * as AuthDeviceService from "../../services/authDeviceService";
import * as deviceConf from "../../utils/conf/deviceConf";
import {toast} from "react-toastify";

import config from "../../config.json";
import Form from "react-bootstrap/Form";
import SimpleSelect from "./simpleSelect";
import ConfirmationIcon from "./confirmationIcon";
import dictionary from "../../PL.json";
import {faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import VolumeSettingsBlockNumberInput from "./confControl/volumeSettingsBlockNumberInput";
import VolumeSettingsBlockTableHeaderNumberInput from "./confControl/volumeSettingsBlockTableHeaderNumberInput";
import VolumeToAll from "./confControl/volumeToAll";
import {deviceMessage} from "../../utils/wsCommunication";

class CardDeviceMA extends Component /* ({ device, online, connectionStatus })  */ {
    state = {
        expanded: false,
        volume: 0,
        audioEnable: false,
        spkVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        spkName: ["Kanał 1:", "Kanał 2:", "Kanał 3:", "Kanał 4:", "Kanał 5:", "Kanał 6:", "Kanał 7:", "Kanał 8:", "Kanał 9:", "Kanał 10:", "Kanał 11:", "Kanał 12:"],
        spkAudioEnable: [false, false, false, false, false, false, false, false, false, false, false, false],
        spkZone: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        playlistEnable: false,
        playlistIMSEnable: false,
        spkPlaylistVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        spkPlaylistAudioEnable: [false, false, false, false, false, false, false, false, false, false, false, false],
    };

    componentDidMount = () => {
        const {device} = this.props;

        this.updateSettings(device);
    };


    updateSettings(device) {
        const {
            volume,
            audioEnable,
            spkVolume,
            spkName,
            spkAudioEnable,
            spkZone,
            playlistEnable,
            playlistIMSEnable,
            spkPlaylistVolume,
            spkPlaylistAudioEnable
        } = deviceConf.AudioDeviceSettingsGenerateAndFillWithData(device);

        this.setState({
            volume,
            audioEnable,
            spkVolume,
            spkName,
            spkAudioEnable,
            spkZone,
            playlistEnable,
            playlistIMSEnable,
            spkPlaylistVolume,
            spkPlaylistAudioEnable
        });
    }

    handleVolumeChangeOld = (volume) => {
        this.setState({
            volume: Number(volume),
        });
    };

    handleEnableChangeOld = (value) => {
        this.setState({
            audioEnable: value,
        });
    };

    handlePlaylistEnableChange = (value) => {
        if(value === true)
        {
            this.setState({
                playlistIMSEnable: false,
            });
        }

        this.setState({
            playlistEnable: value,
        });
    };

    handlePlaylistIMSEnableChange = (value) => {

        if(value === true)
        {
            this.setState({
                playlistEnable: false,
            });
        }

        this.setState({
            playlistIMSEnable: value,
        });
    };


    handleVolumeChange = (i, volume) => {
        const {spkVolume} = this.state;
        spkVolume[i] = Number(volume);
        this.setState({
            spkVolume,
        });
    };

    handleEnableChange = (i, value) => {
        const {spkAudioEnable} = this.state;

        spkAudioEnable[i] = value;

        this.setState({
            spkAudioEnable,
        });

        // console.log(this.state.spkAudioEnable[i]);
    };

    handleZoneChange = (zoneType, spkNo, isChecked) => {
        const {spkZone} = this.state;


        if (isChecked) {

            spkZone[spkNo] = zoneType;
            this.setState({spkZone});
        }
    }

    handlePlaylistVolumeChange = (i, volume) => {
        const {spkPlaylistVolume} = this.state;
        spkPlaylistVolume[i] = Number(volume);
        this.setState({
            spkPlaylistVolume,
        });
    };

    handlePlaylistEnableSpkChange = (i, value) => {
        const {spkPlaylistAudioEnable} = this.state;

        spkPlaylistAudioEnable[i] = value;

        this.setState({
            spkPlaylistAudioEnable,
        });

        // console.log(this.state.spkAudioEnable[i]);
    };

    handleNameChange = (i, value) => {
        const {spkName} = this.state;
        spkName[i] = value;
        this.setState({
            spkName,
        });
    };

    handleSendConfiguration = async () => {
        const {device} = this.props;
        const {
            spkVolume,
            spkAudioEnable,
            spkName,
            spkZone,
            playlistEnable,
            playlistIMSEnable,
            spkPlaylistVolume,
            spkPlaylistAudioEnable
        } = this.state;

        let conf = {};
        if (device.hw_version?.length < 6) {
            conf = {
                spkVolume: spkVolume.slice(0, 4),
                spkAudioEnable: spkAudioEnable.slice(0, 4),
                spkName: spkName.slice(0, 4),
                spkZone: spkZone.slice(0, 4),
                playlistEnable: playlistEnable,
                playlistIMSEnable: playlistIMSEnable,
                spkPlaylistVolume: spkPlaylistVolume.slice(0, 4),
                spkPlaylistAudioEnable: spkPlaylistAudioEnable.slice(0, 4),
            };
        } else {
            let number = Number(device.hw_version?.split("_")[1])

            conf = {
                spkVolume: spkVolume.slice(0, number),
                spkAudioEnable: spkAudioEnable.slice(0, number),
                spkName: spkName.slice(0, number),
                spkZone: spkZone.slice(0, number),
                playlistEnable: playlistEnable,
                playlistIMSEnable: playlistIMSEnable,
                spkPlaylistVolume: spkPlaylistVolume.slice(0, number),
                spkPlaylistAudioEnable: spkPlaylistAudioEnable.slice(0, number),
            };
        }
        try {

            const {data} = await AuthDeviceService.postConf({
                _id: this.props.device._id,
                conf,
            });

            wsCommunication.forceStatusMessage(this.props.device.sn, this.props.onMessageToSent);

            toast.success("Nowe ustawienia zostały zapisane!");

            this.updateSettings(data);
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    };


    handleDeleteAllMusicFiles = async () => {
        const {device} = this.props;
        try {
            const {data} = await AuthDeviceService.deleteAllMusicFiles(device._id);

            wsCommunication.forceStatusMessage(this.props.device.sn, this.props.onMessageToSent);

            toast.success("Polecenie usunięcia plików zostało wysłane!");
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    }

    handleForceIMSPlaylistsUpdate = async () => {
        const {device} = this.props;
        try {
            wsCommunication.deviceMessage(this.props.device.sn,"ims_update", this.props.onMessageToSent);

            toast.success("Prośba została wysłana!");
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    }

    handleDemoAction = (data) => {
        wsCommunication.demoMessage(
            this.props.device.sn,
            data,
            this.props.onMessageToSent,
            this.props.onMessageToSent
        );
    };

    handleDelete = async () => {
        const {data} = await AuthDeviceService.remove(this.props.device._id);

        this.props.forceDataRefresh();
    };

    handleExpand = () => {
    };
    handleChangeVolumeOnAllChannels = (volume) => {
        const {spkVolume} = this.state;
        console.log(volume)
        spkVolume.map((item, index) => {
            spkVolume[index] = Number(volume);
        })
        this.setState({
            spkVolume,
        });
    };

    handleChangePlaylistVolumeOnAllChannels = (volume) => {
        const {spkPlaylistVolume} = this.state;
        console.log(volume)
        spkPlaylistVolume.map((item, index) => {
            spkPlaylistVolume[index] = Number(volume);
        })
        this.setState({
            spkPlaylistVolume,
        });
    };


    render() {
        const {device, onlineDeviceData} = this.props;
        const {
            expanded,
            volume,
            audioEnable,
            spkVolume,
            spkAudioEnable,
            spkName,
            spkZone,
            playlistEnable,
            playlistIMSEnable,
            spkPlaylistAudioEnable,
            spkPlaylistVolume
        } = this.state;

        let number = 4;
        if (device.hw_version?.split("_").length > 1)
            number = Number(device.hw_version?.split("_")[1])

        return (
            <Card className="mb-2 cursor-pointer" style={{minWidth: "800px"}}>
                <CardDeviceHeader
                    expanded={expanded}
                    onlineDeviceData={onlineDeviceData}
                    onAction={this.handleDemoAction}
                    device={device}
                    onExpandedClick={() => {
                        this.setState({expanded: ~expanded});
                    }}
                />

                {expanded ? (
                    <React.Fragment>
                        <Card.Body>
                            <CardDeviceNameView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceNameView>
                            <CardDeviceTagView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceTagView>
                            <CardDeviceWifiView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceWifiView>

                            <hr style={{width: "100%", margin: "auto", background: "black"}}></hr>

                            <Table responsive="xs" style={{width: "90%", margin: "auto"}}>
                                <tbody>
                                {number > 6 &&
                                    <VolumeSettingsBlockTableHeaderNumberInput
                                        volumeMinMaxLabel={"Głośność " + `(${config.audioLevels.min}-${config.audioLevels.max})`}/>
                                }
                                {number <= 6 &&
                                    <VolumeSettingsBlockTableHeaderNumberInput
                                        volumeMinMaxLabel={"Głośność " + "(0-16)"}
                                        extraColumnVolumeValueVisible={true}
                                    />
                                }

                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {


                                    if (
                                        i > (number - 1)
                                    ) {
                                        return null;
                                    }
                                    if (number > 6) {
                                        return (
                                            <VolumeSettingsBlockNumberInput
                                                key={"" + device.sn + "vol" + i}
                                                label={spkName[i]}
                                                device={device}
                                                value={{
                                                    spkNo: i,
                                                    volume: spkVolume[i],
                                                    enable: spkAudioEnable[i],
                                                    zone: spkZone !== undefined ? spkZone[i] : 0,
                                                }}
                                                onEnableChange={this.handleEnableChange}
                                                onVolumeChange={this.handleVolumeChange}
                                                onNameChange={this.handleNameChange}
                                                onZoneChange={this.handleZoneChange}
                                                editLabelDisabled={false}
                                            />
                                        )
                                    } else {

                                        return (
                                            <VolumeSettingsBlock
                                                key={"" + device.sn + "vol" + i}
                                                label={spkName[i]}
                                                device={device}
                                                value={{
                                                    spkNo: i,
                                                    volume: spkVolume[i],
                                                    enable: spkAudioEnable[i],
                                                    zone: spkZone !== undefined ? spkZone[i] : 0,
                                                }}
                                                onEnableChange={this.handleEnableChange}
                                                onVolumeChange={this.handleVolumeChange}
                                                onNameChange={this.handleNameChange}
                                                onZoneChange={this.handleZoneChange}
                                                editLabelDisabled={false}
                                            />
                                        );
                                    }
                                })}
                                <VolumeToAll
                                    onSave={this.handleChangeVolumeOnAllChannels}
                                    extraColumnVolumeValueVisible={number <= 6}/>
                                </tbody>
                            </Table>

                            <div className="tag-container flex-container__center "
                                 style={{width: "90%", margin: "auto"}}>
                                <div className="tag-container  w-25 mb-1">
                                    <p className="m-2 fw-bold">Odtwarza:</p>
                                </div>
                                <div className="tag-container flex-container__center w-25">

                                    <p className="m-2 fw-bold">
                                        playlistę
                                    </p>
                                    <Form.Check
                                        type="switch"
                                        id={device.sn + "en_playlist"}
                                        className="m-2"
                                        checked={playlistEnable}
                                        onChange={(e) => this.handlePlaylistEnableChange(e.target.checked)}
                                    />
                                </div>
                                <div className="tag-container flex-container__center w-25 ">
                                    <p className="m-2 fw-bold">lub</p>
                                </div>
                                <div className="tag-container flex-container__center w-25 ">
                                    <p className="m-2 fw-bold">
                                        IMS
                                    </p>
                                    <Form.Check
                                        type="switch"
                                        id={device.sn + "enIms_playlist"}
                                        className="m-2"
                                        checked={playlistIMSEnable}
                                        onChange={(e) => this.handlePlaylistIMSEnableChange(e.target.checked)}
                                    />
                                </div>
                                <hr style={{width: "100%", margin: "0px", background: "black"}}></hr>

                            </div>
                            {(playlistEnable || playlistIMSEnable) &&

                                <Table responsive="xs" style={{width: "90%", margin: "auto"}}>
                                    <tbody>
                                    {number > 6 &&
                                        <VolumeSettingsBlockTableHeaderNumberInput
                                            volumeMinMaxLabel={"Głośność " + `(${config.audioLevels.min}-${config.audioLevels.max})`}/>
                                    }
                                    {number <= 6 &&
                                        <VolumeSettingsBlockTableHeaderNumberInput
                                            volumeMinMaxLabel={"Głośność " + "(0-16)"}
                                            extraColumnVolumeValueVisible={true}
                                        />
                                    }

                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {
                                        let number = 4;
                                        if (device.hw_version?.split("_").length > 1)
                                            number = Number(device.hw_version?.split("_")[1])

                                        if (
                                            i > (number - 1)
                                        ) {
                                            return null;
                                        }

                                        if (number > 6) {
                                            return (
                                                <VolumeSettingsBlockNumberInput
                                                    key={"" + device.sn + "vol_playlist" + i}
                                                    label={spkName[i]}
                                                    device={device}
                                                    value={{
                                                        spkNo: i,
                                                        volume: spkPlaylistVolume[i],
                                                        enable: spkPlaylistAudioEnable[i],
                                                        zone: spkZone !== undefined ? spkZone[i] : 0,
                                                    }}
                                                    onEnableChange={this.handlePlaylistEnableSpkChange}
                                                    onVolumeChange={this.handlePlaylistVolumeChange}
                                                    editLabelDisabled={true}
                                                    // onNameChange={this.handleNameChange}
                                                />
                                            );
                                        } else {
                                            return (
                                                <VolumeSettingsBlock
                                                    key={"" + device.sn + "vol_playlist" + i}
                                                    label={spkName[i]}
                                                    device={device}
                                                    value={{
                                                        spkNo: i,
                                                        volume: spkPlaylistVolume[i],
                                                        enable: spkPlaylistAudioEnable[i],
                                                        zone: spkZone !== undefined ? spkZone[i] : 0,
                                                    }}
                                                    onEnableChange={this.handlePlaylistEnableSpkChange}
                                                    onVolumeChange={this.handlePlaylistVolumeChange}

                                                    editLabelDisabled={true}
                                                    // onNameChange={this.handleNameChange}
                                                />
                                            );
                                        }
                                    })}
                                    <VolumeToAll
                                        onSave={this.handleChangePlaylistVolumeOnAllChannels}
                                        extraColumnVolumeValueVisible={number <= 6}/>

                                    </tbody>
                                </Table>
                            }
                            {/*<hr style={{ width: "100%", margin: "auto", background: "black" }}></hr>*/}

                            <CardDeviceButtonsPanel
                                onDelete={this.handleDelete}
                                onSendConfiguration={this.handleSendConfiguration}
                                onDeleteAllMusicFiles={this.handleDeleteAllMusicFiles}
                                onForceIMSPlaylistsUpdate={this.handleForceIMSPlaylistsUpdate}
                            />
                        </Card.Body>
                    </React.Fragment>
                ) : null}

                <CardDeviceFooter
                    device={device}
                    onlineDeviceData={onlineDeviceData}
                    onChangeConf={this.handleDemoAction}
                />
            </Card>
        );
    }
}

export default CardDeviceMA;
