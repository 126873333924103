import React from "react";
import Table from "react-bootstrap/Table";

import DistanceSettingsControl from "./distanceSettingsControl";
import DistanceSettingsControlMK from "./distanceSettingsControlMK";
import config from "../../../config.json";

export default function DistanceSettingsBlock({
  device,
  sensorCashier,
  sensorClient,
  sensorCeiling,
  onActiveMinRangeChange,
  onActiveRangeChange,
  onSensorEnableChange,
  onBlockSensorChange
}) {
  return (
    <Table responsive="xs" style={{ width: "90%", margin: "auto" }}>
      <tbody>
        {sensorCashier && (
          <DistanceSettingsControlMK
            label="CZUJNIK KASJER"
            id={config.distanceSensorTypes.cashier_ID + device.sn}
            value={sensorCashier}
            onActiveMinRangeChange={onActiveMinRangeChange}
            activeMinRangeChange={true}
            onActiveRangeChange={onActiveRangeChange}
            onEnableChange={onSensorEnableChange}
            onBlockSensorChange={onBlockSensorChange}
            sensorDisabling={true}
          />
        )}

        {sensorClient && (
          <DistanceSettingsControlMK
            label="CZUJNIK KLIENT"
            id={config.distanceSensorTypes.client_ID + device.sn}
            value={sensorClient}
            onActiveMinRangeChange={onActiveMinRangeChange}
            activeMinRangeChange={true}
            onActiveRangeChange={onActiveRangeChange}
            onEnableChange={onSensorEnableChange}
            onBlockSensorChange={onBlockSensorChange}
            sensorDisabling={true}
          />
        )}

        {sensorCeiling && (
          <DistanceSettingsControl
            label="CZUJNIK SUFITOWY:"
            id={config.distanceSensorTypes.ceiling_ID + device.sn}
            value={sensorCeiling}
            onActiveMinRangeChange={onActiveMinRangeChange}
            activeMinRangeChange={false}
            onActiveRangeChange={onActiveRangeChange}
            onEnableChange={onSensorEnableChange}
            onBlockSensorChange={onBlockSensorChange}
            sensorDisabling={false}
          />
        )}
      </tbody>
    </Table>
  );
}
