import React from "react";
import Form from "react-bootstrap/Form";

const SearchBox = ({
                       value,
                       onChange,
                       label = "",
                       placeholder = "Szukaj...",
                   }) => {
    return (
        <Form.Group controlId="query">
            {label !== "" &&
                <Form.Label>{label}</Form.Label>
            }
            <Form.Control
                type='text'
                name="query"
                onChange={(e) => onChange(e.currentTarget.value)}
                value={value}
                placeholder={placeholder}
            />
        </Form.Group>


    );
};

export default SearchBox;
