import http from "./httpService";

const apiEndpoint = "/fwfiles";

export function add(data) {
  return http.post(apiEndpoint, data);
}

// function get(_id){
//     // return http.get(apiEndpoint+"/"+_id);
// }

export function getAll() {
  return http.get(apiEndpoint);
}

export function putSetCurrent(_id) {
  return http.put(apiEndpoint + "/setCurrent/" + _id);
}

export function putUnsetCurrent(_id) {
  return http.put(apiEndpoint + "/unsetCurrent/" + _id);
}

export function remove(_id) {
  return http.delete(apiEndpoint + "/" + _id);
}

export function upload(fwFile) {
  http
    .get(apiEndpoint + "/" + fwFile._id, { responseType: "blob" })
    .then((response) => {
      if (response.status === 200) {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let a = document.createElement("a");
        a.href = url;
        a.download = fwFile.version + ".bin";
        a.click();
      }
    });
}
