import {useEffect, useState} from "react";
import PathService from "../services/pathService";
import MusicFilesService from "../services/musicFilesService";
import {toast, ToastContainer} from "react-toastify";
import {Button, Container, Row, Spinner} from "react-bootstrap";
import UploadFiles from "./common/UploadFiles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faXmark} from "@fortawesome/free-solid-svg-icons";
import Filesystem from "./Filesystem";
import AddFolder from "./modals/addFolder";
import Dropdown from "react-bootstrap/Dropdown";
import {faListAlt, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import Col from "react-bootstrap/Col";
import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

function MusicFilesManager({
                               show,
                               onClose,
                               playlistToShow,
                               onAddToPlaylist,
                               onRemoveFromPlaylist,
                               allFiles,
                               refreshFiles,
                               setAllFiles
                           }) {
    const [showFolderContextMenu, setShowFolderContextMenu] = useState(null);
    const [showFileContextMenu, setShowFileContextMenu] = useState(null);

    const [menuXposition, setMenuXposition] = useState(0);
    const [menuYposition, setMenuYposition] = useState(0);

    const [pathStack, setPathStack] = useState([]);
    const [currentPath, setCurrentPath] = useState(null);
    const [currentPathFolders, setCurrentPathFolders] = useState([]);
    const [allFolders, setAllFolders] = useState([]);
    const [showAddFolderModal, setShowAddFolderModal] = useState(false);

    const [loadingData, setLoadingData] = useState(false);

    const uploadRootPath = async () => {
        let result = await PathService.getRoot()

        if (result.data.length > 0) {
            setCurrentPath(result.data[0]);
            // result = await PathService.get(result.data[0]._id);
            result = await PathService.getAll();

            setAllFolders(result.data);
        }
    }

    const uploadFiles = async () => {
        await refreshFiles();
    }

    useEffect(() => {
        console.log("App.js useEffect");


        try {
            uploadRootPath();
            uploadFiles();
        } catch (e) {
            console.log(e);
        }


    }, []);

    useEffect(() => {
        console.log("Playlist change")
    }, [playlistToShow]);

    useEffect(() => {
        setCurrentPathFolders(allFolders.filter(folder => folder.parent === currentPath._id));
    }, [currentPath, allFolders]);

    const handleAddFolder = async (name) => {
        try {
            let result = await PathService.add({name: name, parent: currentPath._id});

            if (result.data) {
                result = await PathService.getAll();
                setAllFolders(result.data);
            } else {
                toast.error("Taka ścieżka już istnieje!!!");
            }
        } catch (e) {
            if (e.response && e.response.status === 409) {
                toast.error("Taka ścieżka już istnieje!!!");
            } else {

            }
        }
    }

    const handleRemoveFolder = async (id) => {
        if ((id === null) || (id === undefined)) return;

        try {
            let result = await PathService.remove(id);

            if (result.data) {
                result = await PathService.getAll();
                setAllFolders(result.data);
            }
        } catch (e) {
            toast.error("Błąd serwera!");

        }
    }

    const handleRemoveFile = async (id) => {
        if ((id === null) || (id === undefined)) return;

        try {
            let {data} = await MusicFilesService.remove(id);
            if (data._id && data._id === id) {
                let newFiles = allFiles.filter(file => file._id !== id);
                setAllFiles(newFiles);
            }
        } catch (e) {
            toast.error("Błąd serwera!");

        }
    }

    const handleFolderClick = (e, _id) => {
        setShowFolderContextMenu(null);
        e.stopPropagation();
        e.preventDefault();
        if (e.type === 'click') {
            setCurrentPath(allFolders.find(folder => folder._id === _id));
        } else if (e.type === 'contextmenu') {

            setMenuXposition(e.clientX);
            setMenuYposition(e.clientY);
            setShowFolderContextMenu({id: _id, type: "folder"});
        }
    }

    const handleFileClick = (e, _id) => {
        setShowFileContextMenu(null);
        e.stopPropagation();
        e.preventDefault();
        if (e.type === 'click') {
            console.log(_id);
            if (playlistToShow.files.findIndex(file => file === _id) === -1)
                onAddToPlaylist(_id)
            else
                onRemoveFromPlaylist(_id)
            //akcja na kliknięcie pliku
            // setCurrentPath(allFiles.find(file => file._id === _id));
        } else if (e.type === 'contextmenu') {

            setMenuXposition(e.clientX);
            setMenuYposition(e.clientY);
            setShowFileContextMenu({id: _id, type: "file"});
        }
    }

    const handleClickAnywhere = (e) => {
        console.log("Click anywhere");
        setShowFolderContextMenu(null);
        setShowFileContextMenu(null);
    }

    const handleGoBack = () => {
        if (currentPath.parent !== null) {
            let parent = allFolders.find(folder => folder._id === currentPath.parent);
            setCurrentPath(parent);
        }
    }

    const handleSetProgress = (progress) => {
        setLoadingData(progress);
    }

    const handleForceRefresh = async () => {
        await uploadFiles();
    }
    if (show)
        return (

            <Container fluid style={{
                height: "100%",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                top: "0px",
                left: "0px",
                backgroundColor: "rgba(0,0,0,0.9)",
            }} onClick={handleClickAnywhere} onContextMenu={handleClickAnywhere}>
                {loadingData &&
                    <div className={"spinner"}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>

                }

                <FontAwesomeIcon className={" on_close"}

                                 onClick={onClose}
                                 icon={faXmark} size={"2x"}/>

                <div className="w-75  m-auto p-2 rounded-1 " style={{
                    backgroundColor: "white",
                    height: "90%",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Col>
                        By dodać lub usunąć plik z playlisty należy kliknąć na wybrany plik. Pliki dołączone do
                        playlisty będą zaznaczone na zielono.
                        By dodać nowe pliki do bazy danych należy wybrać odpowiedni folder a następnie kliknąć kliknać
                        przycisk dodawania plików lub przeciągnąć pliki w pole dodawania plików.
                    </Col>
                    <UploadFiles className="w-100 m-auto p-0" currentPath={currentPath} setProgress={handleSetProgress}
                                 forceRefresh={handleForceRefresh}/>

                    <Row className={"w-100 m-auto mt-2"}
                         style={{alignItems: "center", justifyContent: "space-between", flexDirection: "row-reverse"}}>

                        <Col sm={2} className={"m-0 p-0"}>
                            <Button className={"w-100 rounded-1 m-0"} variant="outline-secondary"
                                    onClick={() => setShowAddFolderModal(true)}>
                                {/*<FontAwesomeIcon className={"mx-1"}*/}
                                {/*                 icon={faPlus} size={"1x"}/>*/}
                                Dodaj folder
                            </Button>
                        </Col>


                        {currentPath?.parent !== null && currentPath?.parent !== undefined &&
                            <Col sm={2} className={"m-0 p-0"}>
                                <Button className={"w-100 rounded-1 m-0"} variant="outline-secondary"
                                        onClick={handleGoBack}>
                                    <FontAwesomeIcon className={"mx-1"}
                                                     icon={faArrowLeftLong} size={"1x"}/>
                                </Button>
                            </Col>}
                    </Row>

                    {/*{currentPath?.parent !== null && currentPath?.parent !== undefined &&*/}
                    {/*    <Button className={"rounded-1 m-2"} onClick={handleGoBack}>*/}
                    {/*        <FontAwesomeIcon className={"mx-1"}*/}
                    {/*                         icon={faArrowLeftLong} size={"1x"}/>*/}
                    {/*    </Button>}*/}


                    <Filesystem path={currentPath} folders={currentPathFolders} files={allFiles}
                                filesSelected={playlistToShow.files}
                                onFolderClick={handleFolderClick}
                                onFileClick={handleFileClick}
                                className="w-100 m-auto mt-1"
                                style={{overflowY: "auto", flexBasis: "500px"}}
                    />
                    <AddFolder show={showAddFolderModal} onClose={() => setShowAddFolderModal(false)}
                               addFolder={handleAddFolder}/>

                    <Dropdown style={{position: "absolute", top: menuYposition, left: menuXposition}}
                              show={showFolderContextMenu !== null}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {
                                handleRemoveFolder(showFolderContextMenu?.id)
                            }}> <FontAwesomeIcon className={"mx-1"}
                                                 icon={faTrashAlt} size={"1x"}/> Usuń</Dropdown.Item>
                            {/*<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*/}
                            {/*<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*/}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown style={{position: "absolute", top: menuYposition, left: menuXposition}}
                              show={showFileContextMenu !== null}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {
                                // handleRemoveFile(showFileContextMenu?.id)
                            }}> <FontAwesomeIcon className={"mx-1"}
                                                 icon={faListAlt} size={"1x"}/> Dodaj do playlisty</Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                handleRemoveFile(showFileContextMenu?.id)
                            }}> <FontAwesomeIcon className={"mx-1"}
                                                 icon={faTrashAlt} size={"1x"}/> Usuń</Dropdown.Item>
                            {/*<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*/}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Container>
        );
    else return null;

}

export default MusicFilesManager;