import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import config from "../../../config.json";
import dictionary from "../../../PL.json";
import ConfirmationButton from "../confirmationButton";


let commonStyle = {
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0",
    padding: "0",
};
export default function VolumeToAll({
                                        onSave, extraColumnVolumeValueVisible
                                    }) {

    const [volume, setVolume] = React.useState(0);
    const handleVolumeChange = (volume) => {
        if (volume >= config.audioLevels.min && volume <= config.audioLevels.max)
            setVolume(volume);
    }

    return (
        // <Table responsive="xs" style={{width: "90%", margin: "auto"}}>
        //     <tbody>
        <tr>

            <td
                style={{
                    verticalAlign: "middle",
                    textAlign: "right",
                    minWidth: "100px",
                    margin: "0",
                    padding: "0",
                    fontWeight: "bold"
                }}
            >
            </td>

            <td
                style={{
                    ...commonStyle,
                }}
            >

            </td>
            <td
                style={{
                    ...commonStyle,
                }}
            >

            </td>
            <td
                style={{
                    ...commonStyle,
                    minWidth: "50px",
                }}
            >

            </td>
            <td
                style={{
                    textAlign: "center",
                    minWidth: "100px",
                    margin: "0",
                    padding: "0",
                    fontWeight: "bold"
                }}
            >

            </td>

            <td
                style={{
                    textAlign: "center",
                    minWidth: "200px",
                    width: "auto",
                    height: "auto",
                }}
            >
                <div className="m-2" style={{width: "100%"}}><input
                    type="number"
                    min="0"
                    max="128"
                    key="1"
                    value={volume}
                    onChange={({currentTarget: input}) =>
                        handleVolumeChange(input.value)
                    }
                />
                </div>
            </td>
            {extraColumnVolumeValueVisible === true &&
                <td
                    style={{
                        textAlign: "center",
                        minWidth: "100px",
                        margin: "0",
                        padding: "0",
                        fontWeight: "bold"
                    }}
                >

                </td>
            }

            <td style={{textAlign: "center", justifyItems: "center", alignItems: "center", verticalAlign: "middle",}}>
                <ConfirmationButton
                    label={dictionary.shopView.cardDevice.settings.saveToAll_confirmLabel}
                    yesLabel={dictionary.shopView.cardDevice.settings.saveToAll_confirmYesLabel}
                    noLabel={dictionary.shopView.cardDevice.settings.saveToAll_confirmNoLabel}
                    variant="secondary"
                    onClick={() => onSave(volume)}
                >
                    {dictionary.shopView.cardDevice.settings.saveToAll_label}
                </ConfirmationButton>
            </td>
        </tr>
        //     </tbody>
        // </Table>
    );
}
