import React, {Component, useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {toast} from "react-toastify";

import CardDeviceHeader from "./cardDevice/cardDeviceHeader";
import CardDeviceButtonsPanel from "./cardDevice/cardDeviceButtonsPanel";
import CardDeviceNameView from "./cardDevice/cardDeviceNameView";
import CardDeviceTagView from "./cardDevice/cardDeviceTagView";
import CardDeviceWifiView from "./cardDevice/cardDeviceWifiView";

import CardDeviceFooter from "./cardDevice/cardDeviceFooter";
import BeepTableHeader from "./confControl/beepSettingsTableHeader";
import BeepSettingsControl from "./confControl/beepSettingsControl";
import DistanceSettingsBlock from "./confControl/distanceSettingsBlock";
import DetectionTimeoutSettingsControl from "./confControl/detectionTimeoutSettingsControl";

import * as beepOnChange from "../../utils/conf/beepOnChangeActions";
import * as sensorSettingsOnChange from "../../utils/conf/distanceSensorSettingsOnChangeAction";

import * as wsCommunication from "../../utils/wsCommunication";

import * as AuthDeviceService from "../../services/authDeviceService";
import * as deviceConf from "../../utils/conf/deviceConf";

import config from "../../config.json";
import CardDeviceAlwaysActiveView from "./cardDevice/cardDeviceAlwaysActiveView";
import {activeRangeMK} from "../../utils/conf/distanceSensorSettingsOnChangeAction";
import {DistanceSensorTableGenerateAndFillWithDataMK, timeFillWithData} from "../../utils/conf/deviceConf";
import TimeSettingsControl from "./confControl/timeSettingsControl";
import BoolValueControl from "./confControl/boolValueControl";




function CardDeviceMD({device, forceDataRefresh, onlineDeviceData, onMessageToSent}) {

    const [expanded, setExpanded] = useState(false);
    const [enable, setEnable] = useState(false);
    const [doseDuration, setDoseDuration] = useState(0);
    const [doseInterval, setDoseInterval] = useState(0);
    const [doseDurationDuringShopCloseHours, setDoseDurationDuringShopCloseHours] = useState(0);
    const [doseIntervalDuringShopCloseHours, setDoseIntervalDuringShopCloseHours] = useState(0);
    const [workDuringShopCloseHours, setWorkDuringShopCloseHours] = useState(false);

    const handleDemoAction = (data) => {

        wsCommunication.demoMessage(
            device.sn,
            data,
            {doseInterval, doseDuration},
            onMessageToSent
        );
    };

    useEffect(() => {
        updateSettings(device);
    }, []);

    const updateSettings = (device) => {

        setEnable(deviceConf.boolFillWithData(device, "enable", "MD"));
        setDoseDuration(deviceConf.timeFillWithData(device, "doseDuration", "MD"));
        setDoseInterval(deviceConf.timeFillWithData(device, "doseInterval", "MD"));
        setDoseDurationDuringShopCloseHours(deviceConf.timeFillWithData(device, "doseDurationDuringShopCloseHours", "MD"));
        setDoseIntervalDuringShopCloseHours(deviceConf.timeFillWithData(device, "doseIntervalDuringShopCloseHours", "MD"));
        setWorkDuringShopCloseHours(deviceConf.boolFillWithData(device, "workDuringShopCloseHours", "MD"));

    };

    const handleSendConfiguration = async () => {


        const conf = {
            enable,
            doseDuration,
            doseInterval,
            doseDurationDuringShopCloseHours,
            doseIntervalDuringShopCloseHours,
            workDuringShopCloseHours
        };

        try {
            const {data} = await AuthDeviceService.postConf({
                _id: device._id,
                conf,
            });

            wsCommunication.forceStatusMessage(device.sn, onMessageToSent);
            toast.success("Nowe ustawienia zostały zapisane!");

            updateSettings(data);
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    };

    const handleDelete = async () => {
        const {data} = await AuthDeviceService.remove(device._id);
        forceDataRefresh();
    };

    return (
        <Card className="mb-2 cursor-pointer" style={{minWidth: "800px"}}>
            <CardDeviceHeader
                expanded={expanded}
                onlineDeviceData={onlineDeviceData}
                device={device}
                settings={{}}
                onAction={handleDemoAction}
                onExpandedClick={() => {
                    setExpanded(~expanded);
                }}
            />

            {expanded ? (
                <React.Fragment>
                    <Card.Body>
                        <CardDeviceNameView
                            device={device}
                            refreshData={forceDataRefresh}
                        ></CardDeviceNameView>
                        <CardDeviceAlwaysActiveView
                            device={device}
                            refreshData={forceDataRefresh}>
                        </CardDeviceAlwaysActiveView>
                        <CardDeviceTagView
                            device={device}
                            refreshData={forceDataRefresh}
                        ></CardDeviceTagView>
                        <CardDeviceWifiView
                            device={device}
                            refreshData={forceDataRefresh}
                        ></CardDeviceWifiView>

                        <hr style={{width: "90%", margin: "auto", background: "black"}}></hr>

                        <BoolValueControl
                            label="Aktywny:"
                            device={device}
                            value={enable}
                            onChange={(value) => setEnable(value)}
                        />

                        {enable &&
                            <>
                                <TimeSettingsControl
                                    label="Czas pracy pompy [s]:"
                                    device={device}
                                    min="0"
                                    max="30"
                                    value={doseDuration}
                                    onChange={(value) => setDoseDuration(value)}
                                />

                                <TimeSettingsControl
                                    label="Częstotliwość załączania pompy [m]:"
                                    device={device}
                                    min="0"
                                    max="1440"
                                    value={doseInterval}
                                    onChange={(value) => setDoseInterval(value)}
                                />

                                <BoolValueControl
                                    label="Praca w godzinach zamknięcia sklepu:"
                                    device={device}
                                    value={workDuringShopCloseHours}
                                    onChange={(value) => setWorkDuringShopCloseHours(value)}
                                />

                                {workDuringShopCloseHours &&
                                    <TimeSettingsControl
                                        label="Czas pracy pompy w godzinach zamknięcia sklepu [s]:"
                                        device={device}
                                        min="0"
                                        max="30"
                                        value={doseDurationDuringShopCloseHours}
                                        onChange={(value) => setDoseDurationDuringShopCloseHours(value)}
                                    />
                                }
                                {workDuringShopCloseHours &&
                                    <TimeSettingsControl
                                        label="Częstotliwość załączania pompy w godzinach zamknięcia sklepu [m]:"
                                        device={device}
                                        min="0"
                                        max="1440"
                                        value={doseIntervalDuringShopCloseHours}
                                        onChange={(value) => setDoseIntervalDuringShopCloseHours(value)}
                                    />
                                }
                            </>
                        }
                        <CardDeviceButtonsPanel
                            onDelete={handleDelete}
                            onSendConfiguration={handleSendConfiguration}
                        />
                    </Card.Body>
                </React.Fragment>
            ) : null}


            <CardDeviceFooter
                device={device}
                onlineDeviceData={onlineDeviceData}
                onChangeConf={handleDemoAction}
            />
        </Card>
    );
}


export default CardDeviceMD;
