import React, {useState, useEffect} from "react";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

export default function ProgressModal(props) {
    return (
        <Modal
            show={props.show}
            // onHide={() => this.setState({ showConfirmationModal: false })}
            centered
            className="rounded-lg"
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body
                className="rounded-lg p-0 m-2"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title id="contained-modal-title-vcenter" className={"m-3"}>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <Spinner className="m-0" animation="border" variant="success"/>
                            </div>
                        </Col>
                    </Row>
                    {props.actual !== 0 && props.max !== 0 ? (
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <p
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            margin: "0",
                                        }}
                                    >
                                        {props.actual} /{props.max}
                                    </p>
                                </div>
                            </Col>
                        </Row>) : null
                    }
                </Modal.Title>
            </Modal.Body>
            {/*<Modal.Footer className="p-0 m-2">*/}

            {/*</Modal.Footer>*/}
        </Modal>
    );
}
