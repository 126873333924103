import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Input from "./input";

export default class XLSXFileInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <Form.Control
        type="file"
        name="xlsxFileInput"
        id="file"
        accept={SheetJSFT}
        onChange={this.handleChange}
        style={{ textAlign: "center", verticalAlign: "middle" }}
        className="mt-3"
       
      />
    );
  }
}

const SheetJSFT = [
  "xlsx",
  // "xlsb",
  // "xlsm",
  "xls",
  // "xml",
  "csv",
  // "txt",
  // "ods",
  // "fods",
  // "uos",
  // "sylk",
  // "dif",
  // "dbf",
  // "prn",
  // "qpw",
  // "123",
  // "wb*",
  // "wq*",
  // "html",
  // "htm"
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
