import React, {Component} from "react";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {toast} from "react-toastify";
import FileSaver from "file-saver";

import * as UserService from "../services/userService";

import UsersTable from "./usersTable";
import Pagination from "./common/pagination";
import {search} from "../utils/search";
import {paginate} from "../utils/paginate";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import {prepareDateWithoutHours} from "../utils/dateFormat";

class Users extends Component {
    state = {
        users: [],
        pageSize: 8,
        currentPage: 1,
        sortColumn: {path: "username", order: "asc"},
        searchQuery: "",
        startDate: prepareDateWithoutHours(new Date(Date.now())),
        endDate: prepareDateWithoutHours(new Date(Date.now())),
    };

    updateData = async () => {
        const {data: users} = await UserService.getAll();
        // console.log(users);
        this.setState({users});
    };

    async componentDidMount() {
        try {
            await this.updateData();
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    }

    handleExport = async () => {
        const { startDate, endDate } = this.state;
        const {data: logs} = await UserService.getActivityLogs(startDate, endDate);


        let result = "Czas;IP;Użytkownik;Kod zdarzenia;Opis;Prób nieudanych;\n";
        logs.forEach((log) => (result += this.formatLogToTxt(log)));
        var blob = new Blob([result], {
            type: "text/plain;charset=utf-8",
        });

        FileSaver.saveAs(blob, "użytkownicy_aktywność_logi_" + (new Date(Date.now())).toLocaleString() + ".txt");

    }

    formatLogToTxt = (log) => {
        let result = "";

        result += log.timestamp + ";";
        result += log?.ip + ";";
        result += log.username + ";";
        result += "C:" + log.event + ";";
        result += log.message + ";";
        result += log.attemptNo + ";\n";

        return result;
    };

    handleDelete = async (user) => {
        try {
            const {data} = await UserService.remove(user._id);
            await this.updateData();
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    handleEdit =  (user) => {
        this.props.history.push("/register/" + user._id);
    };

    handleUnlockUser = async (user) => {
        try {
            const {data} = await UserService.unlock(user._id);

            await this.updateData();
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    handleRowClick = (AuthDevice) => {
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleSort = (sortColumn) => {
        if (sortColumn.path === "") return;

        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {pageSize, currentPage, users: allUsers, sortColumn, searchQuery} = this.state;

        let filtered = allUsers;

        if (searchQuery) {
            filtered = search(allUsers, ["username", "name", "surname"], searchQuery);
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const users = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: users};
    };

    handleSearch = (query) => {
        this.setState({
            searchQuery: query,
            selectedDevType: null,
            currentPage: 1,
        });
    };

    generateDate = (selectedDate) => {
        const date = prepareDateWithoutHours(selectedDate);
        return date;
    };

    handleStartDateChange = async ({currentTarget: input}) => {
        const value = input.value;
        const date = new Date(value);
        this.setState({startDate: date});
    };

    handleEndDateChange = async ({currentTarget: input}) => {
        const value = input.value;
        const date = new Date(value);
        this.setState({endDate: date});
    };

    render() {
        const {pageSize, currentPage, sortColumn, searchQuery} = this.state;

        const {totalCount, data: users} = this.getPagedData();

        return (
            <React.Fragment>
                <ConfirmationModalContextProvider>
                    <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                        <Breadcrumb.Item
                            style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                            href="/"
                        >
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                        >
                            Konfiguracja
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                            active
                        >
                            Zarządzanie użytkownikami
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Container>

                        <Row>
                            <Col ms="12" xs="12">
                                <Link className="btn btn-primary margin-bottom-5" to="/register">
                                    Dodaj użytkownika
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <SearchBox value={searchQuery} onChange={this.handleSearch}/>
                            </Col>
                        </Row>
                        {users && (
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        <Col>
                                            <UsersTable
                                                users={users}
                                                sortColumn={sortColumn}
                                                onEdit={this.handleEdit}
                                                onDelete={this.handleDelete}
                                                onSort={this.handleSort}
                                                onUnlockUser={this.handleUnlockUser}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" lg="12">
                                            <Pagination
                                                itemsCount={totalCount}
                                                pageSize={pageSize}
                                                currentPage={currentPage}
                                                onPageChange={this.handlePageChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row className="justify-content-center align-content-center">
                            <Col lg="4" md="6">

                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <label className="align-self-center fw-bold">Od:</label>
                                    <input
                                        className="m-2 w-100"
                                        value={this.generateDate(this.state.startDate)}
                                        name="data"
                                        type="date"
                                        onChange={this.handleStartDateChange}
                                    />
                                </div>
                            </Col>
                            <Col lg="4" md="6">

                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <label className="align-self-center fw-bold">Do:</label>
                                    <input
                                        className="m-2 w-100"
                                        value={this.generateDate(this.state.endDate)}
                                        name="data"
                                        type="date"
                                        onChange={this.handleEndDateChange}
                                    />
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <Button
                                        onClick={this.handleExport}
                                        size="sm"
                                        variant="success"
                                        className="mb-2 mt-2 me-2 w-100"
                                    >
                                        Eksportuj logi aktywności użytkowników
                                    </Button>
                                </div>
                            </Col>
                    </Row>

                </Container>
            </ConfirmationModalContextProvider>
    </React.Fragment>
    )
        ;
    }
}

export default Users;
