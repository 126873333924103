import http from "./httpService";
import {isAdmin} from "./authService";

const apiEndpoint = "/users";

export function register(user) {
    return http.post(apiEndpoint,{
        username: user.username,
        password: user.password,
        isAdmin: user.isAdmin,
        isService: user.isService,
        name: user.name,
        surname: user.surname
    })
}

export function edit(_id,user) {
    return http.put(apiEndpoint+"/"+_id,{
        username: user.username,
        isAdmin: user.isAdmin,
        isService: user.isService,
        name: user.name,
        surname: user.surname
    })
}


export function getAll() {
    return http.get(apiEndpoint);
}

export function get(_id) {
    return http.get(apiEndpoint + "/" + _id);
}


export function getActivityLogs(startDate,endDate) {
    return http.get(apiEndpoint + "/activity",{ params: { startDate, endDate } });
}

export function unlock(_id) {
    return http.put(apiEndpoint + "/unlock/" + _id);
  }

export function remove(_id) {
    return http.delete(apiEndpoint + "/" + _id);
  }

export default  {register,edit,getAll,get,getActivityLogs,unlock, remove};