import React, { Component } from "react";
import Joi from "joi";
import UForm from "./common/uform";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {login, getCurrentUser} from "../services/authService";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";


class LoginForm extends UForm {
  state = {
    data: { username: ``, password: `` },
    errors: {},
  };

  //nie musi sprawdzać dokładnych założeń loginu i hasła by nie zdradzać jego budowy
  schema = Joi.object({
    username: Joi.string().min(1).required().label("Nazwa użytkownika").optional(), 
    password: Joi.string().min(1).required().label("Hasło").optional(),
  });

  doSubmit = async () => {
    try {
      const {username, password} = this.state.data;

      await login(username,password);

      const { state } = this.props.location;
      window.location = (state) ? state.from.pathname : "/shops";

    } catch (ex) {
      console.log(ex);
      if(ex.response && ex.response.status === 400){
        const errors = {...this.state.errors};
        errors.username = ex.response.data;
        errors.password = ex.response.data;
        this.setState({errors});
      }
      

    }
  };

  render() {

    if(getCurrentUser()) return <Redirect to="/shops"/>

    return (
      <Container className="mt-5">
        <Row className="justify-content" nogutters="true">
          <Col xs="2" md="2"> </Col>
          <Col xs="8" md="8">
            <h3>Logowanie</h3>
            <Form onSubmit={this.handleSubmit}>
              {this.renderInput("username", "Nazwa użytkownika")}
              {this.renderInput("password", "Hasło", "password")}

              <div className="mt-3">
              {this.renderButton("Zaloguj się")}
              </div>
            </Form>
          </Col>
          <Col xs="2" md="2"> </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginForm;
