import React, {useEffect, useState} from 'react';
import FileView from "./common/FileView";
import FolderView from "./common/FolderView";
import Dropdown from "react-bootstrap/Dropdown";


function Filesystem({path, folders, files, onFolderClick, onFileClick, filesSelected, ...props}) {

    const calculateFilesCount = () => {
        let allFiles = 0;
        let selectedFiles = 0;
        files.map((item, index) => {
            if (item.path === path._id) {
                let selected = false;
                allFiles++;

            }
        })
        return allFiles;
    }

    const showFiles = () => {
        let count = 0;

        return (<>{files.map((item, index) => {
            if (item.path === path._id) {
                let selected = false;
                if (filesSelected.find((file) => file === item._id) !== undefined) {
                    selected = true;
                }
                count++;
                return (
                    <div className="w-100" key={index}>
                        {/*{item.type === 1 ? <FileView {...item}/> : <FolderView {...item}/>}*/}
                        <FileView {...item} no={count} onClick={onFileClick} selected={selected}/>
                    </div>
                )
            }
            return null;

        })}</>)

    }

    return (
        <>

            <div className="w-100  m-0 rounded-1 " style={{
                backgroundColor: "white",
                alignSelf: "center",
                display: "flex",
                flexDirection: "row",
                textAlign: "right",
                fontWeight: "bold"
            }}>
                <div className="w-50  m-0 rounded-1 " style={{
                    backgroundColor: "white",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    fontWeight: "bold"
                }}>Wszystkich plików w playliście: {filesSelected.length}</div>

                <div className="w-50  m-0 rounded-1 " style={{
                    backgroundColor: "white",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold"
                }}>Plików w folderze: {calculateFilesCount()}</div>

            </div>
            <div {...props}>
                {
                    folders.map((item, index) => {
                        return (
                            <div className="w-100" key={index}>
                                {/*{item.type === 1 ? <FileView {...item}/> : <FolderView {...item}/>}*/}
                                <FolderView {...item} onClick={onFolderClick}/>
                            </div>
                        )
                    })
                }
                {
                    showFiles()
                }


            </div>
        </>
    );
}

export default Filesystem;
