import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import {toast} from "react-toastify";

import CardDeviceHeader from "./cardDevice/cardDeviceHeader";
import CardDeviceButtonsPanel from "./cardDevice/cardDeviceButtonsPanel";
import CardDeviceNameView from "./cardDevice/cardDeviceNameView";
import CardDeviceTagView from "./cardDevice/cardDeviceTagView";
import CardDeviceWifiView from "./cardDevice/cardDeviceWifiView";
import CardDeviceFooter from "./cardDevice/cardDeviceFooter";
import BeepTableHeader from "./confControl/beepSettingsTableHeader";
import BeepSettingsControl from "./confControl/beepSettingsControl";
import DistanceSettingsBlock from "./confControl/distanceSettingsBlock";

import * as beepOnChange from "../../utils/conf/beepOnChangeActions";
import * as sensorSettingsOnChange from "../../utils/conf/distanceSensorSettingsOnChangeAction";
import * as wsCommunication from "../../utils/wsCommunication";

import * as AuthDeviceService from "../../services/authDeviceService";
import * as deviceConf from "../../utils/conf/deviceConf";

import config from "../../config.json";
import CardDeviceAlwaysActiveView from "./cardDevice/cardDeviceAlwaysActiveView";

class CardDeviceMKS extends Component /* ({ device, online, connectionStatus })  */ {
    state = {
        expanded: false,
        beepTable: [],
    };

    componentDidMount = () => {
        const {device} = this.props;

        this.updateSettings(device);
    };

    updateSettings = (device) => {
        const beepTable = deviceConf.beepTableGenerateAndFillWithData(
            [
                config.beepTypes.beep1_ID,
                config.beepTypes.beep2_ID,
                config.beepTypes.beep3_ID,
                config.beepTypes.beep4_ID,
                config.beepTypes.beep5_ID,
                config.beepTypes.beep6_ID,
            ],
            device,
            this.props.maDevices,
            this.props.files
        );
        // console.log(beepTable)
        this.setState({beepTable});
    };

    // handleAudioDevChange = (audioDev, id) => {
    //   this.setState({
    //     beepTable: beepOnChange.audioDev(this.state.beepTable, audioDev, id),
    //   });
    // };

    handleFileChange = (file, id) => {
        this.setState({
            beepTable: beepOnChange.file(this.state.beepTable, file, id),
        });
    };

    handleSpkChange = (spk, audioDevSn, value, id) => {
        // console.log("Spk "+spk+" sn: "+audioDevSn+" value: "+value+ " id: "+id)
        this.setState({
            beepTable: beepOnChange.spk(this.state.beepTable, audioDevSn, spk, value, id),
        });
    };

    handleEnableChange = (audioDevSn, value, id) => {
        this.setState({
            beepTable: beepOnChange.enable(this.state.beepTable, audioDevSn, value, id),
        });
    };

    handleSendConfiguration = async () => {
        const {beepTable} = this.state;

        const conf = {beepTable};

        try {
            const {data} = await AuthDeviceService.postConf({
                _id: this.props.device._id,
                conf,
            });

            wsCommunication.forceStatusMessage(this.props.device.sn, this.props.onMessageToSent);
            toast.success("Nowe ustawienia zostały zapisane!");

            this.updateSettings(data);
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    };

    handleDemoAction = (data) => {
        const {beepTable, distanceSensorTable} = this.state;

        wsCommunication.demoMessage(
            this.props.device.sn,
            data,
            {beepTable, distanceSensorTable},
            this.props.onMessageToSent
        );
    };

    handleDelete = async () => {
        const {data} = await AuthDeviceService.remove(this.props.device._id);

        this.props.forceDataRefresh();
    };

    handleExpand = () => {
    };

    render() {
        const {device, onlineDeviceData} = this.props;
        const {expanded, beepTable} = this.state;

        return (
            <Card className="mb-2 cursor-pointer" style={{minWidth: "800px"}}>
                <CardDeviceHeader
                    expanded={expanded}
                    onlineDeviceData={onlineDeviceData}
                    device={device}
                    onAction={this.handleDemoAction}
                    onExpandedClick={() => {
                        this.setState({expanded: ~expanded});
                    }}
                />

                {expanded ? (
                    <React.Fragment>
                        <Card.Body>
                            <CardDeviceNameView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceNameView>
                            <CardDeviceAlwaysActiveView
                                device={device}
                                refreshData={this.props.forceDataRefresh}>
                            </CardDeviceAlwaysActiveView>
                            <CardDeviceTagView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceTagView>
                            <CardDeviceWifiView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceWifiView>

                            <hr style={{width: "100%", margin: "auto", background: "black"}}></hr>

                            <Table responsive="xs" style={{width: "90%", margin: "auto"}}>
                                <BeepTableHeader/>
                                <tbody>
                                {[
                                    config.beepTypes.beep1_ID,
                                    config.beepTypes.beep2_ID,
                                    config.beepTypes.beep3_ID,
                                    config.beepTypes.beep4_ID,
                                    config.beepTypes.beep5_ID,
                                    config.beepTypes.beep6_ID].map((x) => {
                                    const index = beepTable.findIndex((beep) => beep.id === x);
                                    return (
                                        <BeepSettingsControl
                                            device={device}
                                            value={beepTable[index]}
                                            id={beepTable[index].id}
                                            key={beepTable[index].id + "" + device.sn}
                                            label={`Komunikat ${beepTable[index].id}:`}
                                            // onAudioDevChange={this.handleAudioDevChange}
                                            onFileChange={this.handleFileChange}
                                            onSpkChange={this.handleSpkChange}
                                            onEnableChange={this.handleEnableChange}
                                        />
                                    );
                                })}
                                </tbody>
                            </Table>

                            <CardDeviceButtonsPanel
                                onDelete={this.handleDelete}
                                onSendConfiguration={this.handleSendConfiguration}
                            />
                        </Card.Body>
                    </React.Fragment>
                ) : null}

                <CardDeviceFooter
                    device={device}
                    onlineDeviceData={onlineDeviceData}
                    onChangeConf={this.handleDemoAction}
                />
            </Card>
        );
    }
}

export default CardDeviceMKS;
