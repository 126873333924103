export function demoMessage(sn, data, configuration, sendMessage) {
    if (data) {
        if (data.action === "beep") {
            const {beepTable} = configuration;

            if (!beepTable) return;

            const beep = beepTable.find((beep) => data.id === beep.id);
            if (!beep) return;

            let to = [];

            if (!beep.to) return;
            beep.to.forEach((_to) => {
                let spk = [];
                for (let i = 0; i < 12; i++) {
                    if (_to[`spk${i + 1}`] !== undefined) {
                        spk.push(_to[`spk${i + 1}`]);
                    } else {
                        if (i < 6)
                            spk.push(false);
                    }
                }
                let target = {
                    sn: _to.sn,
                    spk: spk,
                };
                to.push(target);
            });

            sendMessage({
                broadcast: "",
                cmd: "beep",
                file: beep.file,
                to: to,
            });
        } else if (data.action === "sensor_reset") {
            sendMessage({
                sn: sn,
                cmd: "sensor_reset",
            });
        } else if (data.action === "device_reset") {
            sendMessage({
                sn: sn,
                cmd: "device_reset",
            });
        } else if (data.action === "conf") {
            sendMessage({
                sn: sn,
                cmd: "conf",
                sn_new: data.sn_new,
                hw: data.hw,
            });
        } else if (data.action === "broadcast_reset") {
            sendMessage({
                broadcast: "",
                cmd: "reset_all",
            });
        } else if (data.action === "dose") {
            sendMessage({
                sn: sn,
                cmd: "dose",
                dose: configuration,
            });

        }
    }
}

export function forceStatusMessage(sn, sendMessage) {
    sendMessage({
        sn: sn,
        cmd: "status",
    });
}

export function deviceMessage(sn,cmd, sendMessage) {
    sendMessage({
        sn: sn,
        cmd: cmd,
    });
}

export function broadcastMessage(sendMessage) {
    sendMessage({
        cmd: "broadcast",
    });
}
