import React from "react";
import Table from "react-bootstrap/Table"

function BeepTableHeader() {
  return (
    <thead>
      <tr>
        <th style={{ textAlign: "center" }}></th>
        <th style={{ textAlign: "center" }}>Plik</th>
        <th style={{ textAlign: "center" }}></th>
      </tr>
    </thead>
  );
}

export default BeepTableHeader;
