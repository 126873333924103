import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import config from "../../../config.json";

let commonStyle = {
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0",
    padding: "0",
    fontWeight: "bold"
};

let pStyle = {
    margin: "0",
    padding: "0",
};

export default function VolumeSettingsBlockTableHeaderNumberInput({volumeMinMaxLabel = "0 - 128", extraColumnVolumeValueVisible = false} ) {

    return (
        <tr>
            <th
                style={{
                    ...commonStyle,
                    minWidth: "100px",
                }}
            >

            </th>
            <th
                style={{
                    ...commonStyle,
                    minWidth: "70px",
                }}
            >
                <p style={pStyle}>
                    Strefa
                </p>
                <p style={pStyle}>
                    kas
                </p>
            </th>
            <th
                style={{
                    ...commonStyle,
                    minWidth: "70px",
                }}
            >
                <p style={pStyle}>
                    Sala
                </p>
                <p style={pStyle}>
                    sprzedaży
                </p>
            </th>
            <th
                style={{
                    ...commonStyle,
                    minWidth: "70px",
                }}
            >
                Zaplecze
            </th>
            <th
                style={{
                    ...commonStyle,
                    minWidth: "100px",
                }}
            >
                <p className="m-auto">
                    Nazwa
                </p>
            </th>

            <th
                style={{
                    ...commonStyle,
                    minWidth: "200px",
                }}
            >
                <p className="m-auto">
                    {volumeMinMaxLabel}
                </p>
            </th>
            {/*<td*/}
            {/*    style={{*/}
            {/*        textAlign: "center",*/}
            {/*        width: "80px",*/}
            {/*        margin: "0",*/}
            {/*        padding: "0",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <p className="m-2">{value.volume}</p>*/}
            {/*</td>*/}
            {extraColumnVolumeValueVisible === true &&
                <th style={{
                    ...commonStyle
                }}>

                </th>
            }
            <th style={{
                ...commonStyle
            }}>
                Enable
            </th>

        </tr>
    );
}
