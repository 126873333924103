import {React, useState, useEffect} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {toast} from "react-toastify";

import * as AuthDeviceService from "../../../services/authDeviceService";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faSave} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import dictionary from "../../../PL.json";
import ConfirmationSwitch from "../confirmationSwitch";
import ApiSettingsService from "../../../services/apiSettingsService";
import {updateAlwaysActive} from "../../../services/authDeviceService";

export default function CardDeviceAlwaysActiveView({device, refreshData}) {


    const handleChange = async (checked) => {

        try {
            const {data} = await AuthDeviceService.updateAlwaysActive({
                _id: device._id,
                always_active: checked,
            });

            refreshData();
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }

    };



    return (
        <div className="flex-container container__center mb-2">
            <div className="me-2 my-auto fw-bold h-100">Przejmuje ciągłą komunikacje:</div>
            <ConfirmationSwitch
                label={dictionary.shopView.cardDevice.alwaysActiveView.activeSwitch_confirmLabel}
                yesLabel={dictionary.shopView.cardDevice.alwaysActiveView.activeSwitch_confirmYesLabel}
                noLabel={dictionary.shopView.cardDevice.alwaysActiveView.activeSwitch_confirmNoLabel}
                key={"activeSwitch+"+device._id}
                onChange={handleChange}
                type="switch"
                checked={device?.always_active ? device.always_active : false}
            />

        </div>
    );
}
