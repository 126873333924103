import React, { useContext, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const ConfirmationModalContext = React.createContext({});

const ConfirmationModalContextProvider = (props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [label, setLabel] = useState("Czy napewno?");
  const [yesLabel, setYesLabel] = useState("TAK");
  const [noLabel, setNoLabel] = useState("NIE");
  const [body, setBody] = useState("");
  const resolver = useRef();

  const handleShow = () => {
    setShowConfirmationModal(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const handleSetLabel = (text) => {
    setLabel(text);
  };

  const handleSetYesLabel = (text) => {
    setYesLabel(text);
  };

  const handleSetNoLabel = (text) => {
    setNoLabel(text);
  };

  const handleSetBody = (text) => {
    setBody(text);
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setShowConfirmationModal(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShowConfirmationModal(false);
  };

  return (
    <ConfirmationModalContext.Provider value={{ showConfirmation: handleShow, setLabel: handleSetLabel, setYesLabel: handleSetYesLabel, setNoLabel: handleSetNoLabel, setBody: handleSetBody }}>
      {props.children}

      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
        dialogClassName="modal-xs"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {label}
          </Modal.Title>
        </Modal.Header>

        {body.length > 0 && 
          <Modal.Body>{body}</Modal.Body>
        }
        <Modal.Footer className="show-grid">
          <Container>
            <Row>
              <Col xs={6} md={6} style={{ textAlign: "left"}}>
                <Button variant="secondary" onClick={handleCancel} style={{width: "100%"}}>
                  {noLabel}
                </Button>
              </Col>
              <Col xs={6} md={6}  style={{ textAlign: "right"}}>
                <Button variant="primary" onClick={handleOk} style={{width: "100%"}}>
                  {yesLabel}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModalContext = () =>
  useContext(ConfirmationModalContext);
export default ConfirmationModalContextProvider;
