import http from "./httpService";


export async function getImsEndpoint() {
    try {

        let {data} = await http.get("/apiSettings")
        let endpoint = data.find(x => x.what === "ims_endpoint");
        if(endpoint){
            return endpoint.value
        }
    } catch (ex) {
        console.log(ex)
        return null;
    }
}

export function getAll(imsEndpoint) {
    return http.get(imsEndpoint + "/api/player/ims/files");
}
//
// export function remove(_id) {
//     return http.delete(apiEndpoint + "/" + _id);
// }
//
// export function upload(file) {
//     http
//         .get(apiEndpoint + "/" + file._id, { responseType: "blob" })
//         .then((response) => {
//             if (response.status === 200) {
//                 let url = window.URL.createObjectURL(new Blob([response.data]));
//                 let a = document.createElement("a");
//                 a.href = url;
//                 a.download = file.name + ".mp3";
//                 a.click();
//             }
//         });
// }
