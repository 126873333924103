import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderClosed} from "@fortawesome/free-regular-svg-icons";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function FolderView({name, _id,onClick}) {




    return (
        <div onClick={(e) => onClick(e,_id)} onContextMenu={(e) => onClick(e,_id)}
            className={"folder w-100 mx-auto p-1 my-1 d-flex justify-content-start align-items-center"} >
            <FontAwesomeIcon className={"mx-3"}
                             icon={faFolderClosed} size={"2x"}/>
            <div className={"  fs-6 text-start"} style={{fontSize: "1.2rem"}}>{name}</div>

        </div>
    );
}

export default FolderView;