import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ConfirmationButton from "./common/confirmationButton";
import dictionary from "../PL.json";

import { isAdmin } from "../services/authService";
import UTable from "./common/table";

class TagsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Tag",
    },
    {
      path: "count",
      label: "Występuje:",
    },
    {
      path: "dev_types",
      label: "Dotyczy:",
      content: (item) => {
        return (
          <div className="tag-container flex-container__center">
            {item.dev_types.map((dev_type) => {
              return (
                <div className="tag" key={dev_type}>
                  <div className="tag__name">{dev_type}</div>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      path: "description",
      label: "Opis",
    },
  ];

  buttonAdd = {
    path: "",
    key: "delete",
    content: (item) => (
      <div style={{textAlign: "center"}}> 
       
        <Button
          variant="warning"
          className="my-0 me-2"
          size="sm"
          onClick={(e) => this.props.onEdit(e, item)}
        >
          Edytuj
        </Button>
        {item.count === 0 && <ConfirmationButton
          label={dictionary.tags.tagsTable.deleteButton_confirmLabel}
          yesLabel={dictionary.tags.tagsTable.deleteButton_confirmYesLabel}
          noLabel={dictionary.tags.tagsTable.deleteButton_confirmNoLabel}
          body={dictionary.tags.tagsTable.deleteButton_body}
          variant="danger"
          className="my-0 me-2"
          size="sm"
          onClick={(event) => this.props.onDelete(event, item)}
        >
          {dictionary.tags.tagsTable.deleteButton_label}
        </ConfirmationButton>}
      </div>
    ),
  };

  constructor() {
    super();
    if (isAdmin()) {
      this.columns.push(this.buttonAdd);
    }
  }

  render() {
    const { tags, sortColumn, onSort } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={tags}
        dataId="_id"
      />
    );
  }
}

export default TagsTable;
