import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function ManyGenerateSensorTitle(props){
    return (
      <React.Fragment>
        <Row className={"w-100 mx-0 px-0"}>
          <Col
            xs="12"
            md="2"
            className="d-flex justify-content-center "
            style={{
              minWidth: "100px",
              background: props.color,
            }}
          >
            <p className="h5 align-self-center" style={{ textAlign: "center", color: "white" }}>
              {props.label}
            </p>
          </Col>
          <Col
            xs="12"
            md="10"
            className="justify-content-center px-0"
            style={{
              minWidth: "100px",
            }}
          >
            {props.children}
          </Col>
        </Row>
        <div className="row my-0 mx-auto">
          <hr className="my-0 m-auto"></hr>
        </div>
      </React.Fragment>
    );
  };