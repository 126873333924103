import {Button, Container, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faPlus} from "@fortawesome/free-solid-svg-icons";
import MusicFilesManager from "./MusicFilesManager";
import AddPlaylist from "./modals/addPlaylist";
import PlaylistsService, {remove, updateActive, updateFiles} from "../services/playlistsService";
import PlaylistView from "./common/PlaylistView";
import Col from "react-bootstrap/Col";
import FileView from "./common/FileView";
import MusicFilesService from "../services/musicFilesService";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ConfirmationModalContextProvider from "./common/modalConfirmationContext";


function MusicManagement() {
    const [showFileManager, setShowFileManager] = useState(false);
    const [showAddPlaylistModal, setShowAddPlaylistModal] = useState(false);

    const [playlists, setPlaylists] = useState([]);
    const [playlistToEdit, setPlaylistToEdit] = useState(null);
    const [playlistToView, setPlaylistToView] = useState(null);
    const [allFiles, setAllFiles] = useState([]);
    const [changes, setChanges] = useState(false);

    const uploadFiles = async () => {
        try {
            let result = await MusicFilesService.getAll();
            setAllFiles(result.data);
        } catch (e) {

        }
    }

    const uploadPlaylists = async () => {
        try {
            let result = await PlaylistsService.getAll();
            setPlaylists(result.data);
        } catch (e) {

        }
    }

    const handlePlaylistRefresh = async () => {
        uploadPlaylists();
    }

    const handlePlaylistClick = (event, playlist) => {
        event.stopPropagation();
        setPlaylistToView(playlist);
        setChanges(false);
    }

    const handelPlaylistEdit = (event, _id) => {
        event.stopPropagation();
        if (_id !== null) {
            const result = playlists.find((playlist) => playlist._id === _id);
            if (result) {
                setPlaylistToEdit(result);
            }
        } else {
            setPlaylistToEdit(null);
        }

        setShowAddPlaylistModal(true);
    }

    const handleSetActivePlaylist = async (e, _id) => {
        e.stopPropagation();
        try {
            let result = await PlaylistsService.updateActive(_id);
            if (result.status === 200) {
                uploadPlaylists();
            }
        } catch (e) {
            toast.error("Błąd ustawiania playlisty jako obowiązującej");
        }
    }

    const handleDeletePlaylist = async (e, _id) => {
        try {
            let result = await PlaylistsService.remove(_id);
            if (result.status === 200) {
                uploadPlaylists();
            }
        } catch (e) {
            toast.error("Błąd usuwania playlisty");
        }
    }

    const handleAddToPlaylist = (_id) => {
        let playlist = {...playlistToView};
        if (playlist.files.findIndex((file) => file === _id) === -1) {
            playlist.files.push(_id);
            setPlaylistToView(playlist);
            setChanges(true);
        }

    }

    const handleRemoveFromPlaylist = (_id) => {
        let playlist = {...playlistToView};
        playlist.files = playlist.files.filter((file) => file !== _id);
        setPlaylistToView(playlist);

    }

    const saveFilesToPlaylist = async () => {
        try {
            let result = await PlaylistsService.updateFiles(playlistToView._id, playlistToView.files);
            if (result.status === 200) {
                toast.success("Zapisano playlistę");
                setChanges(false);
            }
        } catch (e) {
            toast.error("Błąd zapisu playlisty");
        }
    }

    useEffect(() => {
        try {
            uploadPlaylists();
            uploadFiles();
        } catch (e) {
            console.log(e);
        }


    }, []);

    const handleCloseFileManager = () => {
        setShowFileManager(false);
    }

    const returnToListOfPlaylist = async () => {
        setPlaylistToView(null);
        await uploadPlaylists();
        await uploadFiles();
    }

    return (
        <React.Fragment>
            <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}>Zarządzanie muzyką</Breadcrumb.Item>
                <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} active>Playlisty</Breadcrumb.Item>
            </Breadcrumb>
            <div className="App">
                <div>
                    <ToastContainer/>
                </div>
                <Container fluid>
                    <ConfirmationModalContextProvider>

                        {playlistToView &&
                            <>
                                <Row className={"w-75 m-auto mt-2"} style={{alignItems: "center"}}>
                                    <Col sm={2} className={"m-0 p-0"}>
                                        <Button className={"w-100 rounded-1 m-0"} variant="outline-secondary"
                                                onClick={returnToListOfPlaylist}>
                                            <FontAwesomeIcon className={"mx-1"}
                                                             icon={faArrowLeftLong} size={"1x"}/>
                                        </Button>
                                    </Col>
                                    <Col sm={8} className={"m-0 p-0"}>
                                        <p className={"display-6"}><strong>{playlistToView.name}</strong></p>
                                    </Col>
                                    <Col sm={2} className={"m-0 p-0"}>
                                        <Button className={"w-100 rounded-1 m-0"} variant="outline-secondary"
                                                onClick={() => setShowFileManager(true)}>
                                            <FontAwesomeIcon className={"mx-1"}
                                                             icon={faPlus} size={"1x"}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        <MusicFilesManager show={showFileManager} onClose={handleCloseFileManager}
                                           playlistToShow={playlistToView} onAddToPlaylist={handleAddToPlaylist}
                                           onRemoveFromPlaylist={handleRemoveFromPlaylist}
                                           setAllFiles={setAllFiles}
                                           allFiles={allFiles}
                                           refreshFiles={uploadFiles}/>

                        {!playlistToView &&
                            <Row className={"w-75 m-auto"}>
                                <Button className={"rounded-1 m-0 mt-2"} variant="outline-secondary"
                                        onClick={(e) => handelPlaylistEdit(e, null)}>Dodaj
                                    playlistę</Button>
                            </Row>
                        }

                        {/*<Button className={"rounded-1 m-2"} onClick={() => setShowFileManager(!showFileManager)}>Dodaj pliki do playlisty</Button>*/}

                        {!playlistToView && playlists && playlists.length > 0 && (

                            <div className="w-75 m-auto">
                                {playlists.map((playlist, index) => {
                                    return (
                                        <div className="w-100" key={index}>
                                            <PlaylistView id={playlist._id} name={playlist.name} date={playlist.date}
                                                          active={playlist.active}
                                                          onClick={(e) => handlePlaylistClick(e, playlist)}
                                                          onEditClick={handelPlaylistEdit}
                                                          onSetActive={handleSetActivePlaylist}
                                                          onDeleteClick={handleDeletePlaylist}/>
                                        </div>)
                                })}
                            </div>

                        )}
                        {playlistToView && !showFileManager && playlistToView.files.length > 0 && (

                            <div className="w-75  m-auto rounded-1 " style={{
                                backgroundColor: "white",
                                height: "90%",
                                alignSelf: "center",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "right",
                                fontWeight: "bold"
                            }}>Liczba plików: {playlistToView.files.length}</div>)

                        }
                        <div className="w-75  m-auto rounded-1 " style={{
                            backgroundColor: "white",
                            height: "90%",
                            alignSelf: "center",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            {playlistToView && !showFileManager && (
                                <div className="w-100 m-auto mt-1"
                                     style={{overflowY: "auto", flexBasis: "500px"}}>
                                    {playlistToView.files.length === 0 && (
                                        <p className={"display-6"}>Brak plików w tej playliste</p>
                                    )}
                                    {playlistToView.files.length > 0 && (

                                        playlistToView.files.map((item, index) => {

                                            let file = allFiles.find((file,index) => file._id === item);

                                            if (file !== undefined) {
                                                return (
                                                    <div className="w-100" key={index}>
                                                        <FileView {...file} onClick={() => true}
                                                                  no={index+1}
                                                                  selected={false}
                                                                  onDeleteFromPlaylist={async (e, _id) => {
                                                                      e.stopPropagation();
                                                                      handleRemoveFromPlaylist(_id);
                                                                      setChanges(true);
                                                                  }}/>
                                                    </div>
                                                )
                                            }


                                        })


                                    )}
                                </div>
                            )}
                            {playlistToView && !showFileManager && changes && (
                                <Row className={"w-100 m-auto mt-2"} style={{alignItems: "center"}}>
                                    <Col sm={12} className={"m-0 p-0"}>
                                        <Button className={"w-100 rounded-1 m-0"} variant="outline-success"
                                                onClick={saveFilesToPlaylist}>
                                            {/*<FontAwesomeIcon className={"mx-1"}*/}
                                            {/*                 icon={faArrowLeftLong} size={"1x"}/>*/}
                                            ZAPISZ ZMIANY
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>


                        <AddPlaylist show={showAddPlaylistModal} onClose={() => setShowAddPlaylistModal(false)}
                                     forceRefresh={handlePlaylistRefresh}
                                     playlist={playlistToEdit}></AddPlaylist>

                    </ConfirmationModalContextProvider>

                </Container>
            </div>
        </React.Fragment>

    )
        ;
}

export default MusicManagement;
