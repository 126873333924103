import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ConfirmationButton from "./common/confirmationButton";
import dictionary from "../PL.json";

import { isAdmin } from "../services/authService";
import UTable from "./common/table";

class UsersTable extends Component {
  columns = [
    {
      path: "username",
      label: "Login",
    },
    {
      path: "isAdmin",
      label: "Admin",
      key: "admin",
      content: (item) => {
        return <input onChange={() => {}} type="checkbox" checked={item.isAdmin ? true : false} />;
      },
    },
    {
      path: "isService",
      label: "Serwis",
      key: "admin",
      content: (item) => {
        return (
          <input onChange={() => {}} type="checkbox" checked={item.isService ? true : false} />
        );
      },
    },
    {
      path: "name",
      label: "Imię",
    },
    {
      path: "surname",
      label: "Nazwisko",
    },
  ];

  editColumn = {
    path: "",
    key: "edit",
    content: (item) => (
        <ConfirmationButton
            label={dictionary.users.usersTable.editButton_confirmLabel}
            yesLabel={dictionary.users.usersTable.editButton_confirmYesLabel}
            noLabel={dictionary.users.usersTable.editButton_confirmNoLabel}
            variant="warning"
            className="my-0"
            size="sm"
            onClick={(event) => this.props.onEdit(item)}
        >
          {dictionary.users.usersTable.editButton_label}
        </ConfirmationButton>
    ),
  };

  deleteColumn = {
    path: "",
    key: "delete",
    content: (item) => (
      <ConfirmationButton
        label={dictionary.users.usersTable.deleteButton_confirmLabel}
        yesLabel={dictionary.users.usersTable.deleteButton_confirmYesLabel}
        noLabel={dictionary.users.usersTable.deleteButton_confirmNoLabel}
        variant="danger"
        className="my-0"
        size="sm"
        onClick={(event) => this.props.onDelete(item)}
      >
        {dictionary.users.usersTable.deleteButton_label}
      </ConfirmationButton>
    ),
  };

  badAccessColumn = {
    path: "badAccessCounter",
    key: "unlock",
    label: "Nieudane próby logowania",
    content: (item) => {
      if (item.badAccessCounter >= 3) {
        return (
          <div className="flex-container flex-container__center">
            <div className="fw-bold">{item.badAccessCounter}</div>
            <ConfirmationButton
              label={dictionary.users.usersTable.unlockUserButton_confirmLabel}
              yesLabel={dictionary.users.usersTable.unlockUserButton_confirmYesLabel}
              noLabel={dictionary.users.usersTable.unlockUserButton_confirmNoLabel}
              variant="warning"
              className="my-0 ms-2"
              size="sm"
              onClick={(event) => this.props.onUnlockUser(item)}
            >
              {dictionary.users.usersTable.unlockUserButton_label}
            </ConfirmationButton>
          </div>
        );
      } else if (item.badAccessCounter > 0) {
        return (
          <div className="flex-container flex-container__center">
            <div className="fw-bold">{item.badAccessCounter}</div>
            <ConfirmationButton
              label={dictionary.users.usersTable.clearBadAccessButton_confirmLabel}
              yesLabel={dictionary.users.usersTable.clearBadAccessButton_confirmYesLabel}
              noLabel={dictionary.users.usersTable.clearBadAccessButton_confirmNoLabel}
              variant="secondary"
              className="my-0 ms-2"
              size="sm"
              onClick={(event) => this.props.onUnlockUser(item)}
            >
              {dictionary.users.usersTable.clearBadAccessButton_label}
            </ConfirmationButton>
          </div>
        );
      } else {
        return `Brak`;
      }
    },
  };

  constructor() {
    super();
    if (isAdmin()) {
      this.columns.push(this.badAccessColumn);
      this.columns.push(this.editColumn);
      this.columns.push(this.deleteColumn);
    }
  }

  render() {
    const { users, sortColumn, onSort } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={users}
        dataId="_id"
      />
    );
  }
}

export default UsersTable;
