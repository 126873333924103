import React, {Component} from "react";
import Joi from "joi";
import UForm from "./common/uform";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import validator from "validator";

import {toast} from "react-toastify";
import config from "../config.json";
import UserService from "../services/userService";

class RegisterForm extends UForm {
    state = {
        _id: "new",
        data: {
            username: "",
            password: ``,
            repassword: "",
            isAdmin: false,
            isService: false,
            name: "",
            surname: "",
        },
        errors: {},
    };

    componentDidMount = async () => {

        const _id = this.props.match.params.id;

        if (!_id) return;


        this.setState({_id: _id});

        try {
            const {data: user} = await UserService.get(_id);
            if (!user) {
                return this.props.history.replace("/not-found");
            }

            this.setState({
                data: this.mapToViewModel(user),
            });
        } catch (err) {
            // if (err.response) {
            //   if (err.response.status === 404) {

            //   }
            // }
            return this.props.history.replace("/users");
        }
    };

    mapToViewModel(user) {
        return {
            username: user.username,
            name: user.name,
            surname: user.surname,
            isAdmin: user.isAdmin,
            isService: user.isService,
        };
    }

    passwordValidation = (value, helpers) => {
        if (
            validator.isStrongPassword(value, {
                minLength: 10,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1,
            }) ||
            validator.isStrongPassword(value, {
                minLength: 10,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 0,
                minSymbols: 2,
            })
        ) {
            return value;
        } else {
            // throw helpers.message(": minumum 10 znaków, jedna mała i duża litera, conajmniej dwie cyfry lub znaki specjalne",);
            throw new Error(
                ": Minimum 10 znaków w tym: jedna mała litera, jedna duża litera, jedna cyfra i jeden znak specjalny."
            );
        }
        // // Throw an error (will be replaced with 'any.custom' error)
    };

    schema = Joi.object({
        username: Joi.string().min(5).max(50).required().label("Nazwa użytkownika").optional()
            .messages({
                'string.empty': `Nazwa użytkownika nie może być pusta`,
                'string.min': `minimum {#limit} znaków`,
                'any.required': `Nazwa użytkownika nie może być pusta`,
            }),
        password: Joi.string()
            .custom(this.passwordValidation, " ")
            .required()
            .label("Hasło")
            .optional()
            .messages({
                "any.custom": `Minimum 10 znaków w tym: jedna mała litera, jedna duża litera, jedna cyfra i jeden znak specjalny.`,
                "any.required": `Minimum 10 znaków w tym: jedna mała litera, jedna duża litera, jedna cyfra i jeden znak specjalny.`,
            }),
        repassword: Joi.string()
            .custom(this.passwordValidation, " ")
            .required()
            .label("Hasło")
            .optional()
            .messages({
                "any.custom": `Minimum 10 znaków w tym: jedna mała litera, jedna duża litera, jedna cyfra i jeden znak specjalny.`,
                "any.required": `Minimum 10 znaków w tym: jedna mała litera, jedna duża litera, jedna cyfra i jeden znak specjalny.`,
            }),
        isAdmin: Joi.boolean().optional(),
        isService: Joi.boolean().optional(),
        name: Joi.string().allow(null).allow("").label("Imię").optional(),
        surname: Joi.string().allow(null).allow("").label("Nazwisko").optional(),
    });


    doSubmit = async () => {
        try {
            const {_id,data} = this.state;

            if (_id === "new") {
                if (data.password !== data.repassword) {
                    let {errors} = this.state;

                    errors["repassword"] = "Hasła nie są takie same.";
                    this.setState({errors});
                    return;
                }

                const result = await UserService.register({
                    username: data.username,
                    password: data.password,
                    isAdmin: data.isAdmin,
                    isService: data.isService,
                    name: data.name,
                    surname: data.surname,
                });

                return this.props.history.replace("/users");
            } else {


                const result = await UserService.edit(_id,{
                    username: data.username,
                    isAdmin: data.isAdmin,
                    isService: data.isService,
                    name: data.name,
                    surname: data.surname,
                });


                return this.props.history.replace("/users");
            }
        } catch (ex) {
            const errors = {...this.state.errors};

            if(ex.response.data.username !== undefined)
            {
                errors.username = ex.response.data.username;
            }
            else if(ex.response.data.password !== undefined)
            {
                errors.password = ex.response.data.password;
            }
            else
            {
                toast.error("Błędne dane")
            }

            console.log(errors);
            this.setState({errors});
        }
    };

    render() {
        return (
            <Container className="mt-5">
                <Row className="justify-content" noGutters="true">
                    <Col md="2" xs="2">
                        {" "}
                    </Col>
                    <Col md="8" xs="8">
                        <h3>Nowy użytkownik</h3>
                        <Form onSubmit={this.handleSubmit}>
                            {/* {this.renderSelect("priviliges", "Uprawnienia", priviliges)} */}
                            {this.renderInput("username", "Nazwa użytkownika")}

                            {this.state._id === "new" && this.renderInput("password", "Hasło", "password")}
                            {this.state._id === "new" && this.renderInput("repassword", "Powtórz hasło", "password")}

                            {this.renderCheck("isAdmin", "Admin", "checkbox")}
                            {this.renderCheck("isService", "Serwis", "checkbox")}
                            <hr></hr>
                            {this.renderInput("name", "Imię")}
                            {this.renderInput("surname", "Nazwisko")}

                            <div className="mt-3">{this.renderButton("Dodaj")}</div>
                        </Form>
                    </Col>
                    <Col md="2" xs="2">
                        {" "}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default RegisterForm;
