import http from "./httpService";

const apiEndpoint = "/audioplayerLog";

export function getByShopAndDay(shopId,day){
    return http.get(apiEndpoint+"/"+shopId + "/date/" + day);
}





