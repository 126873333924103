import React from "react";
import Form from "react-bootstrap/Form";

const Input = ({ name, value, label, error, onChange, type, ...rest }) => {
  return (
    <Form.Group controlId={name} className="mt-3">
      <Form.Label>{label}</Form.Label>

      <Form.Control type={type} name={name} onChange={onChange} value={value} isValid={value&&!error} isInvalid={error} {...rest}/>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>

    </Form.Group>
  );
};

export default Input;
