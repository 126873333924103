import React from "react";
import {useState, useEffect} from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink as link} from "@fortawesome/free-solid-svg-icons";
import {faEthernet} from "@fortawesome/free-solid-svg-icons";
import {faWifi} from "@fortawesome/free-solid-svg-icons";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {faStop} from "@fortawesome/free-solid-svg-icons";



import {prepareDate, prepareDateWithoutHours, prepareHours} from "../../../utils/dateFormat";
import {FaBottleWater} from "react-icons/fa6";

export default function CardDeviceFooter({onlineDeviceData, device, onChangeConf}) {
    const [sn, setSN] = useState("");
    const [hw, setHW] = useState("");

    // useEffect(() => {

    // });

    const activeAlwaysClass = (device?.always_active && device?.always_active) ? {fontWeight: "bold"} : {}
    const isWifi = onlineDeviceData.online === true ? (onlineDeviceData.rssi !== 0 ? true : false) : (device?.meta?.rssi !== 0 ? true : false);

    let liquidLevel = -1;
    if (device.dev_type === "MD") {
        if (onlineDeviceData.online === true) {
            liquidLevel = onlineDeviceData.liquidLevel;
        } else {
            liquidLevel = (device?.meta?.liquidLevel !== null) && (device?.meta?.liquidLevel !== undefined) ? device?.meta?.liquidLevel : -1;
        }
    }
    return (
        <Card.Footer>
            {onlineDeviceData.audioplayerInfo && (
            <Row md="12" xs="12" style={{marginBottom: "20px"}}>
                <Col md="12" xs="12">
                    {onlineDeviceData.audioplayerInfo !== null && onlineDeviceData.audioplayerInfo !== undefined &&
                        <FontAwesomeIcon
                            style={{marginLeft: "30px", marginRight: "30px"}}
                            icon={onlineDeviceData.audioplayerInfo.playing ? faPlay : faStop}
                            size="1x"
                            color={"black"}
                        />
                    }
                    {onlineDeviceData.audioplayerInfo.playing && (
                        <>
                            <small className="text-muted"> Od: {prepareHours(onlineDeviceData.audioplayerInfo.start * 1000)}</small>
                            <small className="text-muted"> do: {prepareHours(onlineDeviceData.audioplayerInfo.end * 1000)}</small>

                            <small style={{marginLeft: "30px"}} className="text-muted">
                                Dostawca: {onlineDeviceData.audioplayerInfo.source}
                            </small>
                            <small style={{marginLeft: "30px"}} className="text-muted">
                                Pozycja: {onlineDeviceData.audioplayerInfo.position} / {onlineDeviceData.audioplayerInfo.count}
                            </small>


                        </>
                    )}

                    <small style={{marginLeft: "30px"}} className="text-muted">
                        {onlineDeviceData.audioplayerInfo.dso ? "DSO" : null}
                    </small>

                </Col>
            </Row>)}
            <Row md="12" xs="12">
                <Col md="10" xs="10">
                    <small className="text-muted"> Ostatni status: {prepareDate(device.last_seen)}</small>
                    <small style={{marginLeft: "30px"}} className="text-muted">
                        FW: {onlineDeviceData.online === true ? onlineDeviceData.fw : device.fw_version}
                    </small>
                    <small style={{marginLeft: "30px"}} className="text-muted">
                        {"   "}
                        HW: {onlineDeviceData.online === true ? onlineDeviceData.hw : device.hw_version}
                    </small>
                    <small style={{marginLeft: "30px"}} className="text-muted">
                        Pamięć: {onlineDeviceData.online === true ? onlineDeviceData.msize : device?.meta?.msize}
                    </small>
                    <small style={{marginLeft: "30px"}} className="text-muted">
                        IP: {onlineDeviceData.online === true ? onlineDeviceData.ip : device?.meta?.ip}
                    </small>

                    {isWifi &&
                        <small style={{marginLeft: "30px"}} className="text-muted">
                            RSSI: {onlineDeviceData.online === true ? onlineDeviceData.rssi : device?.meta?.rssi}
                        </small>
                    }

                    <small style={{marginLeft: "30px"}} className="text-muted">
                        H: {onlineDeviceData.online === true ? onlineDeviceData.heap : device?.meta?.heap}
                    </small>

                    <FontAwesomeIcon
                        style={{marginLeft: "30px"}}
                        icon={isWifi ? faWifi : faEthernet}
                        size="1x"
                        color={"black"}
                    />


                    {(liquidLevel === 1) ?
                        <FaBottleWater size={"1.2rem"} style={{textAlign: "center", color: "green"}}
                                       className={"mx-4"}/> : null
                    }

                    {(liquidLevel === 0) ?
                        <>
                            <FaBottleWater size={"1.2rem"} style={{textAlign: "center", color: "red"}}
                                           className={"ms-4"}/>
                            <small style={{marginLeft: "1px"}} className="text-muted">Niski poziom
                                płynu
                            </small>
                        </> : null
                    }


                </Col>
                <Col md="2" xs="2" style={{textAlign: "right"}}>
                    <small style={{marginRight: "10px", ...activeAlwaysClass}} className={"text-mute"}>
                        {onlineDeviceData.online === true ? "online" : "offline"}
                    </small>{" "}
                    <FontAwesomeIcon
                        icon={link}
                        size="1x"
                        color={onlineDeviceData.online === true ? "green" : "red"}
                    />
                </Col>
            </Row>

            {(device.sn === "1000000" ||
                device.sn === "2000000" ||
                device.sn === "3000000" ||
                device.sn === "4000000" ||
                device.sn === "6000000") && (
                <Row md="12" xs="auto" className="mt-3">
                    <Col>
                        <small style={{marginLeft: "30px"}} className="text-muted">
                            {"   "}
                            NOWY SN:
                        </small>
                        <input
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width: "70px",
                            }}
                            type="text"
                            name="name"
                            value={sn}
                            onChange={(e) => setSN(e.target.value)}
                        />
                    </Col>
                    <Col>

                        <small style={{marginLeft: "10px"}} className="text-muted">
                            {"   "}
                            HW:
                        </small>
                        <input
                            style={{marginRight: "10px", marginLeft: "10px", width: "70px"}}
                            type="text"
                            name="name"
                            value={hw}
                            onChange={(e) => setHW(e.target.value)}
                        />
                    </Col>
                    <Col>
                        <Button
                            className="align-self-center"
                            onClick={() => {
                                onChangeConf({action: "conf", sn_new: sn, hw: hw});
                            }}
                            variant="primary"
                        >
                            Zapisz
                        </Button>
                    </Col>
                </Row>
            )}
        </Card.Footer>
    );
}
