import http from "./httpService";

const apiEndpoint ="/playlists";


export function get(id) {
    return http.get(apiEndpoint+"/"+id);
}

export function add(data) {
    return http.post(apiEndpoint, data);
}

export function update(_id,data) {
    return http.put(apiEndpoint+"/"+_id, data);
}

export function updateActive(_id) {
    return http.put(apiEndpoint+"/active/"+_id);
}

export function updateFiles(_id,data) {
    return http.put(apiEndpoint+"/files/"+_id, data);
}


export function getAll() {
    return http.get(apiEndpoint+"/");
}

export function remove(id) {
    return http.delete(apiEndpoint+"/"+id);
}

const PlaylistsService = {get,add,update,updateActive, updateFiles,getAll,remove};

export default PlaylistsService;

