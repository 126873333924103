import React, {Component} from "react";
import Joi from "joi";
import Input from "./input";
import InputH from "./inputHorizontal";
import Check from "./check";
import Select from "./select";
import Button from "react-bootstrap/Button";
import ConfirmationButton from "./confirmationButton";
import ConfirmationSubmitButton from "./confirmationSubmitButton";

class UForm extends Component {
    state = {
        data: {},
        file: {},
        errors: {},
    };

    validateProperty = ({name, value}) => {
        const obj = {[name]: value};
        const {error} = this.schema.validate(obj);

        return error ? error.details[0].message : null;
    };

    handleChange = ({currentTarget: input}) => {
        const errors = {...this.state.errors};

        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({data, errors});
    };

    handleCheckChange = ({currentTarget: input}) => {
        const data = {...this.state.data};
        data[input.name] = input.checked;
        this.setState({data});
    };

    handleFileChange = (input, accept) => {
        const errors = {...this.state.errors};
        console.log(input.files[0]);

        let errorMessage;
        if (!input.files[0]) errorMessage = "Brak pliku";
        else if (input.files[0].size > 10000000) errorMessage = "Plik jest za duży.";

        //Obejście problemu chwilowe
        if (accept === "audio/mpeg") {
            if (input.files[0].type !== accept) errorMessage = "Niepoprawny format pliku";
        }

        if (errorMessage) {
            errors[input.name] = errorMessage;
        } else {
            delete errors[input.name];
        }

        let file = this.state.file;
        file = input.files[0];
        this.setState({file, errors});
    };

    handleFwFileChange = (input, accept) => {
        const errors = {...this.state.errors};
        console.log(input.files[0]);

        let errorMessage;
        if (!input.files[0]) errorMessage = "Brak pliku";
        else if (input.files[0].size > 100000000) errorMessage = "Plik jest za duży.";

        // //Obejście problemu chwilowe
        // if(accept === "audio/mpeg")
        // {
        //   if(input.files[0].type !== accept) errorMessage = "Niepoprawny format pliku";
        // }

        if (errorMessage) {
            errors[input.name] = errorMessage;
        } else {
            delete errors[input.name];
        }

        let file = this.state.file;
        file = input.files[0];
        this.setState({file, errors});
    };

    validate = () => {
        const options = {abortEarly: false};

        const {error} = this.schema.validate(this.state.data, options);
        const errors = {};

        if (error) {
            // console.log(error)
            error.details.map((error) => (errors[error.path] = error.message));
        } else {
            return null;
        }

        return errors;
    };

    handleSubmit = (e) => {
        if (e) e.preventDefault();

        const errors = this.validate();
        this.setState({errors: errors || {}});
        console.log(errors)
        if (errors) {
            return;
        }
        this.doSubmit();
    };

    handleBack = (e) => {
        if (e) e.preventDefault();

        this.goBack();
    };

    renderInput = (name, label, type = "text", ...rest) => {
        const {data, errors} = this.state;

        return (
            <Input
                value={data[name]}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                onChange={this.handleChange}

                {...rest}
            />
        );
    };

    renderNumberInput = (name, label, type = "number", min = "0", max = "99", ...rest) => {
        const {data, errors} = this.state;

        return (
            <Input
                value={data[name]}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                onChange={this.handleChange}
                min={min}
                max={max}
                {...rest}
            />
        );
    };

    renderInputHorizontal = (name, label, type = "text", ...rest) => {
        const {data, errors} = this.state;

        return (
            <InputH
                value={data[name]}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                onChange={this.handleChange}
                {...rest}
            />
        );
    };

    renderCheck = (name, label, type = "checkbox", ...rest) => {
        const {data, errors} = this.state;

        return (
            <Check
                value={data[name]}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                onChange={this.handleCheckChange}
                {...rest}
            />
        );
    };

    renderFileInput = (name, label, accept, type = "file") => {
        const {errors} = this.state;

        // console.log(errors);
        return (
            <Input
                // value={data[name].name}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                accept={accept}
                onChange={({currentTarget: input}) => this.handleFileChange(input, accept)}
            />
        );
    };

    renderFwFileInput = (name, label, accept, type = "file") => {
        const {errors} = this.state;

        // console.log(errors);
        return (
            <Input
                // value={data[name].name}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                accept={accept}
                onChange={({currentTarget: input}) => this.handleFwFileChange(input, accept)}
            />
        );
    };

    renderSelect = (name, label, options) => {
        const {data, errors} = this.state;

        return (
            <Select
                value={data[name]}
                name={name}
                label={label}
                options={options}
                onChange={this.handleChange}
                error={errors[name]}
            />
        );
    };

    renderButton = (label) => {
        return (
            <Button disabled={this.validate()} variant="primary" type="submit">
                {label}
            </Button>
        );
    };

    renderButtonWithCancel = (label) => {
        return (
            <ConfirmationButton
                className={"mx-2 w-100"}
                label="Napewno anulować zmiany?"
                yesLabel="TAK"
                noLabel="NIE"
                variant="warning"
                onClick={this.handleBack}
            >
                {label}
            </ConfirmationButton>
        );
    };

    renderButtonWithConfirm = (label) => {
        return (
            <ConfirmationButton
                className={"mx-2 w-100"}
                label="Napewno zapisać zmiany?"
                yesLabel="TAK"
                noLabel="NIE"
                variant="primary"
                onClick={this.handleSubmit}
            >
                {label}
            </ConfirmationButton>
        );
    };

    renderDeleteButton = (label, onDelete) => {
        return (
            <Button
                className={"mx-2 w-100"}
                onClick={onDelete}
                variant="danger">
                {label}
            </Button>
        );
    };

    renderDeleteButtonWithConfirm = (label, onDelete) => {
        return (
            <ConfirmationButton
                label="Potwierdź usunięcie elementu"
                yesLabel="TAK"
                noLabel="NIE"
                variant="danger"
                onClick={onDelete}
                className={"mx-2 w-100"}
            >
                {label}
            </ConfirmationButton>
        );
    };
}

export default UForm;
