import {React, useState, useEffect} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {toast} from "react-toastify";

import * as AuthDeviceService from "../../../services/authDeviceService";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faSave} from "@fortawesome/free-solid-svg-icons";

export default function CardDeviceNameView({device, refreshData}) {
    const [info, setInfo] = useState("");
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setInfo(device?.info);
    }, [device]);

    const handleSaveChanges = async () => {
        try {
            const {data} = await AuthDeviceService.updateInfo({
                _id: device._id,
                info,
            });

            refreshData();
            setEdit(false);
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    };

    const handleEdit = (newInfo) => {
        if (device.dev_type === "MK" || device.dev_type === "MKS") {
            let infoNo = Number(newInfo)
            if (infoNo >= 0 && infoNo < 100) {
                setInfo(newInfo);
            }
        } else {
            if (newInfo.length < 100)
                setInfo(newInfo);
        }

    };

    const infoDiffrent = info !== device.info;
    // console.log(device)
    return (
        <div className="flex-container container__center mb-2">
            <div className="me-2 my-auto fw-bold h-100">Opis:</div>
            {
                ((device.dev_type === "MK") || (device.dev_type === "MKS")) ? (edit === true &&
                    <input type={"number"} min={0} max={99} value={info}
                           onChange={(e) => handleEdit(e.currentTarget.value)}></input>) : (edit === true &&

                    <input type={"text"} min={0} max={20} value={info}
                           onChange={(e) => handleEdit(e.currentTarget.value)}></input>)
            }

            {(edit === false) &&
                <div>{info}</div>
            }

            <FontAwesomeIcon onClick={() => setEdit(!edit)} className="tag__edit" icon={faEdit}/>
            {infoDiffrent ? (
                <FontAwesomeIcon onClick={handleSaveChanges} className="tag__save" icon={faSave}/>
            ) : null}
        </div>
    );
}
