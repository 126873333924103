import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink as link,
  faExpand as expand,
  faCompress as compress,
} from "@fortawesome/free-solid-svg-icons";

import LiveDeviceVisualisation from "./liveDeviceVisualisation";
import { getName } from "../../../utils/devType";



export default function CardDeviceHeader({
  expanded,
  onlineDeviceData,
  device,
  settings,
  onExpandedClick,
  onAction,
}) {
  return (
    <Card.Header style={{ background: "Lightgrey" }}>
      <Row>
        <Col className="font-weight-bold " onClick={onExpandedClick}> 
          <FontAwesomeIcon
            icon={expanded ? compress : expand}
            size="1x"
            style={{ marginRight: "10px" }}
          />
          {getName(device.dev_type)}
        </Col>
        <Col  className="my-1" sm="8" style={{ background: "white", borderRadius: "10px" }}>
          <LiveDeviceVisualisation
            onlineDeviceData={onlineDeviceData}
            devType={device.dev_type}
            settings={settings}
            onAction={onAction}
          />
        </Col>
        <Col
          className="font-weight-bold"
          style={{ textAlign: "right", width: "100%" }}
        >
          SN: {device.sn}
        </Col>
      </Row>
    </Card.Header>
  );
}
