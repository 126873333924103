export function findShopsInUseDuringLastHour(allShops,devices)
{
    const shops = [];

    if (allShops) {
        if (allShops.length !== 0) {
            allShops.forEach((shop) => {
                if(!devices || devices.length === 0) return;
                const result = devices.findIndex((device,index) => {
                    // console.log(index)
                    return (String(device.shop_id) === String(shop._id) && (Date.parse(device.last_seen) > Date.now() - 3600000));
                });
                if (result !== -1){
                    shops.push(shop);
                }
            });
        }
    }

    return shops;
}

export function findShopsWithDevices(allShops,devices)
{
    const shops = [];


    if (allShops) {
        if (allShops.length !== 0) {
            allShops.forEach((shop) => {
                const result = devices.findIndex((device) => {
                    return String(device.shop_id) === String(shop._id);
                });
                if (result !== -1) shops.push(shop);
            });
        }
    }


    return shops;
}