import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import { toast } from "react-toastify";

import * as AuthDeviceService from "../services/authDeviceService";
import TagService from "../services/tagService";

import Pagination from "./common/pagination";
import { prepareTableForList } from "../utils/devType";
import { search } from "../utils/search";
import { paginate } from "../utils/paginate";
import UListGroup from "./common/uListGroup";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";
import TagAddFormModal from "./tagAddFormModal";

import TagsTable from "./tagsTable";
import Sync from "./common/sync";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import { isAdminOrIsService, isService } from "../services/authService";

class Tags extends Component {
  state = {
    tags: [],
    devTypes: [],
    authDevices: [],
    pageSize: 100,
    currentPage: 1,
    selectedDevType: "",
    sortColumn: { path: "name", order: "asc" },
    searchQuery: "",
    unauthDeviceAddFormModal: false,
    tagAddFormModal: false,
    tagToEdit: null,
  };

  async componentDidMount() {
    try {
      const { data: tags } = await TagService.getAll();
      const { data: authDevices } = await AuthDeviceService.getAll();

      tags.forEach((tag) => {
        let count = 0;
        authDevices.forEach((device) => {
          if (device?.tags?.includes(tag._id)) count++;
        });
        tag.count = count;
      });

      const devTypes = prepareTableForList();
      let selectedDevType = null;
      if (devTypes) selectedDevType = devTypes[0];
      this.setState({ tags, devTypes, selectedDevType });
    } catch (err) {
      toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  }

  handleAddTag = () => {
    this.setState({ tagAddFormModal: true, tagToEdit: null });
  };

  handleFormHide = async () => {
    this.setState({ tagAddFormModal: false });
    const { data: tags } = await TagService.getAll();
    const { data: authDevices } = await AuthDeviceService.getAll();

    tags.forEach((tag) => {
      let count = 0;
      authDevices.forEach((device) => {
        if (device?.tags?.includes(tag._id)) count++;
      });
      tag.count = count;
    });


    this.setState({ tags });
  };

  handleDelete = async (event, tag) => {
    if (event) event.stopPropagation();

    const { data } = await TagService.remove(tag._id);

    const { data: tags } = await TagService.getAll();

    this.setState({ tags });
  };

  handleRowClick = (unauthDevice) => {
    this.setState({
      selectedDevice: unauthDevice,
      unauthDeviceAddFormModal: true,
    });
  };

  handleEdit = (event, tag) => {
    if (event) event.stopPropagation();
    // console.log(tag.dev_types);
    this.setState({ tagToEdit: tag, tagAddFormModal: true });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleDevTypeSelect = (devType) => {
    this.setState({
      selectedDevType: devType,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path === "") return;

    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      tags: allTags,
      selectedDevType,
      sortColumn,
      searchQuery,
    } = this.state;

    let filtered = allTags;

    if (searchQuery) {
      filtered = search(allTags, ["name"], searchQuery);
    } else if (selectedDevType && selectedDevType._id) {
      filtered = search(allTags, ["dev_types"], selectedDevType.type, true);
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const tags = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: tags };
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedDevType: "",
      currentPage: 1,
    });
  };

  render() {
    const count = this.state.tags.length;
    const {
      pageSize,
      currentPage,
      devTypes,
      selectedDevType,
      sortColumn,
      searchQuery,
      tagAddFormModal,
      tagToEdit,
    } = this.state;

    const { totalCount, data: tags } = this.getPagedData();

    return (
      <React.Fragment>
        <ConfirmationModalContextProvider>
          <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
            <Breadcrumb.Item
              style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }}
              href="/"
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }}
            >
              Konfiguracja
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }}
              active
            >
              Zarządzanie Tagami
            </Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            <Row>
              <Col xs="2" style={{ textAlign: "left" }}>
                <Button onClick={this.handleAddTag} variant="primary">
                  Dodaj
                </Button>
              </Col>

              {/* {count !== 0 && (
              // <Col xs="11" style={{ textAlign: "center", width: "100%" }}>
              //   <h3>Lista urządzeń oczekujących na autoryzację: {count}</h3>
              // </Col>
            } */}

              <Col xs="9" style={{ textAlign: "center" }}>
                {count === 0 && <h3>Brak tagów</h3>}
              </Col>

              <Col xs="1" style={{ textAlign: "right" }}>
                <Sync onClick={() => (window.location = "/tags")} />
              </Col>
            </Row>

            {count !== 0 && (
              <React.Fragment>
                <Row>
                  <Col xs="12">
                    <SearchBox value={searchQuery} onChange={this.handleSearch} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="2">
                    <UListGroup
                      items={devTypes}
                      onItemSelect={this.handleDevTypeSelect}
                      selectedItem={selectedDevType}
                      horizontal={false}
                    />
                  </Col>

                  <Col lg="10">
                    <Row>
                      <Col>
                        <TagsTable
                          tags={tags}
                          sortColumn={sortColumn}
                          onDelete={this.handleDelete}
                          onEdit={this.handleEdit}
                          onSort={this.handleSort}
                          onRowClick={this.handleRowClick}
                          authDevices={this.state.authDevices}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" lg="12">
                        <Pagination
                          itemsCount={totalCount}
                          pageSize={pageSize}
                          currentPage={currentPage}
                          onPageChange={this.handlePageChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <TagAddFormModal
              tagToEdit={tagToEdit}
              show={tagAddFormModal}
              devTypes={devTypes}
              onHide={this.handleFormHide}
            />
          </Container>
        </ConfirmationModalContextProvider>
      </React.Fragment>
    );
  }
}

export default Tags;
