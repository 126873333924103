import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) {
      return column.content(item);
    }

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return item[this.props.dataId] + (column.path || column.key);
  };

  render() {
    const { data, id, columns, onRowClick } = this.props;

    return (
      <tbody>
        {data.map((item) => (
          <tr
            key={item[id]}
            onClick={() => {
              if (onRowClick !== undefined) {
                onRowClick(item);
              }
            }}
            
          >
            {columns.map((column) => (
              <td
                key={this.createKey(item, column)}
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
