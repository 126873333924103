export function search(data, tags, searchQuery, strict = false) {
  const searchResult = data.filter((item) => {
    let result = false;

    const shopFilter = new RegExp("([0-9]{4})+:+([0-9]{4})");

    if(shopFilter.test(searchQuery)){
      const shopNoMin = Number(searchQuery.split(":")[0]);
      const shopNoMax = Number(searchQuery.split(":")[1]);

      const shopName = Number(item.shop_name);
      if(shopName >= shopNoMin && shopName <= shopNoMax){
        return true;
      }
      else {
        return false;
      }
    }

    tags.forEach((tag) => {
      if (typeof item[tag] === "object") {
        if (!result)
          result = compareObject(item[tag], searchQuery, strict);
      }
      else {
        if (!result)
          result = !strict
            ? item[tag]?.toLowerCase().startsWith(searchQuery.toLowerCase())
            : item[tag]?.toLowerCase() === searchQuery.toLowerCase();
      }

    });

    return result;
  });

  return searchResult;
}

function compareObject(object, searchQuery, strict = false) {
  let result = false;
  object.forEach(element => {
    if (!result)
      result = !strict
        ? element?.toLowerCase().startsWith(searchQuery.toLowerCase())
        : element?.toLowerCase() === searchQuery.toLowerCase();
  })



  return result;
}


export function searchInShopTable(data, tags, searchQuery, strict = false) {
  const searchResult = data.filter((item) => {
    let result = false;

    tags.forEach((tag) => {
      if (!result) {
        if (tag === "name") {
          let shopNo = Number(item[tag]);
          let query = Number(searchQuery);
          if (query != null && shopNo != null) {
            result = shopNo === query;
            if (result) {
              return;
            }
          }
        }

        result = item[tag].toLowerCase().startsWith(searchQuery.toLowerCase());
      }
    });

    return result;
  });

  return searchResult;
}

