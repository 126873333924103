import React from "react";
import Table from "react-bootstrap/Table";

function BeepSettingsAudioModulesTableHeader() {
    return (
        <React.Fragment>
            <thead>
            <tr>
                <th style={{textAlign: "center", margin: "0", padding: "0"}}>
                    Device
                </th>
                <th
                    style={{margin: "0", padding: "0", textAlign: "center"}}
                    colSpan="12"
                >
                    <Table
                        borderless={true}
                        style={{textAlign: "center", margin: "0", padding: "0"}}
                    >
                        <tbody>
                        {/* <tr>
              <td colSpan="4" style={{ margin: "0", padding: "0" }}>
                {" "}
                Głośniki
              </td>
            </tr> */}
                        <tr>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                                return (<td key={item}
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        width: "8%",
                                    }}
                                >
                                    {item}
                                </td>)
                            })}


                        </tr>
                        </tbody>
                    </Table>
                </th>
                <th style={{textAlign: "center", margin: "0", padding: "0"}}>
                    On/Off
                </th>
            </tr>
            </thead>
        </React.Fragment>
    );
}

export default BeepSettingsAudioModulesTableHeader;
