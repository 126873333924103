import React from "react";

import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import distanceSettingsControlMKOneSensor from "./distanceSettingsControlMKOneSensor";
import config from "../../../config.json";
import DistanceSettingsControlMKOneSensor from "./distanceSettingsControlMKOneSensor";


const DistanceSettingsControlMK = ({
                                       label,
                                       id,
                                       value,
                                       activeMinRangeChange,
                                       onActiveMinRangeChange,
                                       onActiveRangeChange,
                                       onEnableChange,
                                       diffActiveMinRangeChange,
                                       diffActiveRangeChange,
                                       diffEnableChange,
                                       onBlockSensorChange,
                                       sensorDisabling,
                                   }) => {
    const disabled = !value.enable ? true : false;
    return (
        <>
            <tr className="align-middle">
                <th
                    style={{
                        verticalAlign: "center",
                        textAlign: "center",
                        minWidth: "100px",
                        background: "black",
                        margin: "0",
                        padding: "0",
                    }}
                    rowSpan={5}
                >
                    <p className="m-1" style={{textAlign: "center", color: "white"}}>
                        {label}

                    </p>
                    <Form.Check
                        type="switch"
                        id={id}
                        className="m-2"
                        checked={value.enable}
                        onChange={(e) => onEnableChange(e.target.checked, value.id)}
                    />
                </th>
                <th
                    className={diffActiveMinRangeChange ? "bg-success" : null}
                    style={{
                        verticalAlign: "right",
                        textAlign: "right",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                    }}
                >

                </th>
                <th
                    className={diffActiveMinRangeChange ? "bg-success" : null}
                    style={{
                        verticalAlign: "right",
                        textAlign: "right",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    <p className="mx-0 my-0" style={{textAlign: "center"}}>
                        Minimalna odległość blokady:
                    </p>
                </th>

                <th
                    className={diffActiveRangeChange ? "bg-success" : null}
                    style={{
                        verticalAlign: "center",
                        textAlign: "center",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    <p className="mx-0 my-0" style={{textAlign: "center"}}>
                        Odległość aktywna:
                    </p>
                </th>

                <th
                    className={diffActiveRangeChange ? "bg-success" : null}
                    style={{
                        verticalAlign: "center",
                        textAlign: "center",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    <p className="mx-0 my-0" style={{verticalAlign: "center", fontWeight: "bold"}}>
                        Blokada czujnika:
                    </p>
                </th>
            </tr>

            {[0, 1, 2, 3].map((no) =>
                <DistanceSettingsControlMKOneSensor
                    key={id+ "s_" + no}
                    no={no}
                    id={id}
                    value={value}
                    onActiveMinRangeChange={onActiveMinRangeChange}
                    activeMinRangeChange={activeMinRangeChange}
                    onActiveRangeChange={onActiveRangeChange}
                    onEnableChange={onEnableChange}
                    onBlockSensorChange={onBlockSensorChange}
                    sensorDisabling={sensorDisabling}/>
            )}

        </>
    );
};

export default DistanceSettingsControlMK;
