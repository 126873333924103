import http from "./httpService";

import config from "../config.json";

const apiEndpoint = "/settings";

// export function updateValue(data) {
//     return http.put(apiEndpoint + "/value/" + data.what, data);
// }

export function updateSetting(settingName,data) {
    return http.put(apiEndpoint + "/" + settingName, data);
}

export function getSetting(settingName) {
    return http.get(apiEndpoint + "/" + settingName);
}

// export function getAll() {
//     return http.get(apiEndpoint);
// }

// export function remove(settingName){
//     return http.delete(apiEndpoint+"/"+what);
// }

const SettingsService = { getSetting,updateSetting };

export default SettingsService;
