import React from "react";
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync as sync } from "@fortawesome/free-solid-svg-icons";

const Sync = ({ onClick, liked,size }) => {
  const _size =  size ? size : "2x";
  return (
    <Button variant="outline-dark" onClick={onClick}>
      <FontAwesomeIcon icon={sync} size={_size} />
    </Button>
  );
};

export default Sync;
