import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import Table from "react-bootstrap/Table"

import "../../App.css";

const UTable = ({columns, onSort, sortColumn, data, dataId, onRowClick}) => {

  return (
    <Table hover striped size="sm" className="cursor-default">
      <TableHeader
        columns={columns}
        onSort={onSort}
        sortColumn={sortColumn}
      />
      <TableBody data={data} id={dataId} columns={columns} onRowClick={onRowClick} />
    </Table>
  );
};

export default UTable;
