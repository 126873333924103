import React from "react";
import Joi from "joi";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UForm from "./common/uform";

import * as FileService from "../services/fileService";
import { toast } from "react-toastify";

class FileAddFormModal extends UForm {
  state = {
    data: {
      name: "",
      info: "",
    },
    file: null,
    errors: {},
  };

  schema = Joi.object({
    name: Joi.string()
      .min(1)
      .max(15)
      .required()
      .optional()
      .label("Nazwa")
      .messages({
        "string.empty": `Nazwa nie może być pusta`,
        "string.max": `"Nazwa może posiadać maksymalnie {#limit} znaków`,
        "any.required": `Nazwa jest wymagana`,
      }),
    info: Joi.string()
      .allow(null)
      .allow("")
      .max(100)
      .optional()
      .label("Opis")
      .messages({
        "string.max": `"Opis nie może być dłuższy niż {#limit} znaków`,
      }),
  });

  componentDidMount = async () => {
    const data = this.state.data;

    this.setState({ data });
  };

  handleShow = () => {
    const data = this.state.data;
    let file = this.state.file;

    data.name = "";
    data.info = "";
    file = null;

    this.setState({ data, file });
  };

  doSubmit = async () => {
    console.log("Submit!!!");

    if (!this.state.file) return toast.error("Proszę załaczyć plik!!");

    const data = new FormData();
    data.append("file", this.state.file);
    data.append("name", this.state.data.name);
    data.append("info", this.state.data.info);
    
    try {
      const { data: newFile } = await FileService.add(data);

      toast.success("Plik " + newFile.name + ".mp3 został dodany!");

      this.props.onHide();
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
      }
    }
  };

  render() {
    //const { searchQuery, filteredShops: shops } = this.state;

    const { show, onHide } = this.props;

    return (
      <Modal
        show={show}
        onShow={this.handleShow}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Dodaj plik
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            {this.renderInput("name", "Nazwa")}

            {this.renderInput("info", "Opis")}

            {this.renderFileInput("file", "Plik MP3", "audio/mpeg")}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} variant="primary">
              Anuluj
            </Button>
            {this.renderButton("Dodaj")}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default FileAddFormModal;
