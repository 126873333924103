function get(object, id) {
  return object.findIndex((x) => x.id === id);
}

export function activeMinRange(object, maxRange, id) {
  const index = get(object, id);
  object[index].maxRange = maxRange;

  return object;
}

export function activeMinRangeMK(object,sensorNo, maxRange, id) {
  const index = get(object, id);
  object[index].maxRange[sensorNo] = maxRange;

  return object;
}


export function activeRange(object, activeRange, id) {
  const index = get(object, id);
  object[index].activeRange = activeRange;

  return object;
}

export function activeRangeMK(object,sensorNo, activeRange, id) {
  const index = get(object, id);
  object[index].activeRange[sensorNo] = activeRange;
  return object;
}

export function enable(object, value, id) {
  const index = get(object, id);
  object[index].enable = value;

  return object;
}

export function blockSensor(object, value,vlSensorNo, id) {
  const index = get(object, id);
  
  object[index].blockSensor[vlSensorNo] = value;

  return object;
}
