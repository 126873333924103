import React from "react";

import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";


const distanceSettingsControlMKOneSensor = ({
                                                no,
                                                id,
                                                value,
                                                activeMinRangeChange,
                                                onActiveMinRangeChange,
                                                onActiveRangeChange,
                                                diffActiveMinRangeChange,
                                                diffActiveRangeChange,
                                                diffEnableChange,
                                                onBlockSensorChange,
                                                sensorDisabling,
                                            }) => {
    const disabled = !value.enable ? true : false;
    return (
        <>
            <tr className="align-middle">

                <th
                    className={diffActiveMinRangeChange ? "bg-success" : null}
                    style={{
                        textAlign: "center",
                        minWidth: "100px",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    <b>{no+1}:</b>
                </th>
                {activeMinRangeChange === true && (
                    <td
                        className={diffActiveMinRangeChange ? "bg-success" : null}
                        style={{
                            textAlign: "center",
                            minWidth: "100px",
                            margin: "0",
                            padding: "0",
                        }}
                    >
                        <input
                            className="m-2"
                            style={{width: "60px"}}
                            type="number"
                            min="0"
                            max="400"
                            key="0"
                            value={value.maxRange[no]}
                            onChange={({currentTarget: input}) => onActiveMinRangeChange(no,input.value, value.id)}
                            disabled={disabled}
                        />
                    </td>
                )}
                <td
                    className={diffActiveRangeChange ? "bg-success" : null}
                    style={{
                        textAlign: "center",
                        minWidth: "100px",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    <input
                        type="number"
                        className="m-2"
                        style={{width: "60px"}}
                        min="10"
                        max="400"
                        key="1"
                        value={value.activeRange[no]}
                        onChange={({currentTarget: input}) => onActiveRangeChange(no, input.value, value.id)}
                        disabled={disabled}
                    />
                </td>

                <td
                    className={diffEnableChange ? "bg-success" : null}
                    style={{
                        verticalAlign: "center",
                        textAlign: "center",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    <Form.Check
                        type="checkbox"
                        id={id + "s_"+no}
                        key={id + "s_1"}
                        className="mx-3 my-0 py-0 my-0 py-0"
                        checked={value.blockSensor[no]}
                        onChange={(e) => onBlockSensorChange(no, e.target.checked, value.id)}
                        disabled={disabled}
                    />
                </td>
            </tr>
        </>
    );
};

export default distanceSettingsControlMKOneSensor;
