import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const InputH = ({ name, value, label, error, onChange, type, ...rest }) => {
  return (
    <Form.Group controlId={name} className="mt-3">
      <Row>
        <Col>
          <Form.Label>{label}</Form.Label>
        </Col>

        <Col>
          <Form.Control
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            isValid={value && !error}
            isInvalid={error}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Row>
    </Form.Group>
  );
};

export default InputH;
