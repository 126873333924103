import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DetectionTimeoutSettingsControl from "./detectionTimeoutSettingsControl";

export default function ManyGenerateTimeoutBlock(props) {

  const isDiffStyle = (enable, style) => {
    if (enable) return style + " bg-success";
    else return style;
  };

  

  return (
    <Row className={isDiffStyle(props.enable, "w-100 mx-0 px-0")}>
      <Col xs="2" className="d-flex justify-content-center">
        <Form.Check
          type="switch"
          id="dt_cb"
          className="align-self-center"
          checked={props.enable}
          onChange={(e) => props.onEnableChange(e.target.checked, props.name)}
        />
      </Col>
      <Col xs="10">
        <DetectionTimeoutSettingsControl
          label={props.label}
          device={{ sn: "0000000" }}
          min={props.min}
          max={props.max}
          value={props.value}
          error={props.error}
          enable={props.enable}
          onDetectionTimeoutChange={(value) => props.onTimeoutChange(value, props.name, props.min, props.max)}
        />
      </Col>
    </Row>
  );
}

