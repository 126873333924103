import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
// import mp3Duration from "../../utils/mp3Duration";

import styled from "styled-components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloudArrowUp} from '@fortawesome/free-solid-svg-icons'
import MusicFilesService from "../../services/musicFilesService";

const InputFiles = styled.input`
  display: none;
  visibility: hidden;

  &:hover {
    background-color: #555;
  }
`;

const LabelContainer = styled.div`
  //padding: 0.5rem 1rem;
  //margin: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 5px;
  cursor: grab;
  background-color: white;//#61dafb;
`;


function UploadFiles({className, currentPath, setProgress, forceRefresh}) {
    const [filesInfo, setFilesInfo] = useState([]);


    const onDrop = useCallback(async (acceptedFiles) => {

        // const _filesInfo = [];

        // acceptedFiles.forEach(async file => {
        //
        //
        //     // const reader = new FileReader()
        //     //
        //     // reader.onabort = () => console.log('file reading was aborted')
        //     // reader.onerror = () => console.log('file reading has failed')
        //     // reader.onload = () => {
        //     //     // Do whatever you want with the file contents
        //     //     // const arrayBuffer = reader.result
        //     //     // const buffer = new Uint8Array(arrayBuffer);
        //     //     // const duration = mp3Duration(buffer)
        //     //
        //     //
        //     //     _filesInfo.push({file });//, duration});
        //     // }
        //     // await reader.readAsArrayBuffer(file)
        //
        //     _filesInfo.push({file});
        //
        // })

        setProgress(true);
        const data = new FormData()

        for (let x = 0; x < acceptedFiles.length; x++) {
            data.append('file', acceptedFiles[x])
        }
        console.log(acceptedFiles)
        console.log(currentPath)
        data.append('path', currentPath._id)

        if (acceptedFiles.length > 0) {
            try {
                let result = await MusicFilesService.add(data);
                console.log(result);
            } catch (e) {
                console.log(e)
            }
        }
        await forceRefresh();
        setProgress(false);

    }, [currentPath])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections
    } = useDropzone({onDrop, accept: {"audio/mp3": ['.mp3']}})


    return (
        <div className={className}>
            <div className={"w-100"}>
                <label className={"w-100 m-0 p-0"} htmlFor="uploadFiles" {...getRootProps()}>
                    <LabelContainer>
                        <FontAwesomeIcon
                            icon={faCloudArrowUp} size={"5x"}/>
                        <p>Kliknij i wybierz pliki lub przeciągnij...</p>
                    </LabelContainer>
                </label>
                <InputFiles {...getInputProps()}/>
            </div>
            <div className={"w-100"}>
                {filesInfo.length > 0 ? <p>Wybrano {filesInfo.length} plików:</p> : null}
                {filesInfo.map(fileInfo => {
                    return (<p key={fileInfo.file.name}>
                        {fileInfo.file.name} - {parseFloat(fileInfo.file.size * (9.537 * Math.pow(10, -7))).toFixed(2)} Mb
                        -
                        {/*{`${Math.round(fileInfo.duration / 60)} min ${Math.round(fileInfo.duration % 60)} sek`}*/}
                    </p>)
                })}
            </div>
        </div>
    );
}

export default UploadFiles;