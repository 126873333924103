
const zeroPad = (num, places) => String(num).padStart(places, '0')

export function prepareDateWithoutHours(data) {
  const date = new Date(data);

  const result = date.getFullYear() + "-" + zeroPad(date.getMonth()+1, 2) + "-" + zeroPad(date.getUTCDate(), 2)

  return result
}

export function prepareHours(data) {
  const date = new Date(data);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;

}

export function prepareDate(data) {
  const date = new Date(data);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formatDateTime = new Intl.DateTimeFormat("pl-PL", options).format;

  return formatDateTime(date);
}

export function prepareDateWithSec(data) {
  const date = new Date(data);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formatDateTime = new Intl.DateTimeFormat("pl-PL", options).format;

  return formatDateTime(date);
}