import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import ConfirmationButton from "./common/confirmationButton";

import {isAdmin} from "../services/authService";

import UTable from "./common/table";
import {prepareDate} from "../utils/dateFormat";
import {upload} from "../services/fwService"

import dictionary from "../PL.json";

class FwFilesTable extends Component {
    columns = [
        {
            path: "dev_type",
            label: "Typ urządzenie",
        },
        {
            path: "version",
            label: "Wersja",
        },
        {
            path: "hw_version",
            label: "HW"
        },
        {
            path: "info",
            label: "Opis",
        },
        {
            path: "date",
            label: "Data dodania",
            content: (fwFile) => {
                return prepareDate(fwFile.date);
            },
        }
    ];

    currentVersionColumn = {
        label: "Obowiązujący",
        path: "current",
        key: "current",
        content: (item) => {
            if (item.current)
                return (<ConfirmationButton
                    label={dictionary.fwFiles.fwFilesTable.currentButton_unset_confirmLabel}
                    yesLabel={dictionary.fwFiles.fwFilesTable.currentButton_confirmYesLabel}
                    noLabel={dictionary.fwFiles.fwFilesTable.currentButton_confirmNoLabel}
                    variant="success"
                    className="my-0"
                    size="sm"
                    onClick={(event) => this.props.onChangeCurrent(event, item, false)}
                >
                    {dictionary.fwFiles.fwFilesTable.currentButton_label_unset}
                </ConfirmationButton>);

            if (!item.current || (item.current == undefined))
                return (<ConfirmationButton
                    label={dictionary.fwFiles.fwFilesTable.currentButton_set_confirmLabel}
                    yesLabel={dictionary.fwFiles.fwFilesTable.currentButton_confirmYesLabel}
                    noLabel={dictionary.fwFiles.fwFilesTable.currentButton_confirmNoLabel}
                    variant="warning"
                    className="my-0"
                    size="sm"
                    onClick={(event) => this.props.onChangeCurrent(event, item, true)}
                >
                    {dictionary.fwFiles.fwFilesTable.currentButton_label_set}
                </ConfirmationButton>);
        },
    };

    downloadColumn = {
        path: "",
        key: "download",
        content: (fwFile) => (
            <Button

                variant="primary"
                className="my-0"
                size="sm"
                onClick={(e) => {
                    e.stopPropagation();
                    upload(fwFile);
                }}
            >
                Pobierz
            </Button>
        ),
    };

    deleteColumn = {
        path: "",
        key: "delete",
        content: (item) => (
            <ConfirmationButton
                label={dictionary.fwFiles.fwFilesTable.deleteButton_confirmLabel}
                yesLabel={dictionary.fwFiles.fwFilesTable.deleteButton_confirmYesLabel}
                noLabel={dictionary.fwFiles.fwFilesTable.deleteButton_confirmNoLabel}
                variant="danger"
                className="my-0"
                size="sm"
                onClick={(event) => this.props.onDelete(event, item)}
            >
                {dictionary.fwFiles.fwFilesTable.deleteButton_label}
            </ConfirmationButton>

        ),
    };

    constructor() {
        super();
        if (isAdmin()) {
            this.columns.push(this.currentVersionColumn);
            this.columns.push(this.downloadColumn);
            this.columns.push(this.deleteColumn);
        }
    }

    render() {
        const {fwFiles, sortColumn, onSort, onRowClick} = this.props;

        return (
            <UTable
                columns={this.columns}
                onSort={onSort}
                sortColumn={sortColumn}
                data={fwFiles}
                dataId="_id"
                onRowClick={onRowClick}
            />
        );
    }
}

export default FwFilesTable;
