import http from "./httpService";

import config from "../config.json";

const apiEndpoint = "/apiSettings";

export function updateValue(data) {
  return http.put(apiEndpoint + "/value/" + data.what, data);
}

export function updateEnable(data) {
  return http.put(apiEndpoint + "/enable/" + data.what, data);
}


export function getAll() {
  return http.get(apiEndpoint);
}

export function remove({what}){
    return http.delete(apiEndpoint+"/"+what);
}

const ApiSettingsService = { getAll, updateValue, updateEnable, remove };

export default ApiSettingsService;
