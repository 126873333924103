import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";

function AddFolder({show,folders, onClose, addFolder}) {

    const [folderName, setFolderName] = useState("");

    const handleAddFolder = async () => {
        await addFolder(folderName);
        onClose();
    }

    const handleFolderNameChange = (name) => {
        setFolderName(name);
    }

    return (

        <Modal show={show} onHide={onClose}>
            <Modal.Header style={{border: "0px"}}>
                <Modal.Title>Podaj nazwę:</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"py-0"}>
                <Form.Control type="text" value={folderName} onChange={(e) => handleFolderNameChange(e.target.value)}/>
            </Modal.Body>
            <Modal.Footer className={"d-flex justify-content-start align-items-stretch border-0 "}>
                <Button className="mx-2 flex-grow-1" variant="secondary" onClick={onClose}>
                    Anuluj
                </Button>
                <Button className="mx-2 flex-grow-1" variant="primary" onClick={handleAddFolder}>
                    Dodaj
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default AddFolder;