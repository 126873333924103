import React from "react";


const SimpleSelect = ({
  /* label, */ name,
  options,
  optionName,
  optionKey,
  onChange,
  value,
  ...rest
}) => {
  return (
    <select  name={name} onChange={(e) => onChange(e)} value={value} {...rest}>
      <option value="" />
      {options &&
        options.map((option) => {
          return (
            <option
              value={option[optionKey]}
              key={option[optionKey]}
              // selected={option[optionKey] === value ? true : false}
            >
              {option[optionName]}
            </option>
          );
        })}
    </select>
  );
};

export default SimpleSelect;
