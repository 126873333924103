import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { isAdmin } from "../services/authService";
import UTable from "./common/table";
import Input from "./common/input";
import ShopWifisInTable from "./common/shopWifisInTable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

class WifiTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nr. sklepu",
    },

    {
      path: "wifi",
      label: "WiFi",
      content: (shop) => {
        return (
          <ShopWifisInTable shop={shop} onRefreshData={this.props.onRefreshData}/>
        );
      },
    },
    {
      path: "city",
      label: "Miasto",
    },
  ];

  

  constructor() {
    super();
    // if(isAdmin()){
    // this.columns.push(this.editColumn);
    // }
  }

  render() {
    const { shops, sortColumn, onSort, onRowClick } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={shops}
        dataId="_id"
      />
    );
  }
}

export default WifiTable;
