import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import {toast} from "react-toastify";

import UForm from "./common/uform";
// import { isAdmin } from "../services/authService";


import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import Joi from "joi";
import Button from "react-bootstrap/Button";
import PlaylistsService from "../services/playlistsService";
import playlistsService from "../services/playlistsService";
import VolumeSettingsBlock from "./common/confControl/volumeSettingsBlock";
import Table from "react-bootstrap/Table";

const schemaPlaylist = Joi.object({
    name: Joi.string().min(1).max(100).required().optional().label("Nazwa"),
    randomizer: Joi.bool().required().optional().label("Losowość"),
});

class AddPlaylistForm extends UForm {
    state = {
        data: {
            name: "",
            randomizer: false,
        },
        errors: {},
    };

    schema = schemaPlaylist;

    componentDidMount = async () => {
        const _id = this.props.id;

        if (!_id) return;

        if (_id === "new") return;

        try {
            const {data: playlist} = await playlistsService.get(_id);
            if (!playlist) {
                this.props.onClose();
            }

            this.setState({
                data: this.mapToViewModel(playlist),
            });
        } catch (err) {
            // if (err.response) {
            //   if (err.response.status === 404) {

            //   }
            // }
            this.props.onClose();
        }
    };

    mapToViewModel(playlist) {
        return {
            name: playlist.name,
            randomizer: playlist.randomizer !== undefined ? playlist.randomizer : false,
        };
    }

    prepareToSend() {
        const playlist = this.state.data;
        return {
            name: playlist.name,
            date: new Date(Date.now()),
            randomizer: playlist.randomizer,
            // spkVolume: this.state.spkVolume,
            // playHourConf: {
            //     pn: [playlist.pnOpen, playlist.pnClose],
            //     wt: [playlist.wtOpen, playlist.wtClose],
            //     sr: [playlist.srOpen, playlist.srClose],
            //     czw: [playlist.czwOpen, playlist.czwClose],
            //     pt: [playlist.ptOpen, playlist.ptClose],
            //     sb: [playlist.sbOpen, playlist.sbClose],
            //     nd: [playlist.ndOpen, playlist.ndClose],
            // },
        };
    }

    doSubmit = async () => {
        const _id = this.props.id;

        try {

            const toSend = this.prepareToSend();
            console.log(toSend)

            if (!_id || _id === "new") {
                const {data: newPlaylist} = await PlaylistsService.add(toSend);

                if (newPlaylist) {
                    toast.success("Nowa playlista została dodana!");
                }

                this.props.onClose();
                this.props.forceRefresh();
                return;
            }

            const {data: updatedPlaylist} = await PlaylistsService.update(_id, toSend);
            if (updatedPlaylist) {
                toast.success("Dane zostały zmienione!");
            }

            this.props.onClose();
            this.props.forceRefresh();
            return;
        } catch (ex) {
            console.log(ex.response.data);
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                toast.error(ex.response.data);
                this.setState({errors});
            }
        }
    };

    // handleVolumeChange = (volume) => {
    //     // const { spkVolume } = this.state;
    //     let spkVolume = Number(volume);
    //     this.setState({
    //         spkVolume,
    //     });
    // };


    render() {

        // if (!isAdmin()) return <Redirect to="/shops" />;

        const dayHeaderStyle = {
            textAlign: "center",
            fontWeight: "bold",
            verticalAlign: "auto",
        };

        return (
            <ConfirmationModalContextProvider>
                <React.Fragment>

                    <Row className={"m-0 p-0"}>
                        <Col md="12" xs="12">
                            <Form onSubmit={this.handleSubmit}>
                                {/* {this.renderSelect("priviliges", "Uprawnienia", priviliges)} */}
                                {this.renderInput("name", "Nazwa")}

                                <hr className={"my-3"}
                                    style={{width: "100%", margin: "auto", background: "black"}}></hr>

                                {this.renderCheck("randomizer", "odtwarzanie losowe", "checkbox")}


                                <Row className={"mt-3"}>

                                    <Col className="mx-2 flex-grow-2" style={{textAlign: "left", width: "100%"}}>
                                        <Button className="mx-2 w-100" variant="secondary" onClick={this.props.onClose}>
                                            Anuluj
                                        </Button>
                                    </Col>
                                    <Col className="mx-2 flex-grow-2 " style={{textAlign: "left", width: "100%"}}>
                                        {this.props.id === "new" ? this.renderButtonWithConfirm("Dodaj") : this.renderButtonWithConfirm("Zapisz")}
                                    </Col>

                                    {/*<Button className="mx-2 flex-grow-1" variant="primary" onClick={() => {*/}
                                    {/*}}>*/}
                                    {/*    Dodaj*/}
                                    {/*</Button>*/}
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </React.Fragment>
            </ConfirmationModalContextProvider>
        );
    }
}

export default AddPlaylistForm;
