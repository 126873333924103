import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Input from "./input";

import { toast } from "react-toastify";

import WifiService from "../../services/wifiService";
import ConfirmationIcon from "./confirmationIcon";
import ConfirmationButton from "./confirmationButton";

import dictionary from "../../PL.json";

// import * as AuthDeviceService from "../../../services/authDeviceService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

export default function AddWifi({ shopId, onRefreshData, horizontal }) {
  const [ssid, setSSID] = useState("");
  const [pass, setPASS] = useState("");
  const [visibility, setVisibility] = useState(false);

  const handleSaveChanges = async () => {
    try {
      if (shopId !== null && shopId !== undefined) {
        if(shopId !== "all")
        {
          const { data } = await WifiService.add({
            ssid: ssid,
            pass: pass,
            shops: [shopId],
          });
        }
        else
        {
          const { data } = await WifiService.add({
            ssid: ssid,
            pass: pass,
            shops: [],
            all: true
          });
        }

        setSSID("");
        setPASS("");
        
        await onRefreshData();
      }
    } catch (err) {
      if (err.response.status === 409) toast.error("Taka sieć już istnieje");
      if (err.response.status === 400) toast.error("Niepoprawne dane.");
    }
  };

  const handleEditSSID = ({ currentTarget: input }) => {
    if (input.value.length < 50) setSSID(input.value);
  };

  const handleEditPASS = ({ currentTarget: input }) => {
    if (input.value.length < 50) setPASS(input.value);
  };

  const handleVisibility = () => {
    if (visibility === false)
      setTimeout(() => {
        setVisibility(false);
      }, 2000);

    setVisibility(!visibility);
  };

  const ifHorizontal = (val) => {
    if (horizontal) return "12";
    return val;
  };

  return (
    <div className=" ">
      <Row className="flex-container-wrap flex-container__center w-100 m-0">
        <Col md={ifHorizontal("5")} xs={ifHorizontal("12")} className="p-0">
          <Row>
            <Col xs={ifHorizontal("2")} className="text-center">
              <label
                htmlFor="ssid"
                className="me-0 fw-bold"
                style={{ fontSize: "0.9em" }}
              >
                SSID:
              </label>
            </Col>
            <Col xs={ifHorizontal("")}>
              <input
                value={ssid}
                style={{ fontSize: "0.9em", width: "100%" }}
                name="ssid"
                type="text"
                onChange={handleEditSSID}
              />
            </Col>
          </Row>
        </Col>
        <Col
          md={ifHorizontal("6")}
          xs={ifHorizontal("12")}
          className="p-0 m-0"
          style={{ display: "inline-block" }}
        >
          <Row>
            <Col xs={ifHorizontal("2")} className="text-center">
              <label
                htmlFor="ssid"
                className="fw-bold ms-2 "
                style={{ fontSize: "0.9em" }}
              >
                Hasło:
              </label>
            </Col>
            <Col>
              <input
                style={{ fontSize: "0.9em", width: "100%" }}
                value={pass}
                name="password"
                type={visibility ? "text" : "password"}
                onChange={handleEditPASS}
              />
              <FontAwesomeIcon
                onClick={() => handleVisibility()}
                style={{ marginLeft: "-25px", cursor: "pointer" }}
                icon={visibility ? faEyeSlash : faEye}
                size="xs"
              />
            </Col>
          </Row>
        </Col>

        <Col xs="12" md={ifHorizontal("1")} className="text-center">
          {horizontal && (
            <ConfirmationButton
              label={dictionary.wifiSettings.addButton_confirmLabel}
              yesLabel={dictionary.wifiSettings.addButton_confirmYesLabel}
              noLabel={dictionary.wifiSettings.addButton_confirmNoLabel}
              onClick={handleSaveChanges}
              className="mt-1 m-0 ps-0 w-100"
              icon={faPlusSquare}
              size="sm"
            >
              Dodaj
            </ConfirmationButton>
          )}

          {!horizontal && (
            <ConfirmationIcon
              label={dictionary.wifiSettings.addButton_confirmLabel}
              yesLabel={dictionary.wifiSettings.addButton_confirmYesLabel}
              noLabel={dictionary.wifiSettings.addButton_confirmNoLabel}
              onClick={handleSaveChanges}
              className="tag__add ms-0"
              icon={faPlusSquare}
              size="lg"
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
