import config from "../config.json";

export function prepareTableForList() {
  const temp = config.devTypes;
  let result = [];
  let i = 0;

  result.push({ _id: "", name: "Wszystkie", type: "" });
  temp.map((type) => {
    i++;
    return result.push({ _id: i, name: type.name, type: type.type });
  });

  return result;
}


export function getName(devType){
  const temp = config.devTypes;

  const result = temp.find((item) => item.type === devType)
  
  return result.name;

}
