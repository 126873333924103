import React from "react";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

const DistanceShowControl = ({
  header,
  label_L,
  label_R,
  now_L,
  now_R,
  status_L,
  status_R,
  max,
  active,
}) => {
  const setVariant = (now,status) => {
    // console.log(now);
    if (status !== 2) {
      if (status === 1) {
        return "success";
      }

      return "danger";
    }
    else
    {
      return "secondary";
    }
  };

  return (
    <React.Fragment>
      <Col
        sm="2"
        className="mx-auto my-auto"
        style={{ textAlign: "center", fontsize: "8", fontWeight: "bold" }}
      >
        {header}
      </Col>
      <Col sm="5" className="mx-auto my-auto">
        <ProgressBar
          now={now_L}
          max={max}
          label={label_L}
          variant={setVariant(now_L,status_L)}
          style={{ height: "30px" }}
        />
      </Col>
      <Col sm="5" className="mx-auto my-auto">
        <ProgressBar
          now={now_R}
          max={max}
          label={label_R}
          variant={setVariant(now_R,status_R)}
          style={{ height: "30px" }}
        />
      </Col>
    </React.Fragment>
  );
};

export default DistanceShowControl;
