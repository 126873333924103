import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

export default function ManyGenerateIsActiveBlock(props) {
  const isDiffStyle = (enable, style) => {
    if (enable) return style + " bg-success";
    else return style;
  };

  return (
    <Row xs="12" className={isDiffStyle(props.enable, "w-100 mx-0 px-0")}>
      <Col xs="2" className="d-flex justify-content-center">
        <Form.Check
          type="switch"
          id="dt_cb"
          className="align-self-center"
          checked={props.enable}
          onChange={(e) => props.onEnableChange(e.target.checked, props.name, props.type)}
        />
      </Col>
      <Col xs="10">
        <div className="row justify-content-center" style={{ width: "90%" }}>
          <div className="col-12 w-auto">
            <Table borderless={true} responsive="xs" className="my-0">
              <tbody>
                <tr className="align-middle">
                  <th
                    style={{
                      textAlign: "right",
                      minWidth: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <p className="m-2">{props.label}</p>
                  </th>

                  <td
                    style={{
                      textAlign: "left",
                      // minWidth: "200px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Form.Check
                      type="switch"
                      // id={id}
                      className=""
                      checked={props.value}
                      onChange={(e) => props.onValueChange(e.target.checked, props.name, props.type)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  );
}
