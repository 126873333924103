import React, {Component} from "react";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import {toast} from "react-toastify";

import config from "../config.json";

import * as AuthDeviceService from "../services/authDeviceService";
import * as UpdateService from "../services/authDeviceUpdateService";
import ShopService from "../services/shopService";
import * as FwService from "../services/fwService";

import Pagination from "./common/pagination";
import {prepareTableForList} from "../utils/devType";
import {paginate} from "../utils/paginate";
import {search} from "../utils/search";

import UListGroup from "./common/uListGroup";
import SimpleSelect from "./common/simpleSelect";
import Sync from "./common/sync";
import AuthDevicesUpdateTable from "./authDevicesUpdateTable";
import {isAdmin} from "../services/authService";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import {FaCircleInfo} from "react-icons/fa6";
import {OverlayTrigger} from "react-bootstrap";
import SearchBoxInfo from "./common/tooltip/searchBoxInfo";

class AuthDevicesUpdate extends Component {
    state = {
        fwFiles: [],
        selectedFw: "",
        authDevices: [],
        devTypes: [],
        shops: [],
        pageSize: 100,
        currentPage: 1,
        selectedDevType: null,
        sortColumn: {path: "title", order: "asc"},
        searchQuery: "",
        selectedDevice: null,
        settingsMode: true,
        selectedCount: 0,
        selectedFwForFiltering: "",
        fwFilesForFilter: [],
        selectedHwForFiltering: "",
    };

    async componentDidMount() {
        await this.updateDataFromServer();
    }

    ifAnyTypeOfDeviceSelected = () => {
        const {selectedDevType} = this.state;
        if (selectedDevType && selectedDevType.type !== "" && selectedDevType.type !== "MA") {
            return true;
        }
        return false;
    };

    filterFwFilesForFiltering = () => {
        let {fwFilesForFilter, selectedDevType} = this.state;

        if (!fwFilesForFilter) return [];
        const filtered = fwFilesForFilter.filter((fw) => fw.type === selectedDevType.type);
        if (!filtered) return [];
        return filtered;
    };

    filterHWFilesForFiltering = () => {
        let {authDevices, selectedDevType} = this.state;

        const hwFiltered = [];
        if (authDevices) {

            authDevices.forEach((device, id) => {
                if (device.dev_type !== selectedDevType.type) return;
                if (device.hw_version === null) return;
                hwFiltered.findIndex((hw) => hw._id === device.hw_version) === -1 && hwFiltered.push({
                    _id: device.hw_version,
                    name: device.hw_version
                });
                return;
            });
        }

        return hwFiltered;
    };

    updateFwFilesForFiltering = (devices) => {
        let fwFilesForFilter = [];
        let counter = 0;
        devices.forEach((device) => {
            const exist = fwFilesForFilter.find((fw) => {
                if (fw.name === device.fw_version) {
                    if (fw.type === device.dev_type) {
                        return true;
                    }
                }
                return false;
            });
            if (!exist) {
                fwFilesForFilter.push({_id: counter, name: device.fw_version, type: device.dev_type});
                counter++;
            }
        });

        return fwFilesForFilter;
    };

    updateDataFromServer = async () => {
        try {
            const {data: devices} = await AuthDeviceService.getAll();

            const {data: shops} = await ShopService.getAll();

            let {data: fwFiles} = await FwService.getAll();

            fwFiles = fwFiles.map((fwFile) => {
                return {
                    ...fwFile,
                    extendedName: (fwFile.version + ",   HW: " + (fwFile.hw_version ? fwFile.hw_version : ""))
                };
            });

            let fwFilesForFilter = [];
            let counter = 0;
            devices.forEach((device) => {
                const exist = fwFilesForFilter.find((fw) => fw.name === device.fw_version);
                if (!exist) {
                    fwFilesForFilter.push({_id: counter, name: device.fw_version});
                    counter++;
                }
            });

            const devTypes = prepareTableForList();
            this.setState({
                authDevices: this.addShopInfoAndCheckMark(devices, shops),
                fwFilesForFilter: this.updateFwFilesForFiltering(devices),
                fwFiles,
                devTypes,
                selectedDevType: devTypes?.[0],
                shops,
                selectedDevice: null,
            });
        } catch (err) {
            toast.error("Błąd serwera!!!");
            this.props.history.push("/not-found");
        }
    };

    addShopInfoAndCheckMark = (devices, shops) => {
        let authDevices = [];
        if (devices.length !== 0) {
            authDevices = devices.map((device) => {
                device.checked = false;
                if (shops.length !== 0) {
                    const shop = shops.filter((shop) => shop._id === device.shop_id);

                    if (shop.length !== 0)
                        return {shop_city: shop[0].city, shop_name: shop[0].name, ...device};
                    return device;
                }
                return device;
            });
        }
        return authDevices;
    };

    handleCheckWithFw = () => {
        const {selectedFwForFiltering: fw, fwFilesForFilter, authDevices} = this.state;

        const file = fwFilesForFilter.find((file) => (file._id === fw));

        if (file) {
            authDevices.forEach((device) => {

                if (device.dev_type === file.type) {
                    if (device.fw_version === file.name) {
                        device.checked = true;
                    }
                }
            });
            this.setState({authDevices});
        }
    };

    handleUncheckWithFw = () => {
        const {selectedFwForFiltering: fw, fwFilesForFilter, authDevices} = this.state;

        const file = fwFilesForFilter.find((file) => (file._id === fw));

        if (file) {
            authDevices.forEach((device) => {

                if (device.dev_type === file.type) {
                    if (device.fw_version === file.name) {
                        device.checked = false;
                    }
                }
            });
            this.setState({authDevices});
        }
    };

    handleCheckWithHw = () => {
        const {selectedHwForFiltering: hw, authDevices,selectedDevType} = this.state;

        authDevices.forEach((device) => {

            if (device.hw_version === hw && device.dev_type === selectedDevType.type) {

                device.checked = true;

            }
        });
        this.setState({authDevices});

    };

    handleUncheckWithHw = () => {
        const {selectedHwForFiltering: hw, authDevices} = this.state;

            authDevices.forEach((device) => {

                if (device.hw_version === hw) {

                    device.checked = false;

                }
            });
            this.setState({authDevices});

    };

    handleCheckAll = () => {
        let {authDevices, selectedDevType} = this.state;

        if (this.ifAnyTypeOfDeviceSelected()) {
            let selectedCount = 0;

            authDevices.forEach((device) => {
                if (device.dev_type === selectedDevType.type) {
                    device.checked = true;
                    selectedCount++;
                }
            });

            this.setState({authDevices, selectedCount});
        }
    };

    handleUncheckAll = () => {
        let {authDevices, selectedDevType} = this.state;

        if (this.ifAnyTypeOfDeviceSelected()) {
            let selectedCount = 0;
            authDevices.forEach((device) => {
                if (device.dev_type === selectedDevType.type) {
                    device.checked = false;
                }
            });

            authDevices.forEach((device) => {
                if (device.checked) selectedCount++;
            });

            this.setState({authDevices, selectedCount});
        }
    };

    handleCheck = async (event, deviceClicked) => {
        if (event) event.stopPropagation();

        let {authDevices} = this.state;

        if (this.ifAnyTypeOfDeviceSelected()) {
            let index = authDevices.findIndex((device) => device._id === deviceClicked._id);

            if (index !== -1) {
                authDevices[index].checked = event.target?.checked;
            }

            let selectedCount = 0;

            authDevices.forEach((device) => {
                if (device.checked) selectedCount++;
            });

            this.setState({authDevices, selectedCount});
        }
    };

    countSelectedDevices = () => {
        const {authDevices} = this.state;
        let counter = 0;

        authDevices.forEach((device) => {
            if (device.checked) counter++;
        });

        return counter;
    };

    ifAnyTypeOfDeviceSelected = () => {
        const {selectedDevType, authDevices} = this.state;
        if (selectedDevType && selectedDevType.type !== "") {
            return true;
        }
        return false;
    };

    handleEdit = async (event, authDevice) => {
        if (event) event.stopPropagation();

        return this.props.history.replace("/shopView/" + authDevice.shop_id);
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleDevTypeSelect = (devType) => {
        const {authDevices} = this.state;

        //Czyszczenie zaznaczenia
        authDevices.forEach((device) => {
            device.checked = false;
        });

        if (isAnyDevTypeSelected(devType) === false) {
            this.setState({settingsMode: false});
        }

        this.setState({
            authDevices,
            selectedDevType: devType,
            searchQuery: "",
            currentPage: 1,
            selectedCount: 0,
            selectedFw: ""
        });
    };

    handleSort = (sortColumn) => {
        if (sortColumn.path === "") return;

        this.setState({sortColumn});
    };

    getFwFilesForSelectedDevType = () => {
        const {selectedDevType, fwFiles: allFwFiles} = this.state;

        if (this.ifAnyTypeOfDeviceSelected()) {
            let fwFiles = search(allFwFiles, ["dev_type"], selectedDevType.type, true);

            return fwFiles;
        }

        return null;
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            authDevices: allAuthDevices,
            selectedDevType,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allAuthDevices;

        if (selectedDevType && selectedDevType._id) {
            filtered = search(allAuthDevices, ["dev_type"], selectedDevType.type, true);
        }

        if (searchQuery) {
            filtered = search(filtered, ["sn", "fw_version", "hw_version"], searchQuery);
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const authDevices = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: authDevices};
    };

    handleSearch = (query) => {
        this.setState({
            searchQuery: query,
            currentPage: 1,
        });
    };

    handleSelectedFwChange = (selectedFw) => {
        this.setState({selectedFw});
    };

    handleSelectedFwForFilteringChange = (selectedFwForFiltering) => {
        this.setState({selectedFwForFiltering: Number(selectedFwForFiltering)});
    };

    handleSelectedHwForFilteringChange = (selectedHwForFiltering) => {
        this.setState({selectedHwForFiltering: selectedHwForFiltering});
    };

    handleUpdateButton = async () => {
        const {authDevices, selectedDevType, selectedFw, shops} = this.state;

        let devicesToUpgradeIds = [];
        authDevices.forEach((device) => {
            if (device.checked) {
                if (device.dev_type === selectedDevType.type) {
                    devicesToUpgradeIds.push(device._id);
                }
            }
        });

        const data = {devicesIds: devicesToUpgradeIds, fwId: selectedFw};

        try {
            const {data: result} = await UpdateService.postUpdate(data);

            this.setState({
                authDevices: this.addShopInfoAndCheckMark(result, shops),
                selectedCount: 0,
                fwFilesForFilter: this.updateFwFilesForFiltering(result),
            });
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    handleCancelUpdateButton = async () => {
        const {authDevices, shops} = this.state;

        let devicesToCancelUpgradeIds = [];
        authDevices.forEach((device) => {
            if (device.checked) {
                if (device.update_fw !== null) {
                    devicesToCancelUpgradeIds.push(device._id);
                }
            }
        });

        const data = {devicesIds: devicesToCancelUpgradeIds};

        try {
            const {data: result} = await UpdateService.postCancel(data);

            this.setState({
                authDevices: this.addShopInfoAndCheckMark(result, shops),
                selectedCount: 0,
                fwFilesForFilter: this.updateFwFilesForFiltering(result),
            });
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    render() {
        const count = this.state.authDevices.length;
        const {
            fwFiles: allFwFiles,
            selectedFw,
            pageSize,
            currentPage,
            devTypes,
            selectedDevType,
            sortColumn,
            searchQuery,
            shops,
            settingsMode,
            selectedCount,
        } = this.state;

        const {totalCount, data: authDevices} = this.getPagedData();

        const fwFiles = this.getFwFilesForSelectedDevType();

        const isCheckingPossible = this.ifAnyTypeOfDeviceSelected();
        const howManyDeviceIsSelected = this.countSelectedDevices();

        return (
            <React.Fragment>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item
                        style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                        href="/"
                    >
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                        active
                    >
                        Konfiguracja
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                        active
                    >
                        Zarządzanie aktualizacją firmwaru
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Container fluid>
                    <Row className="mb-2">
                        <Col
                            xs="2"
                            className=" d-flex align-items-center"
                            style={{
                                textAlign: "right",
                                fontSize: "20px",
                            }}
                        >
                            <span style={{width: "100%"}}>Firmware: </span>
                        </Col>

                        {fwFiles ? (
                            <Col xs="9" className="d-flex justify-content-center" style={{textAlign: "left"}}>
                                {" "}
                                <SimpleSelect
                                    value={selectedFw}
                                    name="fw"
                                    optionKey="_id"
                                    optionName="extendedName"
                                    options={fwFiles}
                                    onChange={(e) => this.handleSelectedFwChange(e.target.value)}
                                    style={{width: "100%", fontSize: "20px"}}
                                />
                            </Col>
                        ) : (
                            <Col
                                xs="9"
                                className=" d-flex align-items-center justify-content-center text-danger"
                                style={{
                                    textAlign: "left",
                                    fontSize: "20px",
                                }}
                            >
                                <span style={{width: "100%"}}>Wybierz typ urządzenia z listy</span>
                            </Col>
                        )}
                        <Col xs="1" style={{textAlign: "left"}}>
                            <Sync onClick={() => this.updateDataFromServer()} size="1x"/>
                        </Col>
                    </Row>
                    {isCheckingPossible && (
                        <Row>
                            <Col
                                xs="2"
                                className=" d-flex align-items-center justify-content-center"
                                style={{
                                    textAlign: "right",
                                    fontSize: "20px",
                                    fontWeight: "semi-bold",
                                }}
                            >
                                <span style={{width: "100%"}}>Zaznaczonych: </span>
                            </Col>

                            <Col
                                xs="1"
                                className=" d-flex align-items-center justify-content-center"
                                style={{
                                    textAlign: "left",
                                    fontSize: "20px",
                                    fontWeight: "semi-bold",
                                }}
                            >
                                <span style={{width: "100%"}}>{howManyDeviceIsSelected}</span>
                            </Col>
                            <Col
                                xs="8"
                                className=" d-flex align-items-center justify-content-center"
                                style={{
                                    textAlign: "left",
                                    fontSize: "20px",
                                    fontWeight: "semi-bold",
                                }}
                            >
                                <Button
                                    variant="primary"
                                    size="sm"
                                    style={{width: "50%"}}
                                    onClick={this.handleUpdateButton}
                                    disabled={howManyDeviceIsSelected && selectedFw ? false : true}
                                >
                                    Zaktualizuj
                                </Button>

                                <Button
                                    variant="warning"
                                    className="my-0"
                                    size="sm"
                                    style={{width: "50%"}}
                                    onClick={this.handleCancelUpdateButton}
                                    disabled={!howManyDeviceIsSelected}
                                >
                                    Anuluj oczekujące aktualizację
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs="12" className={"pt-1"}>
                            <div className={"d-flex flex-row align-items-center justify-content-center"}>
                                <div className={"w-50"}><SearchBox value={searchQuery}
                                                                   onChange={this.handleSearch}/></div>
                                <div>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 10, hide: 0}}
                                        overlay={(props) => SearchBoxInfo(props)}
                                    >
                                        <div><FaCircleInfo size={"1.5rem"} style={{textAlign: "center"}}
                                                           className={"mx-1"}/></div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg="2">
                            <UListGroup
                                items={devTypes}
                                onItemSelect={this.handleDevTypeSelect}
                                selectedItem={selectedDevType}
                                horizontal={false}
                            />
                            {fwFiles && (
                                <React.Fragment>
                                    <Accordion
                                        defaultActiveKey=""
                                        activeKey={settingsMode ? "0" : "1"}
                                        className="mt-lg-5 mt-md-2"
                                    >
                                        <Accordion.Item eventKey="0" className="border-primary border-">
                                            <Accordion.Header
                                                onClick={(event) => {
                                                    this.setState({settingsMode: !settingsMode});
                                                }}
                                            >
                                                <div className="w-100 text-center">
                                                    <span className="fw-bold">Zaznaczanie</span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="fw-bold w-100"
                                                    onClick={() => this.handleCheckAll()}
                                                >
                                                    Zaznacz wszystkie
                                                </Button>
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={() => this.handleUncheckAll()}
                                                >
                                                    Odznacz wszystkie
                                                </Button>
                                                <hr></hr>
                                                <div
                                                    className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                    <label className="align-self-center fw-bold">FW:</label>
                                                    <SimpleSelect
                                                        value={this.state.selectedFwForFiltering}
                                                        className="m-2 w-100"
                                                        optionName="name"
                                                        optionKey="_id"
                                                        options={this.filterFwFilesForFiltering()}
                                                        onChange={(e) =>
                                                            this.handleSelectedFwForFilteringChange(e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={() => this.handleCheckWithFw()}
                                                >
                                                    Zaznacz po FW
                                                </Button>
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={() => this.handleUncheckWithFw()}
                                                >
                                                    Odznacz po FW
                                                </Button>
                                                <hr></hr>
                                                <div
                                                    className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                    <label className="align-self-center fw-bold">HW:</label>
                                                    <SimpleSelect
                                                        value={this.state.selectedHwForFiltering}
                                                        className="m-2 w-100"
                                                        optionName="name"
                                                        optionKey="_id"
                                                        options={this.filterHWFilesForFiltering(authDevices, selectedDevType)}
                                                        onChange={(e) =>
                                                            this.handleSelectedHwForFilteringChange(e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={() => this.handleCheckWithHw()}
                                                >
                                                    Zaznacz po HW
                                                </Button>
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={() => this.handleUncheckWithHw()}
                                                >
                                                    Odznacz po HW
                                                </Button>
                                                {/* <p className="text-center mb-1">
                          Zaznaczonych: <span className="fw-bold">{selectedCount}</span>
                        </p> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </React.Fragment>
                            )}
                        </Col>
                        <Col lg="10">
                            <Row>
                                <Col xs="12" lg="12">
                                    <Pagination
                                        itemsCount={totalCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        onPageChange={this.handlePageChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AuthDevicesUpdateTable
                                        authDevices={authDevices}
                                        shops={shops}
                                        fwFiles={allFwFiles}
                                        sortColumn={sortColumn}
                                        onAdd={this.handleAdd}
                                        onSort={this.handleSort}
                                        onCheckClick={this.handleCheck}
                                        checkedVisible={isAnyDevTypeSelected(selectedDevType)}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mb-1"}>
                                <Col xs="12" lg="12">
                                    <Pagination
                                        itemsCount={totalCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        onPageChange={this.handlePageChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

function isAnyDevTypeSelected(devtype) {
    if (devtype?.type) {
        return true;
    } else {
        return false;
    }
}

export default AuthDevicesUpdate;
