import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { toast } from "react-toastify";

import UForm from "./common/uform";
import Shop from "../services/shopService";
import { isAdmin } from "../services/authService";
import { schema as shopSchema } from "../model/shop";
import { Redirect } from "react-router";


import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

const containerStyle = {
  width: "95%",
  height: "300px",
  margin: "auto",
};

class ShopForm extends UForm {
  state = {
    data: {
      name: "",
      city: "",
      adress: "",
      postCode: "",
      pnOpen: "",
      pnClose: "",
      wtOpen: "",
      wtClose: "",
      srOpen: "",
      srClose: "",
      czwOpen: "",
      czwClose: "",
      ptOpen: "",
      ptClose: "",
      sbOpen: "",
      sbClose: "",
      ndOpen: "",
      ndClose: "",
    },
    errors: {},
  };

  schema = shopSchema;

  componentDidMount = async () => {
    const _id = this.props.match.params.id;

    if (!_id) return;

    if (_id === "new") return;

    try {
      const { data: shop } = await Shop.get(_id);
      // console.log(shop);
      if (!shop) {
        return this.props.history.replace("/not-found");
      }

      this.setState({
        data: this.mapToViewModel(shop),
      });
    } catch (err) {
      // if (err.response) {
      //   if (err.response.status === 404) {

      //   }
      // }
      return this.props.history.replace("/shops");
    }
  };

  mapToViewModel(shop) {
    return {
      name: shop.name,
      city: shop.city,
      adress: shop.adress,
      postCode:  shop.postCode ? shop.postCode : "",
      pnOpen: shop.openHourConf ? shop.openHourConf.pn[0] : "00:00",
      pnClose: shop.openHourConf ? shop.openHourConf.pn[1] : "00:00",
      wtOpen: shop.openHourConf ? shop.openHourConf.wt[0] : "00:00",
      wtClose: shop.openHourConf ? shop.openHourConf.wt[1] : "00:00",
      srOpen: shop.openHourConf ? shop.openHourConf.sr[0] : "00:00",
      srClose: shop.openHourConf ? shop.openHourConf.sr[1] : "00:00",
      czwOpen: shop.openHourConf ? shop.openHourConf.czw[0] : "00:00",
      czwClose: shop.openHourConf ? shop.openHourConf.czw[1] : "00:00",
      ptOpen: shop.openHourConf ? shop.openHourConf.pt[0] : "00:00",
      ptClose: shop.openHourConf ? shop.openHourConf.pt[1] : "00:00",
      sbOpen: shop.openHourConf ? shop.openHourConf.sb[0] : "00:00",
      sbClose: shop.openHourConf ? shop.openHourConf.sb[1] : "00:00",
      ndOpen: shop.openHourConf ? shop.openHourConf.nd[0] : "00:00",
      ndClose: shop.openHourConf ? shop.openHourConf.nd[1] : "00:00",
    };
  }

  prepareToSend() {
    const shop = this.state.data;
    return {
      name: shop.name,
      city: shop.city,
      adress: shop.adress,
      postCode: shop.postCode,
      openHourConf: {
        pn: [shop.pnOpen, shop.pnClose],
        wt: [shop.wtOpen, shop.wtClose],
        sr: [shop.srOpen, shop.srClose],
        czw: [shop.czwOpen, shop.czwClose],
        pt: [shop.ptOpen, shop.ptClose],
        sb: [shop.sbOpen, shop.sbClose],
        nd: [shop.ndOpen, shop.ndClose],
      },
    };
  }

  goBack = () => {
    return this.props.history.goBack();
  }
  doSubmit = async () => {
    const _id = this.props.match.params.id;

    try {
      const { data } = this.state;

      const toSend = this.prepareToSend();

      if (!_id || _id === "new") {
        const { data: newShop } = await Shop.add(toSend);

        if (newShop) {
          toast.success("Nowy sklep został dodany!");
        }

        return this.props.history.replace("/shops");
      }

      const { data: updatedShop } = await Shop.update(_id, toSend);
      if (updatedShop) {
        toast.success("Dane zostały zmienione!");
      }

      return this.props.history.goBack();
    } catch (ex) {
      // console.log(ex.response.data);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        toast.error(ex.response.data);
        this.setState({ errors });
      }
    }
  };

  handleDelete = async (shop) => {
    const _id = this.props.match.params.id;

    try {
      const { data: removedShop } = await Shop.remove(_id);
      if (removedShop) {
        toast.success("Sklep został usunięty z bazy!");
        return this.props.history.replace("/shops/");
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
      }
    }
  };

  onLoad = (ref) => (this.searchBox = ref);

  render() {
    const { name, lat, lng } = this.state.data;

    // if (!isAdmin()) return <Redirect to="/shops" />;
  
    const dayHeaderStyle = {
      textAlign: "center",
      fontWeight: "bold",
      verticalAlign: "auto",
    };

    return (
      <ConfirmationModalContextProvider>
        <React.Fragment>
          <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
            <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} href="/Shops">Sklepy</Breadcrumb.Item>
            <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} active>Edycja</Breadcrumb.Item>
          </Breadcrumb>
          <Container className="mt-3" fluid>
            <Row>
              <Col md="2" xs="2">
                {" "}
              </Col>
              <Col md="8" xs="8">
                <h3>Sklep {!isAdmin() ? (" "+this.state.data.name) : null}</h3>
                <Form onSubmit={this.handleSubmit}>
                  {/* {this.renderSelect("priviliges", "Uprawnienia", priviliges)} */}
                  {isAdmin() ?this.renderInput("name", "Nr. sklepu"):null}
                  {isAdmin() ?this.renderInput("city", "Miasto"):null}
                  {isAdmin() ?this.renderInput("adress", "Adres"):null}
                  {isAdmin() ?this.renderInput("postCode", "Kod pocztowy"):null}
                  <hr></hr>
                  <Row>
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Poniedziałek
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("pnOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "pnClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Wtorek
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("wtOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "wtClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mt-3">
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Środa
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("srOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "srClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mt-3">
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Czwartek
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("czwOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "czwClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mt-3">
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Piątek
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("ptOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "ptClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mt-3">
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Sobota
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("sbOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "sbClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row className="mt-3">
                    <Col md="2" className="my-auto">
                      <p style={dayHeaderStyle} className="mt-3">
                        Niedziela
                      </p>
                    </Col>
                    <Col md="5">
                      {this.renderInput("ndOpen", "Godziny otwarcia", "time")}
                    </Col>
                    <Col md="5">
                      {this.renderInput(
                        "ndClose",
                        "Godziny zamknięcia",
                        "time"
                      )}
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row className="mt-3 mb-3">

                    <Col style={{ textAlign: "right", width: "100%" }}>
                      {isAdmin() && this.props.match.params.id !== "new"
                        ? this.renderDeleteButtonWithConfirm("Usuń", this.handleDelete)
                        : null}
                    </Col>
                    <Col style={{ textAlign: "left", width: "100%" }}>
                      {this.renderButtonWithCancel("Anuluj zmiany")}
                    </Col>
                    <Col style={{ textAlign: "left", width: "100%" }}>
                      {this.renderButtonWithConfirm("Zapisz")}
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md="2" xs="2">
                {" "}
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      </ConfirmationModalContextProvider>
    );
  }
}

export default ShopForm;
