import React from "react";

import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";


const DistanceSettingsControl = ({
  label,
  id,
  value,
  activeMinRangeChange,
  onActiveMinRangeChange,
  onActiveRangeChange,
  onEnableChange,
  diffActiveMinRangeChange,
  diffActiveRangeChange,
  diffEnableChange,
  onBlockSensorChange,
  sensorDisabling,
}) => {
  const disabled = !value.enable ? true : false;
  return (
    <>
      <tr className="align-middle">
        <th
          style={{
            textAlign: "right",
            minWidth: "100px",
            background: "black",
            margin: "0",
            padding: "0",
          }}
          rowSpan={2}
        >
          <p className="m-2" style={{ textAlign: "center", color: "white" }}>
            {label}
          </p>
        </th>
        {activeMinRangeChange === true && (
          <th
            className={diffActiveMinRangeChange ? "bg-success" : null}
            style={{
              verticalAlign: "right",
              textAlign: "right",
              fontWeight: "bold",
              margin: "0",
              padding: "0",
            }}
          >
            <p className="m-2" style={{ textAlign: "right" }}>
              Minimalna odległość blokady:
            </p>
          </th>
        )}
        {activeMinRangeChange === true && (
          <th
            className={diffActiveMinRangeChange ? "bg-success" : null}
            style={{
              textAlign: "left",
              minWidth: "100px",
              margin: "0",
              padding: "0",
            }}
          >
            <input
              className="m-2"
              style={{ width: "60px" }}
              type="number"
              min="0"
              max="400"
              key="0"
              value={value.maxRange}
              onChange={({ currentTarget: input }) => onActiveMinRangeChange(input.value, value.id)}
              disabled={disabled}
            />
          </th>
        )}
        <th
          className={diffActiveRangeChange ? "bg-success" : null}
          style={{
            verticalAlign: "right",
            textAlign: "right",
            fontWeight: "bold",
            margin: "0",
            padding: "0",
          }}
        >
          <p className="m-2" style={{ textAlign: "right" }}>
            Odległość aktywna:
          </p>
        </th>

        <th
          className={diffActiveRangeChange ? "bg-success" : null}
          style={{
            verticalAlign: "left",
            textAlign: "left",
            margin: "0",
            padding: "0",
          }}
        >
          <input
            type="number"
            className="m-2"
            style={{ width: "60px" }}
            min="10"
            max="400"
            key="1"
            value={value.activeRange}
            onChange={({ currentTarget: input }) => onActiveRangeChange(input.value, value.id)}
            disabled={disabled}
          />
        </th>

        <th
          className={diffEnableChange ? "bg-success" : null}
          style={{
            verticalAlign: "center",
            textAlign: "center",
            margin: "0",
            padding: "0",
          }}
        >
          <Form.Check
            type="switch"
            id={id}
            className="m-2"
            checked={value.enable}
            onChange={(e) => onEnableChange(e.target.checked, value.id)}
          />
        </th>
      </tr>
      {sensorDisabling &&
        <tr>
          <th colSpan={5} style={{ textAlign: "center", margin: "0", padding: "0" }}>
            <Table borderless={true} responsive="xs" className="my-0">
            <tbody>
              <tr>
                <td  style={{ textAlign: "center", margin: "0", padding: "0" }}>
                  <p className="m-3" style={{verticalAlign: "center", fontWeight: "bold" }}>
                    Blokada czujnika:
                  </p>
                </td>
                <td style={{ textAlign: "center", margin: "0", padding: "0" }}>
                  <b>1</b>
                  <Form.Check
                    type="checkbox"
                    id={id + "s_1"}
                    key={id + "s_1"}
                    className="mx-3 my-0 py-0 my-0 py-0"
                    checked={value.blockSensor[0]}
                    onChange={(e) => onBlockSensorChange(0, e.target.checked, value.id)}
                    disabled={disabled}
                  />
                </td>
                <td style={{ textAlign: "center", margin: "0", padding: "0" }}>
                  <b>2</b>
                  <Form.Check
                    type="checkbox"
                    id={id + "s_2"}
                    key={id + "s_2"}
                    className="mx-3 my-0 py-0"
                    checked={value.blockSensor[1]}
                    onChange={(e) => onBlockSensorChange(1, e.target.checked, value.id)}
                    disabled={disabled}
                  />
                </td>
                <td style={{ textAlign: "center", margin: "0", padding: "0" }}>
                  <b>3</b>
                  <Form.Check
                    type="checkbox"
                    id={id + "s_3"}
                    key={id + "s_3"}
                    className="mx-3 my-0 py-0"
                    checked={value.blockSensor[2]}
                    onChange={(e) => onBlockSensorChange(2, e.target.checked, value.id)}
                    disabled={disabled}
                  />
                </td>
                <td style={{ textAlign: "center", margin: "0", padding: "0" }}>
                  <b>4</b>
                  <Form.Check
                    type="checkbox"
                    id={id + "s_4"}
                    key={id + "s_4"}
                    className="mx-3 my-0 py-0"
                    checked={value.blockSensor[3]}
                    onChange={(e) => onBlockSensorChange(3, e.target.checked, value.id)}
                    disabled={disabled}
                  />
                </td>
              </tr>
              </tbody>
            </Table>
          </th>
        </tr>
      }
    </>
  );
};

export default DistanceSettingsControl;
