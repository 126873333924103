import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import ConfirmationButton from "../confirmationButton";
import dictionary from "../../../PL.json";
import {isAdminOrIsService} from "../../../services/authService";

export default function CardDeviceButtonsPanel({onDelete, onSendConfiguration, onDeleteAllMusicFiles = null,onForceIMSPlaylistsUpdate = null}) {
    return (
        <Table responsive="xs" borderless style={{width: "90%", margin: "auto"}}>
            <tbody>
            <tr>
                {isAdminOrIsService() && (
                    <th style={{textAlign: "left"}}>
                        <ConfirmationButton
                            label={dictionary.shopView.cardDevice.buttonPanel.deleteButton_confirmLabel}
                            yesLabel={dictionary.shopView.cardDevice.buttonPanel.deleteButton_confirmYesLabel}
                            noLabel={dictionary.shopView.cardDevice.buttonPanel.deleteButton_confirmNoLabel}
                            variant="danger"
                            onClick={onDelete}
                        >
                            {dictionary.shopView.cardDevice.buttonPanel.deleteButton_label}
                        </ConfirmationButton>
                    </th>
                )}
                {onDeleteAllMusicFiles !== null && (
                    <th style={{textAlign: "center"}}>
                        <ConfirmationButton
                            label={dictionary.shopView.cardDevice.buttonPanel.deleteMusicButton_confirmLabel}
                            yesLabel={dictionary.shopView.cardDevice.buttonPanel.deleteMusicButton_confirmYesLabel}
                            noLabel={dictionary.shopView.cardDevice.buttonPanel.deleteMusicButton_confirmNoLabel}
                            variant="warning"
                            onClick={onDeleteAllMusicFiles}
                        >
                            {dictionary.shopView.cardDevice.buttonPanel.deleteMusicButton_label}
                        </ConfirmationButton>
                    </th>
                )}
                {
                    onForceIMSPlaylistsUpdate !== null && (
                        <th style={{textAlign: "center"}}>
                            <ConfirmationButton
                                label={dictionary.shopView.cardDevice.buttonPanel.forceIMSPlaylistsUpdateButton_confirmLabel}
                                yesLabel={dictionary.shopView.cardDevice.buttonPanel.forceIMSPlaylistsUpdateButton_confirmYesLabel}
                                noLabel={dictionary.shopView.cardDevice.buttonPanel.forceIMSPlaylistsUpdateButton_confirmNoLabel}
                                onClick={onForceIMSPlaylistsUpdate}
                            >
                                {dictionary.shopView.cardDevice.buttonPanel.forceIMSPlaylistsUpdateButton_label}
                            </ConfirmationButton>
                        </th>
                    )
                }
                <th style={{textAlign: "right"}}>
                    <ConfirmationButton
                        label={dictionary.shopView.cardDevice.buttonPanel.saveButton_confirmLabel}
                        yesLabel={dictionary.shopView.cardDevice.buttonPanel.saveButton_confirmYesLabel}
                        noLabel={dictionary.shopView.cardDevice.buttonPanel.saveButton_confirmNoLabel}
                        variant="primary"
                        onClick={onSendConfiguration}
                    >
                        {dictionary.shopView.cardDevice.buttonPanel.saveButton_label}
                    </ConfirmationButton>
                </th>
            </tr>
            </tbody>
        </Table>
    );
}
