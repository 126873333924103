import React from 'react';
import {Tooltip} from "react-bootstrap";

const SearchBoxInfo = (props) => {
    return (
        <Tooltip id="button-tooltip" {...props}>
            <p>
               W celu wyszukiwania po zakresie sklepów należy wpisać poszukiwany zakres zgodnie z konwencją: <br/>
                0100:0199
            </p>
        </Tooltip>
    );
};

export default SearchBoxInfo;