import React, {useState, useEffect, useContext} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import SimpleSelect from "../simpleSelect";
import BeepSettingsAudioModulesTableHeader from "./beepSettingsAudioModulesTableHeader";

import ShopContext from "../../../contexts/shopContext";
import {toHtml} from "@fortawesome/fontawesome-svg-core";

const BeepSettingsControl = ({
                                 device,
                                 value,
                                 id,
                                 label,
                                 onAudioDevChange,
                                 onFileChange,
                                 onSpkChange,
                                 onEnableChange,
                             }) => {
    const shopContext = useContext(ShopContext);


    return (
        <tr>
            <td
                style={{
                    textAlign: "right",
                    minWidth: "100px",
                    margin: "0",
                    padding: "0",
                    verticalAlign: "middle",
                }}
            >
                <p className="m-2" style={{verticalAlign: "auto", fontWeight: "bold"}}>
                    {label}
                </p>
            </td>

            <td
                style={{
                    textAlign: "center",
                    minWidth: "100px",
                    margin: "0",
                    padding: "0",
                    width: "20%",
                    verticalAlign: "middle",
                }}
            >
                <SimpleSelect
                    options={shopContext.files}
                    optionKey="_id"
                    optionName="name"
                    value={value.file}
                    onChange={(e) => onFileChange(e.target.value, id)}
                    id={device.sn + "select_file"}
                    key={device.sn + "file_beep" + id}
                />
            </td>
            <td
                style={{
                    textAlign: "center",
                    minWidth: "300px",
                    margin: "0",
                    padding: "0",
                    width: "60%",
                }}
            >
                <table style={{margin: "0", padding: "0", width: "100%"}}>
                    <BeepSettingsAudioModulesTableHeader/>

                    {shopContext.maDevices.map((dev) => {

                        const index = value.to.findIndex(x => x.sn === dev.sn)
                        let disabled = true;
                        if (index !== -1) disabled = false

                        let number = 4;
                        if(dev.hw_version?.split("_").length > 1)
                            number = Number(dev.hw_version?.split("_")[1])

                        // const versionWithSixSpk = dev.hw_version.endsWith("_6");


                        // const to = {sn: dev.sn, spk: [false,false,false,false],enable: false}
                        // if(index !== -1){
                        //   to.sn = value.to[index].sn;
                        //   to.spk = value.to[index].spk;
                        //   to.enable = value.to[index].enable;
                        // }

                        // disabled = !to.enable ? true : false;

                        return (
                            <tbody key={device.sn + "t" + dev.sn} id={device.sn + "t" + dev.sn}>
                            <tr style={{width: "100%"}}>
                                <td
                                    style={{
                                        textAlign: "center",
                                        minWidth: "100px",
                                        margin: "0",
                                        padding: "0",
                                    }}
                                >
                                    <p className="m-2" style={{verticalAlign: "auto"}}>
                                        {dev.sn}
                                    </p>
                                </td>
                                <td
                                    style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        id={device.sn + "cb_1" + dev.sn}
                                        key={device.sn + "cb_1" + dev.sn}
                                        className="m-0"
                                        checked={value.to[index] ? value.to[index].spk1 : false}
                                        onChange={(e) => onSpkChange(1, dev.sn, e.target.checked, id)}
                                        disabled={disabled}
                                    />
                                </td>
                                <td
                                    style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        id={device.sn + "cb_2" + dev.sn}
                                        key={device.sn + "cb_2" + dev.sn}
                                        className="m-0"
                                        checked={value.to[index] ? value.to[index].spk2 : false}
                                        onChange={(e) => onSpkChange(2, dev.sn, e.target.checked, id)}
                                        disabled={disabled}
                                    />
                                </td>
                                <td
                                    style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        id={device.sn + "cb_3" + dev.sn}
                                        key={device.sn + "cb_3" + dev.sn}
                                        className="m-0"
                                        checked={value.to[index] ? value.to[index].spk3 : false}
                                        onChange={(e) => onSpkChange(3, dev.sn, e.target.checked, id)}
                                        disabled={disabled}
                                    />
                                </td>
                                <td
                                    style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        id={device.sn + "cb_4" + dev.sn}
                                        key={device.sn + "cb_4" + dev.sn}
                                        className="m-0"
                                        checked={value.to[index] ? value.to[index].spk4 : false}
                                        onChange={(e) => onSpkChange(4, dev.sn, e.target.checked, id)}
                                        disabled={disabled}
                                    />
                                </td>
                                {
                                    [5,6,7,8,9,10,11,12].map((i) => {
                                        if(i <= number) {
                                            return (
                                                <td
                                                    key={device.sn + "spk_s" + i + dev.sn} style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={device.sn + "cb_" + i + dev.sn}
                                                        key={device.sn + "cb_" + i + dev.sn}
                                                        className="m-0"
                                                        checked={value.to[index] ? value.to[index]["spk" + i] : false}
                                                        onChange={(e) => onSpkChange(i, dev.sn, e.target.checked, id)}
                                                        disabled={disabled}
                                                    />
                                                </td>
                                            )
                                        }else{
                                            return (
                                                <td
                                                    id={device.sn + "spk_s" + i + dev.sn} style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={device.sn + "cb_" + i + dev.sn}
                                                        key={device.sn + "cb_" + i + dev.sn}
                                                        className="m-0"
                                                        style={{backgroundColor: "lightgrey"}}
                                                        disabled={true}
                                                    />
                                                </td>
                                            )
                                        }
                                    })
                                }

                                <td
                                    style={{textAlign: "center", margin: "0", padding: "0",width: "8%"}}
                                >
                                    <Form.Check
                                        type="switch"
                                        id={device.sn + "en" + dev.sn + "" + id}
                                        key={device.sn + "en" + dev.sn + "" + id}
                                        className="m-0"
                                        checked={value.to[index] ? value.to[index].enable : false}
                                        onChange={(e) => onEnableChange(dev.sn, e.target.checked, id)}
                                    />
                                </td>

                            </tr>
                            </tbody>
                        );
                    })}
                </table>
            </td>
        </tr>
    );
};

export default BeepSettingsControl;
