import http from "./httpService";

const apiEndpoint ="/tags";

export function add(data){
    return http.post(apiEndpoint,data);
}


export function update(_id,data){
    return http.put(apiEndpoint+"/"+_id,data);
}


export function getAll(){
    return http.get(apiEndpoint);
}


export function remove(_id){
    return http.delete(apiEndpoint+"/"+_id);
}

const TagService = {add,update,getAll,remove};

export default TagService;


