import React from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useConfirmationModalContext } from "./modalConfirmationContext";

const ConfirmationSwitch = (props) => {
  const { className, onChange, label, yesLabel, noLabel, body, ...rest } = props;
  const modalContext = useConfirmationModalContext();

  const handleOnChange = async (event) => {
    if (event) event.stopPropagation();
    const checked = event.target?.checked;
    modalContext.setLabel(label);
    modalContext.setYesLabel(yesLabel);
    modalContext.setNoLabel(noLabel);
    body ? modalContext.setBody(body) : modalContext.setBody("");
    const result = await modalContext.showConfirmation();
    result && onChange(checked);
  };

  return <Form.Check onChange={(event) => handleOnChange(event)} {...rest} type="switch" />;
};

export default ConfirmationSwitch;
