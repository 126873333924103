import React from "react";
import Joi from "joi";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UForm from "./common/uform";

import * as FwService from "../services/fwService";
import { toast } from "react-toastify";

class FwFileAddFormModal extends UForm {
  state = {
    data: {
      dev_type: 0,
      version: "",
      info: "",
    },
    file: null,
    errors: {},
  };

  schema = Joi.object({
    dev_type: Joi.number()
      .min(1)
      .max(10)
      .required()
      .optional()
      .label("Do urządzenia")
      .messages({
        "number.empty": `Typ urządzenia musi być wybrany`,
        "any.required": `Musi zostać podany tup urządzenia`,
      }),
    version: Joi.string()
      .min(1)
      .max(15)
      .required()
      .optional()
      .label("Wersja")
      .messages({
        "string.empty": `Numer wersji nie może być pusty`,
        "string.max": `"Nazwa może posiadać maksymalnie {#limit} znaków`,
        "any.required": `Numer wersji nie może być pusty`,
      }),
    hw_version: Joi.string()
        .pattern(new RegExp('^[0-9_]{4,8}$'))
        .required()
        .optional()
        .label("Wersja HW")
        .messages({
          "string.empty": `Numer wersji HW nie może być pusty`,
          "string.max": `"Numer wersji HW może posiadać maksymalnie {#limit} znaków`,
          "string.pattern.base": `Numer wersji HW może zawierać tylko cyfry i być długości od 4 do 8 znaków`,
          "any.required": `Numer wersji nie może być pusty`,
        }),
    info: Joi.string()
      .allow(null)
      .allow("")
      .max(100)
      .optional()
      .label("Opis")
      .messages({
        "string.max": `"Opis nie może być dłuższy niż {#limit} znaków`,
      }),
  });

  componentDidMount = async () => {
    const data = this.state.data;

    this.setState({ data });
  };

  handleShow = () => {
    const data = this.state.data;
    let file = this.state.file;

    data.dev_type = "";
    data.version = "x.x.x";
    data.hw_version = "";
    data.info = "";
    file = null;

    this.setState({ data, file });
  };

  doSubmit = async () => {
    console.log("Submit FW!!!");

    if (!this.state.file) return toast.error("Proszę załaczyć plik!!");

    const dev_type = this.props.devTypes.filter((devType) => {
      return devType._id === Number(this.state.data.dev_type);
    });

    if(!dev_type.length) return toast.error("Błąd!!!");

    const data = new FormData();
    data.append("file", this.state.file);
    data.append("dev_type", dev_type[0].type);
    data.append("version", this.state.data.version);
    data.append("hw_version", this.state.data.hw_version);
    data.append("info", this.state.data.info);

    try {
      const { data: newFwFile } = await FwService.add(data);

      toast.success("Plik firmwaru " + newFwFile.version + " został dodany!");

      this.props.onHide();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
      }
    }
  };

  render() {
    //const { searchQuery, filteredShops: shops } = this.state;

    const { show, onHide, devTypes } = this.props;

    return (
      <Modal
        show={show}
        onShow={this.handleShow}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Dodaj plik
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            {this.renderSelect("dev_type", "Typ urządzenia", devTypes)}

            {this.renderInput("version", "Wersja")}

            {this.renderInput("hw_version", "Wersja HW (umieszczona na urządzeniu)")}

            {this.renderInput("info", "Opis")}

            {this.renderFwFileInput("fwFile", "Plik Firmwaru", ".bin")}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} variant="primary">
              Anuluj
            </Button>
            {this.renderButton("Dodaj")}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default FwFileAddFormModal;
