// @flow
import * as React from 'react';
import {MdSignalWifiStatusbar1Bar} from "react-icons/md";


export function DistanceTriangleProgressBar({now, max, label, striped, variant}) {

    let percent = (now / max) * 100;
    percent = percent > 100 ? 100 : percent;

    let stripedOrNotTri = (striped === true) ? "triangleProgressBar_tri triangleProgressBar_tri_backgroundImage" : "triangleProgressBar_tri triangleProgressBar_tri_background";

    const setVariantColor = (now, status) => {
        // console.log(now);
        if (status !== 2) {
            if (status === 1) {
                return "#198754";
            }

            return "#dc3545";
        } else {
            return "gray";
        }
    };

    return (

        <div style={{display: "flex", justifyContent: "center", alignContent: "center", textAlign: "center"}}>
            <div className={stripedOrNotTri} style={{transform: "rotate(0deg)"}}>
                <div className="triangleProgressBar_amount" style={{width: `${percent}%`, background: variant}}></div>
            </div>
            <div style={{width: "5rem", height: "60px", justifyContent: "center", textAlign: "center", display: "flex"}}>
                <p className="fw-bold" style={{margin: "auto"}}>{label}</p>
            </div>
        </div>
    );
};