import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";

import ApiSettingsService from "../../services/apiSettingsService";
import ConfirmationIcon from "./confirmationIcon";
import ConfirmationSwitch from "./confirmationSwitch";

import dictionary from "../../PL.json";

// import * as AuthDeviceService from "../../../services/authDeviceService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faCircleInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default function ApiNameView({ api, enable, model, onRefreshData }) {
  const [value, setValue] = useState("");
  const [en, setEn] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const value = api === null ? "brak" : api;
    setValue(value);
  }, [api]);

  // useEffect(() => {
  //   const en = enable !== null ? enable : false;
  //   setEn(en);
  // }, [enable]);

  const handleSaveChanges = async () => {
    try {
      const { data } = await ApiSettingsService.updateValue({
        what: model.what,
        description: model.description,
        value: value,
      });
      setEdit(false);
      await onRefreshData();
      toast.success("Ustawienia zostały zmienione");
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await ApiSettingsService.remove({
        what: model.what,
      });
      setEdit(false);
      await onRefreshData();
      toast.success("Ustawienia zostały zmienione");
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const handleEdit = (newValue) => {
    if (newValue.length < 500) setValue(newValue);
  };

  const handleEnCheck = async (checked) => {
   

    // setEn(event.target?.checked);
console.log(checked)
    try {
      const { data } = await ApiSettingsService.updateEnable({
        what: model.what,
        enable: checked,
        description: model.description,
      });
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
    await onRefreshData();
  };

  const infoDiffrent = value !== api && value !== "brak";

  const handleActiveOrNot = () => {
    const style = {
      minWidth: "500px",
      borderRadius: "8px",
      backgroundColor: "rgba(170,170,170,0.5)",
    };

    if (enable) style.backgroundColor = "rgba(171, 209, 181,0.5)";

    return style;
  };

  return (
    <div className="py-3 my-3 " style={handleActiveOrNot()}>
      <Row className="flex-container flex-container__center w-100 m-2">
        <Col md="3" xs="12">
          <Row className="mx-0 px-0 my-2 fw-bold h-100">
            <Col className="flex-container flex-container__center ">
              {model.description ? model.description : "Opis "}{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip">
                    <div>{model.help}</div>
                  </Tooltip>
                }
              >
                <div className="px-1 my-0">
                  <FontAwesomeIcon
                    onClick={() => setShowInfo(!showInfo)}
                    className="mx-0 my-0 icon__info"
                    icon={faCircleInfo}
                  />
                </div>
              </OverlayTrigger>
              :
            </Col>
          </Row>
        </Col>

        {edit ? (
          <React.Fragment>
            <Col className="px-0" md="6" xs="9">
              <input
                style={{
                  width: "100%",
                  color: infoDiffrent ? "rgb(184,7,7)" : "black",
                }}
                max="500"
                value={value}
                onChange={(e) => handleEdit(e.currentTarget.value)}
              ></input>
            </Col>
          </React.Fragment>
        ) : (
          <Col
            className="px-0 fst-italic"
            style={{
              textAlign: "center",
              color: infoDiffrent ? "rgb(184,7,7)" : "black",
              fontWeight: infoDiffrent ? "bold" : "",
            }}
            md="6"
            xs="9"
          >
            {value}
          </Col>
        )}

        <Col xs="2" md="2">
          <FontAwesomeIcon
            onClick={() => setEdit(!edit)}
            className="tag__edit my-0"
            icon={faEdit}
          />
          {infoDiffrent ? (
            <ConfirmationIcon
              label={dictionary.apiSettings.editButton_confirmLabel}
              yesLabel={dictionary.apiSettings.editButton_confirmYesLabel}
              noLabel={dictionary.apiSettings.editButton_confirmNoLabel}
              onClick={handleSaveChanges}
              className="tag__save"
              icon={faSave}
            />
          ) : null}
          {edit && api !== undefined && api !== null ? (
            <ConfirmationIcon
              label={dictionary.apiSettings.deleteButton_confirmLabel}
              yesLabel={dictionary.apiSettings.deleteButton_confirmYesLabel}
              noLabel={dictionary.apiSettings.deleteButton_confirmNoLabel}
              onClick={handleDelete}
              className="tag__save"
              icon={faTrash}
            />
          ) : null}
        </Col>
        <Col className="px-0" md="1" xs="1">
          <ConfirmationSwitch
            label={enable ? dictionary.apiSettings.CheckSwitch_deactivateConfirmLabel :  dictionary.apiSettings.CheckSwitch_activateConfirmLabel}
            yesLabel={dictionary.apiSettings.CheckSwitch_confirmYesLabel}
            noLabel={dictionary.apiSettings.CheckSwitch_confirmNoLabel}
            key={model.what}
            onChange={handleEnCheck}
            type="switch"
            checked={enable}
          />
        </Col>
      </Row>
    </div>
  );
}
