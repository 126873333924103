import React, {Component} from "react";
import {Redirect} from "react-router";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import UListGroup from "./common/uListGroup";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {toast} from "react-toastify";

import Pagination from "./common/pagination";
import {prepareTableForList} from "../utils/devType";
import {search} from "../utils/search";
import {paginate} from "../utils/paginate";

import * as FwService from "../services/fwService";
import {isAdmin} from "../services/authService";

import FwAddFormModal from "./fwFileAddFormModal";

import FwFilesTable from "./fwFilesTable";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import config from "../config.json";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import {putUnsetCurrent} from "../services/fwService";

class FwFiles extends Component {
    state = {
        fwFiles: [],
        devTypes: [],
        devTypesList: [],
        pageSize: 100,
        currentPage: 1,
        selectedDevType: null,
        sortColumn: {path: "name", order: "asc"},
        searchQuery: "",
        selectedDevice: null,
        fwAddFormModal: false,
    };

    async componentDidMount() {
        try {
            const {data: fwFiles} = await FwService.getAll();
            this.setState({fwFiles});
        } catch (err) {
            toast.error("Błąd serwera!!!");
            this.props.history.push("/not-found");
        }

        const devTypesList = prepareTableForList();
        this.setState({
            selectedDevType: devTypesList[0],
            devTypes: prepareTableForList(),
            devTypesList,
            selectedDevice: null,
        });
    }

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleDevTypeSelect = (devType) => {
        this.setState({
            selectedDevType: devType,
            currentPage: 1,
        });
    };

    handleSort = (sortColumn) => {
        if (sortColumn.path === "") return;

        this.setState({sortColumn});
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            fwFiles: allFwFiles,
            selectedDevType,
            sortColumn,
            searchQuery,
        } = this.state;

        let filtered = allFwFiles;

        if (searchQuery) {
            if (selectedDevType && selectedDevType._id) {
                filtered = search(allFwFiles, ["dev_type"], selectedDevType.type, true);
            }
            filtered = search(filtered, ["version", "info"], searchQuery);
        } else if (selectedDevType && selectedDevType._id) {
            filtered = search(allFwFiles, ["dev_type"], selectedDevType.type, true);
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const fwFiles = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: fwFiles};
    };

    handleSearch = (query) => {
        this.setState({
            searchQuery: query,
            // selectedDevType: null,
            currentPage: 1,
        });
    };

    handleChangeCurrent = async (event, fw, setOrUnset) => {
        if (event) event.stopPropagation();

        const _id = fw._id;

        try {
            let result;

            if(setOrUnset === true) {
                result = await FwService.putSetCurrent(_id);
            }
            else if(setOrUnset === false){
                result = await FwService.putUnsetCurrent(_id);
            }

            this.setState({fwFiles: result.data});
        } catch (ex) {
            console.log(ex);
            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd!");
            } else {
                toast.error("Błąd po stronie serwera!");
            }
        }


    }

    handleDeleteFw = async (event, fw) => {
        if (event) event.stopPropagation();

        const _id = fw._id;

        try {
            const {data: removedFile} = await FwService.remove(_id);
            if (removedFile) {
                toast.success("Plik został usunięty!");
            }

            const {data: fwFiles} = await FwService.getAll();

            this.setState({fwFiles});
        } catch (ex) {
            console.log(ex);
            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd!");
            } else {
                toast.error("Błąd po stronie serwera!");
            }
        }
    };

    handleAddFwFile = () => {
        this.setState({fwFileAddFormModal: true});
    };

    handleFormHide = async () => {
        this.setState({fwFileAddFormModal: false});
        const {data: fwFiles} = await FwService.getAll();

        this.setState({fwFiles});
    };

    render() {
        const count = this.state.fwFiles.length;

        if (!isAdmin()) return <Redirect to="/shops"/>;
        const {
            devTypes,
            devTypesList,
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            selectedDevType,
            fwFileAddFormModal,
        } = this.state;

        const {totalCount, data: fwFiles} = this.getPagedData();

        return (
            <React.Fragment>
                <ConfirmationModalContextProvider>
                    <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                        <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                                         href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                                         active>Konfiguracja</Breadcrumb.Item>
                        <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} active>Zarządzanie
                            plikami Firmwaru</Breadcrumb.Item>
                    </Breadcrumb>
                    <Container>
                        {isAdmin() && (
                            <Row>
                                <Col ms="12" xs="12">
                                    <Button onClick={this.handleAddFwFile} variant="primary">
                                        Dodaj
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col ms="12" xs="12">
                                <SearchBox value={searchQuery} onChange={this.handleSearch}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg="2">
                                <UListGroup
                                    items={devTypesList}
                                    onItemSelect={this.handleDevTypeSelect}
                                    selectedItem={selectedDevType}
                                    horizontal={false}
                                />
                            </Col>

                            <Col lg="10">
                                <FwFilesTable
                                    fwFiles={fwFiles}
                                    sortColumn={sortColumn}
                                    onChangeCurrent={this.handleChangeCurrent}
                                    onDelete={this.handleDeleteFw}
                                    onAdd={this.handleAdd}
                                    onSort={this.handleSort}
                                    onRowClick={this.handleRowClick}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Pagination
                                    itemsCount={totalCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    onPageChange={this.handlePageChange}
                                />
                            </Col>
                        </Row>
                        {isAdmin() && (
                            <FwAddFormModal
                                show={fwFileAddFormModal}
                                devTypes={devTypes}
                                onHide={this.handleFormHide}
                            />
                        )}
                    </Container>
                </ConfirmationModalContextProvider>
            </React.Fragment>
        );
    }
}

export default FwFiles;
