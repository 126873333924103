import React, {Component} from "react";
import Button from "react-bootstrap/Button";

import {isAdmin} from "../services/authService";
import UTable from "./common/table";

class MusicInShopsTable extends Component {
    columns = [
        {
            path: "name",
            label: "Nr. sklepu",
        },
        {
            path: "city",
            label: "Miasto",
        },
        {
            path: "adress",
            label: "Adres",
        }
    ];

    editColumn = {
        path: "playlist",
        label: "Playlista",
        key: "Playlista",
        content: (item) => {
            return (
                <React.Fragment>
                    {item?.playlistsIds !== null &&
                    item?.playlistsIds !== undefined &&
                    item?.playlistsIds.length > 0 ?
                        item.playlistsIds.map((playlistId) => {
                            const playlist = this.props.playlists.find(playlist => playlist._id === playlistId);
                            if (playlist !== null && playlist !== undefined) {
                                return (playlist.name) + ", ";
                            } else {
                                return null;
                            }
                        }) : null
                    }
                </React.Fragment>)
        }
    }


    checkedColumn = {
        path: "checked",
        label: "",
        content: (item) => {
            if (!this.props.checkedVisible) return null;
            let checked = ((item.checked !== null) && (item.checked !== undefined)) ? item.checked : false;
            return (
                <input
                    onChange={(event) => {
                        event.stopPropagation();
                        this.props.onCheckClick(event, item);
                    }}
                    type="checkbox"
                    checked={checked}
                />
            );
        },
    };

    constructor() {
        super();
        // if(isAdmin()){
        this.columns.unshift(this.checkedColumn);

        this.columns.push(this.editColumn)
        // }
    }

    render() {
        const {shops, sortColumn, onSort, onRowClick} = this.props;

        return (
            <UTable
                columns={this.columns}
                onSort={onSort}
                sortColumn={sortColumn}
                data={shops}
                onRowClick={onRowClick}
                dataId="_id"
            />
        );
    }
}

export default MusicInShopsTable;
