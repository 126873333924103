import React from 'react';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {useConfirmationModalContext} from "./modalConfirmationContext";

const ConfirmationIcon = (props) => {
    const {className, onClick,label, yesLabel, noLabel, body, ...rest} = props;
    const modalContext = useConfirmationModalContext();

    const handleOnClick = async (e) => {
        e.stopPropagation();
        modalContext.setLabel(label);
        modalContext.setYesLabel(yesLabel);
        modalContext.setNoLabel(noLabel);
        (body) ? modalContext.setBody(body) :  modalContext.setBody("");
        const result = await modalContext.showConfirmation();
        result && onClick();
    };

    return (
         
        <FontAwesomeIcon className={className} onClick={handleOnClick}  {...rest}>
        </FontAwesomeIcon>
    )
};

export default ConfirmationIcon;