import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

export default function ManyGenerateBlockSensorBlockMK(props) {
    const isDiffStyle = (enable, style) => {
        if (enable) return style + " bg-success";
        else return style;
    };

    const labelNoErrorColor = () => {
        if (props.enable === false) return null;
        else return "#ffffff"
    };

    const labelColor = () => {
        if (props.error === false) {
            return labelNoErrorColor();
        } else return "#DB222A"
    }

    return (
        <Row xs="12" className={isDiffStyle(props.enable, "w-100 mx-0 px-0")}>
            <Col xs="2" className="d-flex justify-content-center">
                <Form.Check
                    type="switch"
                    id="dt_cb"
                    className="align-self-center"
                    checked={props.enable}
                    onChange={(e) => props.onEnableChange(e.target.checked, props.name, props.type)}
                />
            </Col>
            <Col xs="10">
                <div className="row justify-content-center" style={{width: "90%"}}>
                    <div className="col-12 w-auto">
                        <Table borderless={true} responsive="xs" className="my-0">
                            <tbody>
                            <tr className="align-middle">
                                <th
                                    style={{
                                        textAlign: "right",
                                        minWidth: "100px",
                                        margin: "0",
                                        padding: "0",
                                    }}
                                >
                                    <p className="m-2" style={{color: labelColor()}}>{props.label}</p>
                                </th>

                                <td
                                    style={{
                                        // minWidth: "200px",
                                        height: "inherit",
                                        margin: "auto",
                                        padding: "0",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        textAlign: "center",
                                        justifyContent: "middle"
                                    }} className="mx-3">
                                        <p className="m-2" style={{height: "100%", margin: "auto"}}>
                                            1:
                                        </p>

                                        <Form.Check
                                            type="checkbox"
                                            id={"s_0"+props.type}
                                            key={"s_0"+props.type}
                                            style={{ height: "100%", margin: "auto"}}
                                            checked={props.value[0]}
                                            onChange={(e) => props.onValueChange(e.target.checked,0, props.name, props.type)}
                                        />

                                    </div>

                                </td>

                                <td
                                    style={{
                                        // minWidth: "200px",
                                        height: "inherit",
                                        margin: "auto",
                                        padding: "0",
                                    }}
                                >
                                    <div style={{
                                        display: "flex", textAlign: "center",
                                        justifyContent: "middle"
                                    }} className="mx-3">
                                        <p className="m-2" style={{height: "100%", margin: "auto"}}>
                                            2:
                                        </p>

                                        <Form.Check
                                            type="checkbox"
                                            id={"s_1"+props.type}
                                            key={"s_1"+props.type}
                                            style={{ height: "100%", margin: "auto"}}
                                            checked={props.value[1]}
                                            onChange={(e) => props.onValueChange(e.target.checked,1, props.name, props.type)}
                                        />
                                    </div>
                                </td>

                                <td
                                    style={{
                                        // minWidth: "200px",
                                        height: "inherit",
                                        margin: "auto",
                                        padding: "0",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        textAlign: "center",
                                        justifyContent: "middle"
                                    }} className="mx-3">
                                        <p className="m-2" style={{height: "100%", margin: "auto"}}>
                                            3:
                                        </p>

                                        <Form.Check
                                            type="checkbox"
                                            id={"s_2"+props.type}
                                            key={"s_2"+props.type}
                                            style={{ height: "100%", margin: "auto"}}
                                            checked={props.value[2]}
                                            onChange={(e) => props.onValueChange(e.target.checked,2, props.name, props.type)}
                                        />
                                    </div>
                                </td>

                                <td
                                    style={{

                                        // minWidth: "200px",
                                        height: "inherit",
                                        margin: "auto",
                                        padding: "0",
                                    }}
                                >
                                    <div style={{
                                        display: "flex", textAlign: "center",
                                        justifyContent: "middle"
                                    }} className="mx-3">
                                        <p className="m-2" style={{height: "100%", margin: "auto"}}>
                                            4:
                                        </p>

                                        <Form.Check
                                            type="checkbox"
                                            id={"s_3"+props.type}
                                            key={"s_3"+props.type}
                                            style={{ height: "100%", margin: "auto"}}
                                            checked={props.value[3]}
                                            onChange={(e) => props.onValueChange(e.target.checked,3, props.name, props.type)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row my-0 mx-auto" style={{width: "90%"}}>
                    <hr className="my-0 m-auto"></hr>
                </div>
            </Col>
        </Row>
    );
}
