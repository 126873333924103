import React, {Component} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import XLSX from "xlsx";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";
import ConfirmationButton from "./common/confirmationButton";
import dictionary from "../PL.json";

import Shop from "../services/shopService";
import {isAdmin} from "../services/authService";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DragDropFile from "./common/dragDropFile";
import XLSXFileInput from "./common/xlsxFileInput";
import ShopOutTable from "./shopsImportTable";
import ProgressModal from "./common/progressModal";

class ShopsImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
            allSelected: false,
            file: null,
            showConfirmationModal: false,
            dataCountToModify: 0,
            dataCountModifyTillNow: 0,
        };
        // this.handleFile = this.handleFile.bind(this);
    }

    async componentDidMount() {
        if (!isAdmin()) {
            return this.props.history.replace("/not-found");
        }
    }

    getShopsData = async () => {
        try {
            const {data: shops} = await Shop.getAll();
            return shops;
        } catch (err) {
            toast.error("Błąd serwera!!!");
            return null;
        }
    };

    handleFile = (file /*:File*/) => {

        this.setState({
            showConfirmationModal: true,
            dataCountToModify: 0,
            dataCountModifyTillNow: 0,
        });
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = async (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: rABS ? "binary" : "array"});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            // console.log(rABS, wb);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            /* Update state */
            const cols = make_cols(ws["!ref"]);

            const shops = await this.getShopsData();

            const result = await make_shop_table(data, shops)
            this.setState({data: result, file});
            this.setState({
                showConfirmationModal: false,
                dataCountToModify: 0,
                dataCountModifyTillNow: 0,
            });
        };

        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    checkIfDataNeedToBeUpdate(data) {
        if (
            data.inDb === true &&
            data.city.diff === false &&
            data.adress.diff === false &&
            data.adress.postCode === false &&
            data.pn.diff === false &&
            data.wt.diff === false &&
            data.sr.diff === false &&
            data.czw.diff === false &&
            data.pt.diff === false &&
            data.sb.diff === false &&
            data.nd.diff === false
        ) {
            return false;
        } else {
            return true;
        }
    }

    prepareToSend(data) {
        if (this.checkIfDataNeedToBeUpdate(data) === false) {
            return null;
        } else {
            return {
                name: data.name,
                city: data.inDb ? (data.city.diff ? data.city.new : data.city.db) : data.city.new,
                adress: data.inDb ? (data.adress.diff ? data.adress.new : data.adress.db) : data.adress.new,
                postCode: data.inDb ? (data.postCode.diff ? data.postCode.new : data.postCode.db) : data.postCode.new,
                openHourConf: {
                    pn: data.inDb ? (data.pn.diff ? data.pn.new : data.pn.db) : data.pn.new,
                    wt: data.inDb ? (data.wt.diff ? data.wt.new : data.wt.db) : data.wt.new,
                    sr: data.inDb ? (data.sr.diff ? data.sr.new : data.sr.db) : data.sr.new,
                    czw: data.inDb ? (data.czw.diff ? data.czw.new : data.czw.db) : data.czw.new,
                    pt: data.inDb ? (data.pt.diff ? data.pt.new : data.pt.db) : data.pt.new,
                    sb: data.inDb ? (data.sb.diff ? data.sb.new : data.sb.db) : data.sb.new,
                    nd: data.inDb ? (data.nd.diff ? data.nd.new : data.nd.db) : data.nd.new,
                },
            };
        }
    }

    handleChangeOne = async (data) => {
        try {
            this.setState({
                showConfirmationModal: true,
                dataCountToModify: 0,
                dataCountModifyTillNow: 0,
            });
            if (data.inDb === false) {
                // dodawanie nowego
                const shop = this.prepareToSend(data);
                if (shop) {
                    const {data: newShop} = await Shop.add(shop);
                    if (newShop) {
                        toast.success("Nowy sklep został dodany!");
                        this.handleFile(this.state.file);
                        return;
                    }
                }
            } else {
                const shop = this.prepareToSend(data);
                if (shop) {
                    const {data: updatedShop} = await Shop.update(data.id, shop);
                    if (updatedShop) {
                        toast.success("Dane zostały zmienione!");
                        this.handleFile(this.state.file);
                        return;
                    }
                }
            }

        } catch (ex) {

            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd:" + ex);
            } else {
                toast.error("Błąd!!");
            }
        }
        this.setState({
            showConfirmationModal: false,
            dataCountToModify: 0,
            dataCountModifyTillNow: 0,
        });

    };

    // modifyMany = async () => {
    //   const data = this.state.data;
    //   let dataCountToModify = 0;
    //   let dataCountModifyTillNow = 0;

    //   for await (const _shop of data) {
    //     console.log(_shop.name);
    //     if (_shop.isChecked === true) {
    //       try {
    //         if (_shop.inDb === false) {
    //           // dodawanie nowego
    //           const shop = this.prepareToSend(_shop);
    //           if (shop) {
    //             const { data: newShop } = await Shop.add(shop);
    //             if (newShop) {
    //               dataCountModifyTillNow++;
    //               this.setState({ dataCountModifyTillNow });
    //             }
    //           }
    //         } else {
    //           const shop = this.prepareToSend(_shop);
    //           if (shop) {
    //             const { data: updatedShop } = await Shop.update(_shop.id, shop);
    //             if (updatedShop) {
    //               dataCountModifyTillNow++;
    //               this.setState({ dataCountModifyTillNow });
    //             }
    //           }
    //         }
    //       } catch (ex) {
    //         if (ex.response && ex.response.status === 400) {
    //           toast.error("Błąd:" + ex);
    //         } else {
    //           toast.error("Błąd!!");
    //         }
    //       }
    //     }
    //   }

    //   console.log("refresh file 1!!");
    //   this.handleFile(this.state.file);
    //   this.setState({
    //     showConfirmationModal: false,
    //     dataCountToModify: dataCountToModify,
    //     dataCountModifyTillNow: 0,
    //   });
    // };

    modifySelected = async () => {
        const data = this.state.data;
        let dataCountToModify = 0;

        data.forEach((shop) => {
            if (shop.isChecked && this.checkIfDataNeedToBeUpdate(shop)) dataCountToModify++;
        });

        this.setState({
            showConfirmationModal: true,
            dataCountToModify: dataCountToModify,
            dataCountModifyTillNow: 0,
        });

        let dataCountModifyTillNow = 0;


        for await (const _shop of data) {
            console.log(_shop.name);
            if (_shop.isChecked === true) {
                try {
                    if (_shop.inDb === false) {
                        // dodawanie nowego
                        const shop = this.prepareToSend(_shop);
                        if (shop) {
                            const {data: newShop} = await Shop.add(shop);
                            if (newShop) {
                                dataCountModifyTillNow++;
                                // this.setState({dataCountModifyTillNow});
                            }
                        }
                    } else {
                        const shop = this.prepareToSend(_shop);
                        if (shop) {
                            const {data: updatedShop} = await Shop.update(_shop.id, shop);
                            if (updatedShop) {
                                dataCountModifyTillNow++;
                                // this.setState({dataCountModifyTillNow});
                            }
                        }
                    }
                } catch (ex) {
                    if (ex.response && ex.response.status === 400) {
                        toast.error("Błąd:" + ex);
                    } else {
                        toast.error("Błąd!!");
                    }
                }
            }
        }

        this.handleFile(this.state.file);

    };


    handleSelectAll = () => {
        let {data, allSelected} = this.state;

        if (allSelected) {
            allSelected = false;
        } else {
            allSelected = true;
        }

        data.forEach((shop) => {
            shop.isChecked = allSelected;
        });

        this.setState({data, allSelected, dataCountToModify: this.updateCountToModify()});
    };

    handleCheckToChange = (index) => {
        const data = this.state.data;
        let allSelected = this.state.allSelected;

        if (index !== -1) {
            if (data[index].isChecked) {
                data[index].isChecked = false;
            } else {
                data[index].isChecked = true;
            }

            if (data[index].isChecked === false) {
                allSelected = false;
            }


            this.setState({data, allSelected, dataCountToModify: this.updateCountToModify()});
        }
    };

    updateCountToModify = () => {
        const data = this.state.data;
        let dataCountToModify = 0;
        data.forEach((shop) => {
            if (shop.isChecked && this.checkIfDataNeedToBeUpdate(shop)) dataCountToModify++;
        });
        return dataCountToModify;
    }
    handleOk = () => {
        this.setState({showConfirmationModal: false});
    };

    handleCancel = () => {
        this.setState({showConfirmationModal: false});
    };

    render() {
        return (
            <ConfirmationModalContextProvider>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                                     href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                                     href="/shops">Sklepy</Breadcrumb.Item>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} active>Import
                        z pliku .xlsx</Breadcrumb.Item>
                </Breadcrumb>
                <Container>
                    {isAdmin() && (
                        <React.Fragment>
                            {this.state.data.length ? (
                                <Row>
                                    <Col xs="12" ms="12" className="mb-0">
                                        <ConfirmationButton
                                            label={dictionary.shopsImport.modifyButton_confirmLabel}
                                            yesLabel={dictionary.shopsImport.modifyButton_confirmYesLabel}
                                            noLabel={dictionary.shopsImport.modifyButton_confirmNoLabel}
                                            variant="primary"
                                            className="my-0"
                                            size="sm"
                                            onClick={(e) => this.modifySelected()}
                                            style={{width: "100%", verticalAlign: "middle"}}
                                            disabled={(this.state.dataCountToModify !== 0) ? false : true}
                                        >
                                            {dictionary.shopsImport.modifyButton_label}
                                        </ConfirmationButton>
                                    </Col>
                                </Row>
                            ) : null}

                            <DragDropFile handleFile={this.handleFile}>
                                <XLSXFileInput handleFile={this.handleFile}/>

                                {this.state.data.length ? (
                                    <div className="row mt-3">
                                        <div className="col-xs-12">
                                            <ShopOutTable
                                                data={this.state.data}
                                                cols={this.state.cols}
                                                onChangeOne={this.handleChangeOne}
                                                onSelectToModify={this.handleCheckToChange}
                                                allSelected={this.state.allSelected}
                                                onAllSelect={this.handleSelectAll}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </DragDropFile>
                        </React.Fragment>
                    )}
                </Container>
                <ProgressModal
                    show={this.state.showConfirmationModal}
                    actual={this.state.dataCountModifyTillNow}
                    max={this.state.dataCountToModify}
                />
            </ConfirmationModalContextProvider>
        );
    }
}

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = {name: XLSX.utils.encode_col(i), key: i};
    return o;
};

/* extractExactShopData */
const make_shop_table = async (data, shops) => {
    const slicedData = data.slice(1);

    const results = [];
    slicedData.forEach((data) => {
        let shop = {};

        if (data[0] !== null && data[0] !== undefined) {
            if (String(data[0]).length > 0 && String(data[0]).length <= 4) {
                if (Number(data[0])) {
                    shop.name =
                        data[0] !== null && data[0] !== undefined ? String(data[0]).padStart(4, "0") : "";

                    const shopInDB = shops.find((x) => x.name === shop.name);

                    if (shopInDB) {
                        shop.inDb = true;
                        shop.id = shopInDB._id;
                    } else {
                        shop.inDb = false;
                    }

                    shop.isChecked = false;

                    if (shop.inDb) {
                        shop.city = {
                            diff: diffValueInDBToValueInFile(shopInDB.city, data[1]),
                            new: data[1] !== null && data[1] !== undefined ? data[1] : "",
                            db: shopInDB.city,
                        };
                        shop.adress = {
                            diff: diffValueInDBToValueInFile(shopInDB.adress, data[2]),
                            new: data[2] !== null && data[2] !== undefined ? data[2] : "",
                            db: shopInDB.adress,
                        };
                        shop.postCode = {
                            diff: diffValueInDBToValueInFile(shopInDB.postCode, data[3]),
                            new: data[3] !== null && data[3] !== undefined ? data[3] : "",
                            db: shopInDB.postCode,
                        };
                        shop.pn = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.pn : null,
                                splitHours(data[4])
                            ),
                            new: splitHours(data[4]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.pn : null,
                        };
                        shop.wt = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.wt : null,
                                splitHours(data[5])
                            ),
                            new: splitHours(data[5]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.wt : null,
                        };
                        shop.sr = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.sr : null,
                                splitHours(data[6])
                            ),
                            new: splitHours(data[6]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.sr : null,
                        };
                        shop.czw = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.czw : null,
                                splitHours(data[7])
                            ),
                            new: splitHours(data[7]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.czw : null,
                        };
                        shop.pt = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.pt : null,
                                splitHours(data[8])
                            ),
                            new: splitHours(data[8]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.pt : null,
                        };
                        shop.sb = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.sb : null,
                                splitHours(data[9])
                            ),
                            new: splitHours(data[9]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.sb : null,
                        };
                        shop.nd = {
                            diff: diffOpenHourValueInDBToValueInFile(
                                shopInDB.openHourConf ? shopInDB.openHourConf.nd : null,
                                splitHours(data[10])
                            ),
                            new: splitHours(data[10]),
                            db: shopInDB.openHourConf ? shopInDB.openHourConf.nd : null,
                        };
                    } else {
                        shop.city = {
                            new: data[1] !== null && data[1] !== undefined ? data[1] : "",
                        };
                        shop.adress = {
                            new: data[2] !== null && data[2] !== undefined ? data[2] : "",
                        };
                        shop.postCode = {
                            new: data[3] !== null && data[3] !== undefined ? data[3] : "",
                        };
                        shop.pn = {
                            new: splitHours(data[4]),
                        };
                        shop.wt = {
                            new: splitHours(data[5]),
                        };
                        shop.sr = {
                            new: splitHours(data[6]),
                        };
                        shop.czw = {
                            new: splitHours(data[7]),
                        };
                        shop.pt = {
                            new: splitHours(data[8]),
                        };
                        shop.sb = {
                            new: splitHours(data[9]),
                        };
                        shop.nd = {
                            new: splitHours(data[10]),
                        };
                    }
                    results.push(shop);
                }
            }
        }
    });
    return results;
};

const diffValueInDBToValueInFile = (fromDB, fromFile) => {
    if (fromDB !== null && fromDB !== undefined && fromDB !== "") {
        if (fromFile !== null && fromFile !== undefined) {
            if (fromFile !== fromDB) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    } else {
        if (fromFile !== null && fromFile !== undefined && fromFile !== "") {
            return true;
        } else {
            return false;
        }
    }
};

const splitHours = (data) => {
    if (data) {
        let temp = String(data).replaceAll(" ", "");
        let openHours = temp.split("-");
        if (openHours.length === 2) {
            return openHours;
        }
    }

    return ["00:00", "00:00"];
};

const diffOpenHourValueInDBToValueInFile = (fromDB, fromFile) => {
    if (fromDB !== null && fromDB !== undefined) {
        if (fromFile !== null && fromFile !== undefined) {
            if (fromFile[0] !== fromDB[0]) {
                return true;
            }
            if (fromFile[1] !== fromDB[1]) {
                return true;
            }
            return false;
        }

        return true;
    } else {
        return true;
    }
};

export default ShopsImport;
