import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import config from "../../../config.json";

export default function BoolValueControl({
                                                device,
                                                value,
                                                key,
                                                onChange,
                                                label,
                                                error,
                                                enable,
                                                ...rest
                                            }) {

    const labelNoErrorColor = () => {
        if(enable === false) return null;
        else return "#ffffff"
    };

    const labelColor = () => {
        if(error !== null && error !== undefined)
        {
            if(error === false){
                return labelNoErrorColor();
            }
            else return "#DB222A"
        }
        else
        {
            return null;
        }

    }

    return (
        <React.Fragment>
            <div className="row justify-content-center" style={{width: "90%"}}>
                <div className="col-12 w-auto">
                    <Table borderless={true} responsive="xs" className="my-0">
                        <tbody>
                        <tr className="align-middle">
                            <th
                                style={{
                                    textAlign: "right",
                                    minWidth: "100px",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <p className="m-2" style={{color: labelColor()}}>{label}</p>
                            </th>

                            <td
                                style={{
                                    textAlign: "left",
                                    // minWidth: "200px",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Form.Check
                                    type="switch"
                                    // id={id}
                                    className=""
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                />

                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="row my-0 mx-auto" style={{width: "90%"}}>
                <hr className="my-0 m-auto"></hr>
            </div>
        </React.Fragment>
    );
}
