import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Input from "./input";

import { toast } from "react-toastify";

import WifiService from "../../services/wifiService";
import ConfirmationIcon from "./confirmationIcon";

import dictionary from "../../PL.json";
import { prepareDateWithSec, prepareDate } from "../../utils/dateFormat";

// import * as AuthDeviceService from "../../../services/authDeviceService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function ShowWifi({ ssid, all, addedAt, shopId, _id, onRefreshData }) {
  const [visibility, setVisibility] = useState(false);
  const [pass, setPass] = useState(null);

  const handleRemove = async () => {
    try {
      if (shopId !== null && shopId !== undefined) {
        const { data } = await WifiService.removeShop(_id, shopId);
        await onRefreshData();
      }
    } catch (err) {
      if (err.response?.status === 409) toast.error("Taka sieć już istnieje");
    }
  };

  const handleVisibility = async () => {
    if (visibility === false)
      if (pass === null) {
        try {
        
          const { data } = await WifiService.get(_id);
          if (data) setPass(data?.pass);
        } catch (err) {

          toast.error("Błąd komunikacji z serwerem");
        }
      }

    setTimeout(() => {
      setVisibility(false);
    }, 2000);

    setVisibility(!visibility);
  };

  //   formatLogs = (log, index) => {
  //     let colorMark = log.service ? "#942911" : "#f5cb42";
  //     let colorService = log.service ? "#942911" : "#000000";
  //     let colorLine = index % 2 === 0 ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.3)";
  //     return (
  //       <p
  //         key={"log_" + index}
  //         style={{ color: "black", padding: "0", margin: "0", backgroundColor: colorLine }}
  //       >
  //         <b style={{ color: "#121619" }}> {prepareDateWithSec(log.timestamp * 1000)} </b>
  //         <mark
  //           style={{
  //             backgroundColor: colorMark,
  //             display: "inline-block",
  //             padding: "7px",
  //             borderRadius: "4px",
  //             fontSize: "12px",
  //           }}
  //         >
  //           {/* { sn, fw, hw,type, timestamp, event, message }; */}
  //         </mark>
  //         {" " + log.type + " " + log.sn}
  //         <b style={{ color: colorService }}>{" " + log.message + " "}</b>
  //         {"    C:" + log.event + " "}
  //       </p>
  //     );
  //   };

  const backgroundColor = () => {
    return all ? "rgb(216, 201, 155)" : "rgb(203, 234, 166)";
  };

  return (
    <div className=" ">
      <Row
        className="flex-container flex-container__center w-100 m-2"
        style={{ borderRadius: "5px", backgroundColor: backgroundColor() }}
      >
        <Col md="3" xs="10" style={{ fontSize: "0.7em", fontWeight: "bold" }}>
          {prepareDateWithSec(addedAt)}
        </Col>
        <Col md="3" xs="10" className="p-0">
          {ssid}
        </Col>
        <Col md="4" xs="10" className="flex-container  flex-container__center p-0 m-0">
          <p className="p-0 m-0">{visibility ? pass : "••••••••••"}</p>

          <FontAwesomeIcon
            className="ms-2"
            onClick={() => handleVisibility()}
            style={{ cursor: "pointer" }}
            size="xs"
            icon={visibility ? faEyeSlash : faEye}
          />
        </Col>

        <Col xs="12" md="1">
          {all && (
            <ConfirmationIcon
              label={dictionary.wifiSettings.removeButtonGlobal_confirmLabel}
              yesLabel={dictionary.wifiSettings.removeButtonGlobal_confirmYesLabel}
              noLabel={dictionary.wifiSettings.removeButtonGlobal_confirmNoLabel}
              onClick={handleRemove}
              className="tag__delete ms-1"
              icon={faTrash}
              size="1x"
            />
          )}
          {!all && (
            <ConfirmationIcon
              label={dictionary.wifiSettings.removeButton_confirmLabel}
              yesLabel={dictionary.wifiSettings.removeButton_confirmYesLabel}
              noLabel={dictionary.wifiSettings.removeButton_confirmNoLabel}
              onClick={handleRemove}
              className="tag__delete ms-1"
              icon={faTrash}
              size="1x"
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
