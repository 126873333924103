import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import config from "../../../config.json";


let commonStyle = {
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0",
    padding: "0",
};
export default function VolumeSettingsBlock({
                                                device,
                                                value,
                                                onVolumeChange,
                                                onEnableChange,
                                                onNameChange,
                                                onZoneChange,
                                                label,
                                                editLabelDisabled
                                            }) {
    const disabled = !value.enable ? true : false;
    let chNumber = value.spkNo + 1;



    return (
        <tr>

            <td
                style={{
                    verticalAlign: "middle",
                    textAlign: "right",
                    minWidth: "100px",
                    margin: "0",
                    padding: "0",
                    fontWeight: "bold"
                }}
            >
                {"SP" + chNumber + ": "}
            </td>

            <td
                style={{
                    ...commonStyle,
                }}
            >
                    <input
                        type="checkbox"
                        id={device.sn + "zone" + value.spkNo}
                        checked={value.zone === config.zoneMASettings.checkouts ? true : false}
                        onChange={(e) => onZoneChange(config.zoneMASettings.checkouts,value.spkNo, e.target.checked)}
                        disabled={editLabelDisabled}
                    />
            </td>
            <td
                style={{
                    ...commonStyle,
                }}
            >
                    <input
                        type="checkbox"
                        id={device.sn + "zone" + value.spkNo}
                        checked={value.zone === config.zoneMASettings.salesroom ? true : false}
                        onChange={(e) => onZoneChange(config.zoneMASettings.salesroom,value.spkNo, e.target.checked)}
                        disabled={editLabelDisabled}
                    />
            </td>
            <td
                style={{
                    ...commonStyle,
                    minWidth: "50px",
                }}
            >
                    <input
                        type="checkbox"
                        id={device.sn + "zone" + value.spkNo}
                        checked={value.zone === config.zoneMASettings.background ? true : false}
                        onChange={(e) => onZoneChange(config.zoneMASettings.background,value.spkNo, e.target.checked)}
                        disabled={editLabelDisabled}
                    />
            </td>
            <td
                style={{
                    textAlign: "center",
                    minWidth: "100px",
                    margin: "0",
                    padding: "0",
                    fontWeight: "bold"
                }}
            >
                <p className="m-2">
                    <input
                        type="text"
                        id={device.sn + "text" + value.spkNo}
                        value={label}
                        onChange={({currentTarget: input}) =>
                            onNameChange(value.spkNo, input.value)
                        }
                        disabled={editLabelDisabled}
                    />

                </p>
            </td>

            <td
                style={{
                    textAlign: "center",
                    minWidth: "200px",
                    margin: "0",
                    padding: "0",
                }}
            >
                <div className="m-2" style={{width: "100%"}}>
                        <input
                            type="range"
                            style={{width: "90%"}}
                            min="0"
                            max="16"
                            key="1"
                            value={value.volume}
                            onChange={({currentTarget: input}) =>
                                onVolumeChange(value.spkNo, input.value)
                            }
                            disabled={disabled}
                        />

                </div>
            </td>
            <td
                style={{
                    textAlign: "center",
                    width: "80px",
                    margin: "0",
                    padding: "0",
                }}
            >
                <p className="m-2">{value.volume}</p>
            </td>

            <td style={{textAlign: "center", margin: "0", padding: "0"}}>
                <Form.Check
                    type="switch"
                    id={device.sn + "en" + value.spkNo}
                    className="m-2"
                    checked={value.enable}
                    onChange={(e) => onEnableChange(value.spkNo, e.target.checked)}
                />
            </td>
        </tr>

    );
}
