import React from 'react';
import {Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faListAlt, faEdit, faSquareCheck, faSquare, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {prepareDate} from "../../utils/dateFormat";
import ConfirmationIcon from "./confirmationIcon";
import dictionary from "../../PL.json";
import {faSave} from "@fortawesome/free-solid-svg-icons";

function PlaylistView({id, name, date, active, onClick, onEditClick, onSetActive, onDeleteClick}){
    return (
        <div onClick={(e) => onClick(e, id)} onContextMenu={(e) => onClick(e, id)}
             className={"file w-100 mx-auto border rounded-3 p-1 my-1 d-flex justify-content-center align-items-center "}>
            <FontAwesomeIcon className={"mx-3"}
                             icon={faListAlt} size={"2x"}/>
            <div className={" flex-grow-1 fs-6 text-start"}>{name}</div>
            {/*<div className={" me-2 fs-6 "} style={{fontSize: "1.2rem"}}>{`${Math.round(duration / 60)} min ${Math.round(duration % 60)} sek`}</div>*/}

            <div className={" me-2 fs-6 "} style={{fontSize: "1.2rem"}}>{prepareDate(date)}</div>

            <FontAwesomeIcon
                className={"mx-2 onlist__edit align-self-center "}
                onClick={(e) => onEditClick(e, id)}
                icon={faEdit} size={"lg"}/>


            <FontAwesomeIcon
                className={"mx-2 onCheck align-self-center "}
                onClick={(e) => onSetActive(e, id)}
                icon={active ? faSquareCheck : faSquare} size={"lg"}/>



            <ConfirmationIcon
                label={dictionary.music.playlist.deleteButton_confirmLabel}
                yesLabel={dictionary.music.playlist.deleteButton_confirmYesLabel}
                noLabel={dictionary.music.playlist.deleteButton_confirmNoLabel}
                onClick={(e) => onDeleteClick(e, id)}
                className="onDelete_trash mx-2 align-self-center "
                icon={faTrashAlt}
                size={"lg"}
            />


        </div>
    );
}

export default PlaylistView;