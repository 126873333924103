import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Input from "./input";

import WifiService from "../../services/wifiService";
import ConfirmationIcon from "./confirmationIcon";
import AddWifi from "./addWifi";
import ShowWifi from "./showWifi";
import dictionary from "../../PL.json";

// import * as AuthDeviceService from "../../../services/authDeviceService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faStop } from "@fortawesome/free-solid-svg-icons";

export default function ShopWifisInTable({ shop, onRefreshData }) {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const editIconStyle = () => {
    let style = { cursor: "pointer" };
    edit ? (style.color = "rgb(170, 27, 27)") : (style.color = "black");
    return style;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          {shop.wifis &&
            shop.wifis.map((wifi) => {
              return (
                  <ShowWifi
                    key={"id_"+wifi._id}
                    onRefreshData={onRefreshData}
                    _id={wifi._id}
                    ssid={wifi.ssid}
                    all={wifi.all}
                    addedAt={wifi.addedAt}
                    shopId={shop._id}
                  />
              );
            })}
        </Col>
        <Col xs="1" className="align-self-center" style={{ height: "100%" }}>
          <FontAwesomeIcon
            className="ms-2"
            onClick={() => handleEdit()}
            style={editIconStyle()}
            icon={faEdit}
          />
        </Col>
      </Row>

      {edit && <AddWifi shopId={shop._id} onRefreshData={onRefreshData}/>}
    </React.Fragment>
  );
}
