import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default class Home extends Component {
  state = {};
  componentDidMount() {
      
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb style={{ width: "100%" }}>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <Row>
            <Col ms="12" xs="12">
              <h3 style={{ textAlign: "center" }}>
                Krótka instrukcja korzystania z serwisu.{" "}
              </h3>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
