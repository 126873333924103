import React, {useState, useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ProgressBar from "react-bootstrap/ProgressBar";
import {DistanceTriangleProgressBar} from "./distanceTriangleProgressBar";
import sensor from "../../../sensor.png";
import logoProdel from "../../../logoProdel.png";

const DistanceShowControlNew4Way = ({
                                        header,
                                        s1_label,
                                        s2_label,
                                        s3_label,
                                        s4_label,
                                        s1_now,
                                        s2_now,
                                        s3_now,
                                        s4_now,
                                        s1_status,
                                        s2_status,
                                        s3_status,
                                        s4_status,
                                        max,
                                        active,
                                        blocked,
                                    }) => {
    const [block, setBlock] = useState([0, 0, 0, 0]);
    const [activeSettings, setACtiveSettings] = useState([0, 0, 0, 0]);

    const setVariant = (now, status) => {
        // console.log(now);
        if (status !== 2) {
            if (status === 1) {
                return "success";
            }

            return "danger";
        } else {
            return "secondary";
        }
    };

    const setVariantColor = (now, status) => {
        // console.log(now);
        if (status !== 2) {
            if (status === 1) {
                return "#198754";
            }

            return "#dc3545";
        } else {
            return "gray";
        }
    };

    const isBlocked = (sensor) => {
        if (blocked && blocked[sensor] !== null && blocked[sensor] !== undefined) {
            if (blocked[sensor]) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    useEffect(() => {
        let block = [0, 0, 0, 0];
        block[0] = isBlocked(0);
        block[1] = isBlocked(1);
        block[2] = isBlocked(2);
        block[3] = isBlocked(3);
        setBlock(block);

        let activeSettings = [0, 0, 0, 0];
        activeSettings[0] = active[0] ? active[0] : 0;
        activeSettings[1] = active[1] ? active[1] : 0;
        activeSettings[2] = active[2] ? active[2] : 0;
        activeSettings[3] = active[3] ? active[3] : 0;
        setACtiveSettings(activeSettings);

    }, [blocked, active]);

    return (
        <React.Fragment>
            <Col sm="12">
                <Row
                    sm="12"
                    className="mx-auto my-auto"
                    style={{textAlign: "center", fontsize: "8", fontWeight: "bold"}}
                >
                    <Col sm="12">{header}</Col>
                </Row>
                <Row sm="12" className="mx-auto px-0 my-1">
                    <Col md="2" sm="2" className="mx-auto px-0" style={{width: "auto"}}>
                        <img
                            src={sensor}
                            width="132"
                            height="100%"

                            alt="ROSS"
                        />
                    </Col>
                    <Col md="10" sm="10">
                        <Row sm="12" className="mx-0 px-0 my-1">
                            <Col sm="12">
                                <DistanceTriangleProgressBar
                                    now={s1_now}
                                    max={200}
                                    label={s1_label}
                                    striped={block[0] ? true : false}
                                    variant={block[0] ? "#ffca2c" : setVariantColor(s1_now, s1_status)}
                                />
                            </Col>
                            <Col sm="12">
                                <DistanceTriangleProgressBar
                                    now={s2_now}
                                    max={200}
                                    label={s2_label}
                                    striped={block[1] ? true : false}
                                    variant={block[1] ? "#ffca2c" : setVariantColor(s2_now, s2_status)}
                                />
                            </Col>
                            <Col sm="12">
                                <DistanceTriangleProgressBar
                                    now={s3_now}
                                    max={200}
                                    label={s3_label}
                                    striped={block[2] ? true : false}
                                    variant={block[2] ? "#ffca2c" : setVariantColor(s3_now, s3_status)}
                                />
                            </Col>
                            <Col sm="12">
                                <DistanceTriangleProgressBar
                                    now={s4_now}
                                    max={200}
                                    label={s4_label}
                                    striped={block[3] ? true : false}
                                    variant={block[3] ? "#ffca2c" : setVariantColor(s4_now, s4_status)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/*<Row sm="12" className="mx-auto my-1">*/}
                {/*    <Col sm="12">*/}
                {/*        <ProgressBar*/}
                {/*            now={s2_now}*/}
                {/*            max={max}*/}
                {/*            label={s2_label}*/}
                {/*            striped={block[1] ? true : false}*/}
                {/*            variant={block[1] ? "warning" : setVariant(s2_now, s2_status)}*/}
                {/*            style={{height: block[1] ? "15px" : "30px"}}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row sm="12" className="mx-auto my-1">*/}
                {/*    <Col sm="12">*/}
                {/*        <ProgressBar*/}
                {/*            now={s3_now}*/}
                {/*            max={max}*/}
                {/*            label={s3_label}*/}
                {/*            striped={block[2] ? true : false}*/}
                {/*            variant={block[2] ? "warning" : setVariant(s3_now, s3_status)}*/}
                {/*            style={{height: block[2] ? "15px" : "30px"}}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row sm="12" className="mx-auto my-1">*/}
                {/*    <Col sm="12">*/}
                {/*        <ProgressBar*/}
                {/*            now={s4_now}*/}
                {/*            max={max}*/}
                {/*            label={s4_label}*/}
                {/*            striped={block[3] ? true : false}*/}
                {/*            variant={block[3] ? "warning" : setVariant(s4_now, s4_status)}*/}
                {/*            style={{height: block[3] ? "15px" : "30px"}}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Col>
        </React.Fragment>
    );
};

export default DistanceShowControlNew4Way;
