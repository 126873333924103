import http from "./httpService";

const apiEndpoint ="/shops";

function add(data){
    return http.post(apiEndpoint,data);
}


function update(_id,data){
    return http.put(apiEndpoint+"/"+_id,data);
}

function putPlaylistAdd(shopsIds,playlistId){
    return http.put(apiEndpoint+"/playlistadd", {shopsIds, playlistId});
}

function putPlaylistRemove(shopsIds,playlistId){
    return http.put(apiEndpoint+"/playlistremove", {shopsIds, playlistId});
}

function putPlaylistRemoveAll(shopsIds){
    return http.put(apiEndpoint+"/playlistremoveall", {shopsIds});
}

function get(_id){
    return http.get(apiEndpoint+"/"+_id);
}

function getAll(){
    return http.get(apiEndpoint);
}

function getAllWithDevicesAssigned(){
    return http.get(apiEndpoint+"/inuse");
}


function getAllWithDevicesActiveLastHour(){
    return http.get(apiEndpoint+"/inuselasthour");
}


function getAllShopsAndDevices(){
    return http.get(apiEndpoint+"/allshopsdata");
}

function remove(_id){
    return http.delete(apiEndpoint+"/"+_id);
}


export default  {add, update, get, getAll, getAllWithDevicesAssigned,getAllWithDevicesActiveLastHour,getAllShopsAndDevices, remove,putPlaylistAdd, putPlaylistRemove, putPlaylistRemoveAll};