export function audioDev(object, audioDev, id) {

    const index = object.findIndex((x) => x.id === id);
    object[index].audioDev = audioDev;

    return object;
}

export function file(object, file, id) {
    const index = object.findIndex((x) => x.id === id);

    object[index].file = file;

    return object;
}

export function spk(object, audioDevSn, spk, value, id) {
    const index = object.findIndex((x) => x.id === id);


    const position = object[index].to.findIndex(x => x.sn === audioDevSn);
    if (position !== -1) {
        if (spk === 1)
            object[index].to[position].spk1 = value;
        if (spk === 2)
            object[index].to[position].spk2 = value;
        if (spk === 3)
            object[index].to[position].spk3 = value;
        if (spk === 4)
            object[index].to[position].spk4 = value;
        if (spk === 5)
            object[index].to[position].spk5 = value;
        if (spk === 6)
            object[index].to[position].spk6 = value;
        if (spk === 7)
            object[index].to[position].spk7 = value;
        if (spk === 8)
            object[index].to[position].spk8 = value;
        if (spk === 9)
            object[index].to[position].spk9 = value;
        if (spk === 10)
            object[index].to[position].spk10 = value;
        if (spk === 11)
            object[index].to[position].spk11 = value;
        if (spk === 12)
            object[index].to[position].spk12 = value;
    }
    return object;
}

export function enable(object, audioDevSn, value, id) {
    const index = object.findIndex((x) => x.id === id);


    const position = object[index].to.findIndex(x => x.sn === audioDevSn);
    if (position !== -1) {
        object[index].to[position].enable = value;
    }


    return object;
}
