import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { toast } from "react-toastify";

import Pagination from "./common/pagination";
import { search } from "../utils/search";
import { paginate } from "../utils/paginate";

import * as FileService from "../services/fileService";
import { isAdmin } from "../services/authService";

import FileAddFormModal from "./fileAddFormModal";

import FilesTable from "./filesTable";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import _ from "lodash";
import SearchBox from "./common/searchBox";

class Files extends Component {
  state = {
    files: [],
    pageSize: 10,
    currentPage: 1,
    sortColumn: { path: "name", order: "asc" },
    searchQuery: "",
    fileAddFormModal: false,
  };

  async componentDidMount() {
    try {
      const { data: files } = await FileService.getAll();
      this.setState({ files });
    } catch (err) {
      toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path === "") return;

    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, files: allFiles, sortColumn, searchQuery } = this.state;

    let filtered = allFiles;

    if (searchQuery) {
      filtered = search(allFiles, ["name", "info"], searchQuery);
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const shops = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: shops };
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedDevType: null,
      currentPage: 1,
    });
  };

  handleDeleteFile = async (file) => {
    const _id = file._id;

    try {
      const { data: removedFile } = await FileService.remove(_id);
      if (removedFile) {
        toast.success("Plik został usunięty!");
      }

      const { data: files } = await FileService.getAll();

      this.setState({ files });
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        toast.error("Błąd!");
      } else {
        toast.error("Błąd po stronie serwera!");
      }
    }
  };

  handleAddFile = () => {
    this.setState({ fileAddFormModal: true });
  };

  handleFormHide = async () => {
    this.setState({ fileAddFormModal: false });

    const { data: files } = await FileService.getAll();

    this.setState({ files });
  };

  render() {
    const count = this.state.files.length;

    if (!isAdmin()) return <Redirect to="/shops" />;

    const { pageSize, currentPage, sortColumn, searchQuery, fileAddFormModal } = this.state;

    const { totalCount, data: files } = this.getPagedData();

    return (
      <React.Fragment>
        <ConfirmationModalContextProvider>
          <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
            <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} active>Konfiguracja</Breadcrumb.Item>
            <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} active>Zarządzanie plikami mp3</Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            {isAdmin() && (
              <Row>
                <Col ms="12" xs="12">
                  <Button onClick={this.handleAddFile} variant="primary">
                    Dodaj
                  </Button>
                </Col>
              </Row>
            )}
            <Row>
              <Col ms="12" xs="12">
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col ms="12" xs="12">
                <FilesTable
                  files={files}
                  sortColumn={sortColumn}
                  onDelete={this.handleDeleteFile}
                  onAdd={this.handleAdd}
                  onSort={this.handleSort}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col ms="12" xs="12">
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </Col>
            </Row>
            {isAdmin() && <FileAddFormModal show={fileAddFormModal} onHide={this.handleFormHide} />}
          </Container>
        </ConfirmationModalContextProvider>
      </React.Fragment>
    );
  }
}

export default Files;
