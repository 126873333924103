import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { toast } from "react-toastify";


import UnauthDeviceService from "../services/unauthDeviceService";
import ShopService from "../services/shopService";

import Pagination from "./common/pagination";
import {prepareTableForList} from "../utils/devType"
import {search } from "../utils/search"
import { paginate } from "../utils/paginate";
import UListGroup from "./common/uListGroup";

import UnauthDevicesTable from "./unauthDevicesTable";
import UnauthDeviceAddFormModal from "./unauthDeviceAddFormModal";
import Sync from "./common/sync";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import { isAdminOrIsService, isService } from "../services/authService";

class UnauthDevices extends Component {
  state = {
    unauthDevices: [],
    devTypes: [],
    shops: [],
    pageSize: 100,
    currentPage: 1,
    selectedDevType: null,
    sortColumn: { path: "sn", order: "asc" },
    searchQuery: "",
    selectedDevice: null,
    unauthDeviceAddFormModal: false,
  };

  async componentDidMount() {
    try
    {
      const { data: unauthDevices } = await UnauthDeviceService.getAll();
  
      const { data: shops } = await ShopService.getAll();

  
      this.setState({ unauthDevices, devTypes: prepareTableForList(), shops, selectedDevice: null });
    } catch (err) {
      toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  }

  handleDelete = async (event, unauthDevice) => {
    if (event) event.stopPropagation();

    const { data } = await UnauthDeviceService.remove(unauthDevice._id);

    const { data: unauthDevices } = await UnauthDeviceService.getAll();

    this.setState({ unauthDevices });
  };

  handleRowClick = (unauthDevice) => {
    this.setState({
      selectedDevice: unauthDevice,
      unauthDeviceAddFormModal: true,
    });
  };

  handleAdd = (unauthDevice) => {
    console.log(unauthDevice);
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleDevTypeSelect = (devType) => {
    this.setState({
      selectedDevType: devType,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path === "") return;

    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      unauthDevices: allUnauthDevices,
      selectedDevType,
      sortColumn,
      searchQuery,
    } = this.state;

    let filtered = allUnauthDevices;


    if (searchQuery) {
      filtered = search(
        allUnauthDevices,
        ["sn", "ssid", "fw_version", "hw_version"],
        searchQuery
      );
    } else if (selectedDevType && selectedDevType._id) {
      filtered = search(
        allUnauthDevices,
        ["dev_type"],
        selectedDevType.type,
        true
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const unauthDevices = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: unauthDevices };
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedDevType: null,
      currentPage: 1,
    });
  };

  render() {
    const count = this.state.unauthDevices.length;
    const {
      pageSize,
      currentPage,
      devTypes,
      selectedDevType,
      sortColumn,
      searchQuery,
      selectedDevice,
      unauthDeviceAddFormModal,
      shops,
    } = this.state;


    const { totalCount, data: unauthDevices } = this.getPagedData();

    return (
      <React.Fragment>
        <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
          <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }}>Serwis</Breadcrumb.Item>
          <Breadcrumb.Item style={{ marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0 }} active>Poczekalnia</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <Row>
            {count !== 0 && (
              <Col xs="11" style={{ textAlign: "center", width: "100%" }}>
                <h3>Lista urządzeń oczekujących na autoryzację: {count}</h3>
              </Col>
            )}
            {count === 0 && (
              <Col xs style={{ textAlign: "center", width: "100%" }}>
                <h3>Brak urządzeń oczekujących na autoryzację</h3>
              </Col>
            )}

            <Col xs="1" style={{ textAlign: "left" }}>
              <Sync onClick={() => (window.location = "/unauthDevices")} />
            </Col>
          </Row>

          {count !== 0 && (
            <React.Fragment>
              <Row>
                <Col xs="12">
                  <SearchBox value={searchQuery} onChange={this.handleSearch} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="2">
                  <UListGroup
                    items={devTypes}
                    onItemSelect={this.handleDevTypeSelect}
                    selectedItem={selectedDevType}
                    horizontal={false}
                  />
                </Col>

                <Col lg="10">
                  <Row>
                    <Col>
                      <UnauthDevicesTable
                        unauthDevices={unauthDevices}
                        shops={shops}
                        sortColumn={sortColumn}
                        onDelete={this.handleDelete}
                        onAdd={this.handleAdd}
                        onSort={this.handleSort}
                        onRowClick={this.handleRowClick}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="12">
                      <Pagination
                        itemsCount={totalCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <UnauthDeviceAddFormModal
                show={unauthDeviceAddFormModal}
                onHide={() => {
                  this.setState({
                    selectedDevice: null,
                    unauthDeviceAddFormModal: false,
                  });
                }}
                shops={shops}
                unauthDevice={selectedDevice}
                onDelete={this.handleDelete}
              />
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default UnauthDevices;
