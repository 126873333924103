import React, { Component } from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


export default class DragDropFile extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <Row >
        <Col className="pt-0" xs="12" ms="12"
          onDrop={this.onDrop}
          onDragEnter={this.suppress}
          onDragOver={this.suppress}
        >
          {this.props.children}
        </Col>
      </Row>
    );
  }
}
