import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ConfirmationButton from "./common/confirmationButton";

import {isAdmin} from "../services/authService"
import UTable from "./common/table";
import { prepareDate } from "../utils/dateFormat";
import {apiUrl} from "../config.json"
import { upload} from "../services/fileService"

import dictionary from "../PL.json";

class FilesTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nazwa",
    },
    {
      path: "info",
      label: "Opis",
    },
    {
      path: "date",
      label: "Data dodania",
      content: (file) => {
        return prepareDate(file.date);
      },
    },
    {
      path: "md5",
      label: "MD5",
    },
    {
      path: "",
      key: "download",
      content: (file) => (
        <Button
          
          variant="primary"
          className="my-0"
          size="sm"
          onClick={(e) => {
            upload(file);
          }}
        >
          Pobierz
        </Button>
      ),
    },
  ];

  deleteColumn = {
    path: "",
    key: "delete",
    content: (item) => (
      <ConfirmationButton
      label={dictionary.files.filesTable.deleteButton_confirmLabel}
      yesLabel={dictionary.files.filesTable.deleteButton_confirmYesLabel}
      noLabel={dictionary.files.filesTable.deleteButton_confirmNoLabel}
      variant="danger"
      className="my-0"
      size="sm"
      onClick={(event) => this.props.onDelete(item)}
    >
      {dictionary.authDevices.authDevicesTable.deleteButton_label}
    </ConfirmationButton>

    ),
  };

  constructor() {
    super();
    if (isAdmin()) {
      this.columns.push(this.deleteColumn);
    }
  }

  render() {
    const { files, sortColumn, onSort } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={files}
        dataId="_id"
      />
    );
  }
}

export default FilesTable;
