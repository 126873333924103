import React from "react";
import Form from "react-bootstrap/Form";


const Select = ({name, label, error, options, ...rest}) => {
    return (
        // <div className="form-group" >
        //   <label htmlFor={name}>{label}</label>
        <Form.Group controlId={name}>
            <Form.Label>{label}</Form.Label>
            <select {...rest} name={name} id={name} className="form-control">
                <option value=""/>
                {options.map((option) => {
                    return (
                        <option value={option._id} key={option._id}>
                            {option.name}
                        </option>
                    );
                })}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </Form.Group>
    );
};

export default Select;
