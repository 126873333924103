import React from 'react';
import Button from "react-bootstrap/Button";

import {useConfirmationModalContext} from "./modalConfirmationContext";

const ConfirmationButton = (props) => {
    const {className, onClick,label, yesLabel, noLabel, body, ...rest} = props;
    const modalContext = useConfirmationModalContext();

    const handleOnClick = async () => {
        modalContext.setLabel(label);
        modalContext.setYesLabel(yesLabel);
        modalContext.setNoLabel(noLabel);
        (body) ? modalContext.setBody(body) :  modalContext.setBody("");
        const result = await modalContext.showConfirmation();
        result && onClick();
    };

    return (
        <Button className={className} onClick={handleOnClick}  {...rest}>
            {props.children}
        </Button>
    )
};

export default ConfirmationButton;