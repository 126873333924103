import http from "./httpService";

const apiEndpoint = "/log";

function update(_id,data){
    return http.put(apiEndpoint+"/"+_id,data);
}

export function getByShopId(_id,data){
    return http.post(apiEndpoint+"/"+_id,data);
}


// function getAll(){
//     return http.get(apiEndpoint);
// }
//
// function getAllWithDevicesAssigned(){
//     return http.get(apiEndpoint+"/inuse");
// }
//
// function remove(_id){
//     return http.delete(apiEndpoint+"/"+_id);
// }


