import React, {useState, useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";

import ConfirmationButton from "../confirmationButton";
import dictionary from "../../../PL.json";
import config from "../../../config.json";

import DistanceShowControl from "./distanceShowControl";
import DistanceShowControl4Way from "./distanceShowControl4Way";
import DistanceShowControlNew4Way from "./distanceShowControlNew4Way";

import {beepTableGenerateAndFillWithData} from "../../../utils/conf/deviceConf";

import {FaBottleWater} from "react-icons/fa6";
//config.distanceSensorTypes.cashier_ID

const getDtSettings = (settings) => {
    if (!settings) return null;
    if (!settings.dt) return null;

    return settings.dt;
};

const getMaxDistanceSettings = (sensorId, settings, devType) => {
    // if (!settings && !settings.distanceSensorTable) return null;

    // const result = settings.distanceSensorTable.find(
    //   (sensor) => sensor.id === sensorId
    // );

    if (devType === "MS") {
        return 300;
    }

    if (devType === "MK") {
        return 150;
    }

    // if (result) return result.maxRange;
    return null;
};

const getActiveDistanceSettings = (sensorId, settings) => {
    if (!settings && !settings.distanceSensorTable) return null;

    const result = settings.distanceSensorTable.find((sensor) => sensor.id === sensorId);

    if (result) return result.activeRange;
    return null;
};

const getBlockedSensorsSettings = (sensorId, settings) => {
    if (!settings && !settings.distanceSensorTable) return null;

    const result = settings.distanceSensorTable.find((sensor) => sensor.id === sensorId);

    if (result) return result.blockSensor;
    return null;
};

function LiveDeviceVisualisation(props) {
    const {onlineDeviceData, devType, settings, onAction} = props;

    const isMK = (devType) => {
        return devType === "MK";
    };

    const isMKS = (devType) => {
        return devType === "MKS";
    };

    const isMA = (devType) => {
        return devType === "MA";
    };

    const isMS = (devType) => {
        return devType === "MS";
    };
    const isMD = (devType) => {
        return devType === "MD";
    };

    const [ceilingReport, setCeilingReport] = useState({L: 0, R: 0});
    const [cashReport, setCashReport] = useState({L: 0, R: 0});
    const [clientReport, setClientReport] = useState({L: 0, R: 0});
    const [dt, setDt] = useState(0);
    const [liquidLevel, setLiquidLevel] = useState(0);

    useEffect(() => {
        if (onlineDeviceData.report && onlineDeviceData.report.type) {
            if (onlineDeviceData.report.type === config.distanceSensorTypes.ceiling_ID) {
                const report = {L: 0, R: 0, LS: 0, RS: 0};

                report.L = onlineDeviceData.report.L ? Number(onlineDeviceData.report.L) / 10 : 0;
                report.R = onlineDeviceData.report.R ? Number(onlineDeviceData.report.R) / 10 : 0;

                report.LS = onlineDeviceData.report.LS ? Number(onlineDeviceData.report.LS) : 0;

                report.RS = onlineDeviceData.report.RS ? Number(onlineDeviceData.report.RS) : 0;

                if (report.s1 && report.s2 && report.s3 && report.s4) {
                    report.s1 = onlineDeviceData.report.s1 ? Number(onlineDeviceData.report.s1) / 10 : 0;

                    report.s1_s = onlineDeviceData.report.s1_s ? Number(onlineDeviceData.report.s1_s) : 0;

                    report.s2 = onlineDeviceData.report.s2 ? Number(onlineDeviceData.report.s2) / 10 : 0;

                    report.s2_s = onlineDeviceData.report.s2_s ? Number(onlineDeviceData.report.s2_s) : 0;

                    report.s3 = onlineDeviceData.report.s3 ? Number(onlineDeviceData.report.s3) / 10 : 0;

                    report.s3_s = onlineDeviceData.report.s3_s ? Number(onlineDeviceData.report.s3_s) : 0;

                    report.s4 = onlineDeviceData.report.s4 ? Number(onlineDeviceData.report.s4) / 10 : 0;

                    report.s4_s = onlineDeviceData.report.s4_s ? Number(onlineDeviceData.report.s4_s) : 0;
                }

                setCeilingReport(report);
            }

            if (onlineDeviceData.report.type === config.distanceSensorTypes.cashier_ID) {
                const report = {L: 0, R: 0, LS: 0, RS: 0};

                report.L = onlineDeviceData.report.L ? Number(onlineDeviceData.report.L) / 10 : 0;
                report.R = onlineDeviceData.report.R ? Number(onlineDeviceData.report.R) / 10 : 0;

                report.LS = onlineDeviceData.report.LS ? Number(onlineDeviceData.report.LS) : 0;

                report.RS = onlineDeviceData.report.RS ? Number(onlineDeviceData.report.RS) : 0;

                if (onlineDeviceData.report.s3 !== undefined) {
                    report.s1 = onlineDeviceData.report.s1 ? Number(onlineDeviceData.report.s1) / 10 : 0;

                    report.s1_s = onlineDeviceData.report.s1_s ? Number(onlineDeviceData.report.s1_s) : 0;

                    report.s2 = onlineDeviceData.report.s2 ? Number(onlineDeviceData.report.s2) / 10 : 0;

                    report.s2_s = onlineDeviceData.report.s2_s ? Number(onlineDeviceData.report.s2_s) : 0;

                    report.s3 = onlineDeviceData.report.s3 ? Number(onlineDeviceData.report.s3) / 10 : 0;

                    report.s3_s = onlineDeviceData.report.s3_s ? Number(onlineDeviceData.report.s3_s) : 0;

                    report.s4 = onlineDeviceData.report.s4 ? Number(onlineDeviceData.report.s4) / 10 : 0;

                    report.s4_s = onlineDeviceData.report.s4_s ? Number(onlineDeviceData.report.s4_s) : 0;
                }
                // console.log(onlineDeviceData.report)
                setCashReport(report);
            }

            if (onlineDeviceData.report.type === config.distanceSensorTypes.client_ID) {
                const report = {L: 0, R: 0, LS: 0, RS: 0};
                report.L = onlineDeviceData.report.L ? Number(onlineDeviceData.report.L) / 10 : 0;
                report.R = onlineDeviceData.report.R ? Number(onlineDeviceData.report.R) / 10 : 0;

                report.LS = onlineDeviceData.report.LS ? Number(onlineDeviceData.report.LS) : 0;

                report.RS = onlineDeviceData.report.RS ? Number(onlineDeviceData.report.RS) : 0;

                if (onlineDeviceData.report.s3 != undefined) {
                    report.s1 = onlineDeviceData.report.s1 ? Number(onlineDeviceData.report.s1) / 10 : 0;

                    report.s1_s = onlineDeviceData.report.s1_s ? Number(onlineDeviceData.report.s1_s) : 0;

                    report.s2 = onlineDeviceData.report.s2 ? Number(onlineDeviceData.report.s2) / 10 : 0;

                    report.s2_s = onlineDeviceData.report.s2_s ? Number(onlineDeviceData.report.s2_s) : 0;

                    report.s3 = onlineDeviceData.report.s3 ? Number(onlineDeviceData.report.s3) / 10 : 0;

                    report.s3_s = onlineDeviceData.report.s3_s ? Number(onlineDeviceData.report.s3_s) : 0;

                    report.s4 = onlineDeviceData.report.s4 ? Number(onlineDeviceData.report.s4) / 10 : 0;

                    report.s4_s = onlineDeviceData.report.s4_s ? Number(onlineDeviceData.report.s4_s) : 0;
                }

                // console.log(onlineDeviceData.report)
                setClientReport(report);
            }

            if (onlineDeviceData.report.dt) {
                const dt = onlineDeviceData.report.dt;

                setDt(dt);
            }


        }
    }, [props]);

    const dtInSettings = getDtSettings(settings);

    return (
        <React.Fragment>
            {/* moduł kasy i moduł kasy samoobsługowej*/}
            {(isMK(devType) || isMKS(devType)) && (
                <React.Fragment>
                    <Row className="my-2">
                        <Col style={{textAlign: "center", fontWeight: "bold"}}>Komunikaty:</Col>

                        {[1, 2, 3, 4, 5, 6].map((x) => {
                            return (
                                <Col key={x} className="mx-auto my-0">
                                    <ConfirmationButton
                                        label={
                                            dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmLabel
                                        }
                                        yesLabel={
                                            dictionary.shopView.cardDevice.liveDeviceVisualisation
                                                .demoButton_confirmYesLabel
                                        }
                                        noLabel={
                                            dictionary.shopView.cardDevice.liveDeviceVisualisation
                                                .demoButton_confirmNoLabel
                                        }
                                        style={{backgroundColor: "#48B8D0", borderColor: "#48B8D0"}}
                                        variant="success"
                                        className="py-0 width-max"
                                        size="sm"
                                        onClick={() => onAction({action: "beep", id: x})}
                                    >
                                        {x}
                                    </ConfirmationButton>
                                    {/* <Button
                    variant="info"
                    className="py-0 width-max"
                    size="sm"
                    onClick={() => onAction({ action: "beep", id: x })}
                  >
                    {x}
                  </Button> */}
                                </Col>
                            );
                        })}
                    </Row>
                </React.Fragment>
            )}

            {/* moduł sufitowy*/}
            {isMS(devType) && (
                <React.Fragment>
                    <Row className="my-2">
                        <DistanceShowControl
                            header="Czujnik sufitowy:"
                            now_L={ceilingReport.L}
                            now_R={ceilingReport.R}
                            status_L={ceilingReport.LS}
                            status_R={ceilingReport.RS}
                            max={getMaxDistanceSettings(config.distanceSensorTypes.ceiling_ID, settings, "MS")}
                            active={getActiveDistanceSettings(config.distanceSensorTypes.ceiling_ID, settings)}
                            label_L={ceilingReport.L + "cm"}
                            label_R={ceilingReport.R + "cm"}
                        />
                    </Row>
                </React.Fragment>
            )}

            {isMK(devType) && cashReport.s3 !== undefined && (
                <React.Fragment>
                    {isMK(devType) && <hr></hr>}
                    <Row className="my-2">
                        <DistanceShowControlNew4Way
                            header="Czujnik kasjer:"
                            s1_now={cashReport.s1}
                            s2_now={cashReport.s2}
                            s3_now={cashReport.s3}
                            s4_now={cashReport.s4}
                            s1_status={cashReport.s1_s}
                            s2_status={cashReport.s2_s}
                            s3_status={cashReport.s3_s}
                            s4_status={cashReport.s4_s}
                            max={getMaxDistanceSettings(config.distanceSensorTypes.cashier_ID, settings, "MK")}
                            active={getActiveDistanceSettings(config.distanceSensorTypes.cashier_ID, settings)}
                            blocked={getBlockedSensorsSettings(config.distanceSensorTypes.cashier_ID, settings)}
                            s1_label={cashReport.s1 + "cm"}
                            s2_label={cashReport.s2 + "cm"}
                            s3_label={cashReport.s3 + "cm"}
                            s4_label={cashReport.s4 + "cm"}
                        />
                    </Row>
                </React.Fragment>
            )}

            {isMK(devType) && cashReport.s3 === undefined && (
                <React.Fragment>
                    {isMK(devType) && <hr></hr>}
                    <Row className="my-2">
                        <DistanceShowControl
                            header="Czujnik kasjer:"
                            now_L={cashReport.L}
                            now_R={cashReport.R}
                            status_L={cashReport.LS}
                            status_R={cashReport.RS}
                            max={getMaxDistanceSettings(config.distanceSensorTypes.cashier_ID, settings, "MK")}
                            active={getActiveDistanceSettings(config.distanceSensorTypes.cashier_ID, settings)}
                            label_L={cashReport.L + "cm"}
                            label_R={cashReport.R + "cm"}
                        />
                    </Row>
                </React.Fragment>
            )}

            {isMK(devType) && clientReport.s3 !== undefined && (
                <React.Fragment>
                    {isMK(devType) && <hr></hr>}
                    <Row className="my-2">
                        <DistanceShowControl4Way
                            header="Czujnik klient:"
                            s1_now={clientReport.s1}
                            s2_now={clientReport.s2}
                            s3_now={clientReport.s3}
                            s4_now={clientReport.s4}
                            s1_status={clientReport.s1_s}
                            s2_status={clientReport.s2_s}
                            s3_status={clientReport.s3_s}
                            s4_status={clientReport.s4_s}
                            max={getMaxDistanceSettings(config.distanceSensorTypes.client_ID, settings, "MK")}
                            active={getActiveDistanceSettings(config.distanceSensorTypes.client_ID, settings)}
                            blocked={getBlockedSensorsSettings(config.distanceSensorTypes.client_ID, settings)}
                            s1_label={clientReport.s1 + "cm"}
                            s2_label={clientReport.s2 + "cm"}
                            s3_label={clientReport.s3 + "cm"}
                            s4_label={clientReport.s4 + "cm"}
                        />
                    </Row>
                </React.Fragment>
            )}
            {/* moduł kasy */}
            {isMK(devType) && clientReport.s3 === undefined && (
                <Row className="my-2">
                    <DistanceShowControl
                        header="Czujnik klient:"
                        now_L={clientReport.L}
                        now_R={clientReport.R}
                        status_L={clientReport.LS}
                        status_R={clientReport.RS}
                        max={getMaxDistanceSettings(config.distanceSensorTypes.client_ID, settings, "MK")}
                        active={getActiveDistanceSettings(config.distanceSensorTypes.client_ID, settings)}
                        label_L={clientReport.L + "cm"}
                        label_R={clientReport.R + "cm"}
                    />
                </Row>
            )}

            {/* moduł kasy */}
            {(isMK(devType) || isMS(devType)) && (
                <Row className="my-2">
                    <Col
                        sm="2"
                        style={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        {"Blokada: " + (dt < dtInSettings ? dtInSettings - dt : 0) + "s"}
                    </Col>

                    <Col sm="10">
                        <ProgressBar
                            animated
                            now={dt < dtInSettings ? dtInSettings - dt : 0}
                            max={dtInSettings}
                            variant={"secondary"}
                            style={{height: "20px"}}
                        />
                    </Col>
                </Row>
            )}

            {/* moduł kasy */}
            {(isMK(devType) || isMS(devType)) && (
                <Row className="my-2">
                    <Col sm="3">
                        <ConfirmationButton
                            label={dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmLabel}
                            yesLabel={
                                dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmYesLabel
                            }
                            noLabel={
                                dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmNoLabel
                            }
                            variant="warning"
                            className="py-0 width-max"
                            size="sm"
                            onClick={() => onAction({action: "sensor_reset"})}
                        >
                            Reset czujników
                        </ConfirmationButton>
                        {/* <Button
              variant="warning"
              className="py-0 width-max"
              size="sm"
              onClick={() =>
                onAction({
                  action: "sensor_reset",
                })
              }
            >
              Reset czujników
            </Button> */}
                    </Col>

                    <Col sm="9" className="mx-auto my-0" style={{textAlign: "right"}}>
                        <ConfirmationButton
                            label={dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmLabel}
                            yesLabel={
                                dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmYesLabel
                            }
                            noLabel={
                                dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmNoLabel
                            }
                            style={{backgroundColor: "#48B8D0", borderColor: "#48B8D0"}}
                            className="py-0 width-max"
                            size="sm"
                            onClick={() =>
                                onAction({
                                    action: "beep",
                                    id: config.beepTypes.beepDetectionScenerio1_ID,
                                })
                            }
                        >
                            Detekcja
                        </ConfirmationButton>
                        {/* <Button
              variant="info"
              className="py-0 width-max"
              size="sm"
              onClick={() =>
                onAction({
                  action: "beep",
                  id: config.beepTypes.beepDetectionScenerio1_ID,
                })
              }
            >
              Detekcja
            </Button> */}
                    </Col>
                </Row>
            )}

            {(isMD(devType)) && (
                <Row className="my-2">


                    <Col sm="12" className="mx-auto my-0" style={{textAlign: "right"}}>
                        <ConfirmationButton
                            label={dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_doseConfirmLabel}
                            yesLabel={
                                dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_doseConfirmYesLabel
                            }
                            noLabel={
                                dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_doseConfirmNoLabel
                            }
                            style={{backgroundColor: "#48B8D0", borderColor: "#48B8D0"}}
                            className="py-0 width-max"
                            size="sm"
                            onClick={() =>
                                onAction({
                                    action: "dose"
                                })
                            }
                        >
                            Przetestuj
                        </ConfirmationButton>

                    </Col>
                </Row>
            )}

            <Row className="my-2">
                <Col sm="12">
                    <ConfirmationButton
                        label={dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmLabel}
                        yesLabel={
                            dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmYesLabel
                        }
                        noLabel={
                            dictionary.shopView.cardDevice.liveDeviceVisualisation.demoButton_confirmNoLabel
                        }
                        variant="secondary"
                        className="py-0 width-max"
                        size="sm"
                        onClick={() =>
                            onAction({
                                action: "device_reset",
                            })
                        }
                    >
                        Restart
                    </ConfirmationButton>
                    {/* <Button
            variant="secondary"
            className="py-0 width-max"
            size="sm"
            onClick={() =>
              onAction({
                action: "device_reset",
              })
            }
          >
            Restart
          </Button> */}
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default LiveDeviceVisualisation;
