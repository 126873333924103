import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";

import * as AuthDeviceService from "../../../services/authDeviceService";
import SimpleSelect from "../../common/simpleSelect";

import ShopContext from "../../../contexts/shopContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

export default function CardDeviceTagView({ device, refreshData, allTags }) {
  const shopContext = useContext(ShopContext);

  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setTags(device?.tags);
  }, [device]);

  const handleSaveChanges = async () => {
    try {
      const { data } = await AuthDeviceService.updateTags({
        _id: device._id,
        tags,
      });

      refreshData();
      setEdit(false);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const handleDelete = (_id) => {};

  const handleEdit = (newInfo) => {
    // if (newInfo.length < 100) setInfo(newInfo);
  };

  //   const infoDiffrent = info !== device.info;

  const prepareOptions = () => {
    const { tags } = shopContext;

    const result = tags.filter((tagFromAll) => {
      const exist = tagFromAll.dev_types.find((type) => type === device.dev_type);
      if (exist) {
        if (device.tags.find((tag) => tag === tagFromAll._id)) return false;
        return true;
      }
      return false;
    });

    return result;
  };

  const addTag = () => {
    if (selectedTag === "") return false;

    const result = device.tags.find((tag) => tag === selectedTag);
    if (result) return false;

    try {
      const tags = device.tags;
      tags.push(selectedTag);

      const { data } = AuthDeviceService.updateTags({
        _id: device._id,
        tags,
      });
      refreshData();
      setEdit(false);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  const removeTag = (element) => {
    const index = device.tags.findIndex((tag) => tag === element);
    if (index === -1) return;

    const tags = _.cloneDeep(device.tags);

    try {
      tags.splice(index, 1);

      const { data } = AuthDeviceService.updateTags({
        _id: device._id,
        tags,
      });
      refreshData();
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  return (
    <React.Fragment>
      <div className="tag-container">
        <div className="me-2 my-auto fw-bold h-100">Tagi:</div>
        {device.tags.map((tag) => {
          const result = shopContext.tags.find((tagFromAll) => tagFromAll._id === tag);
          if (!result) return null;
          return (
            <div className="tag" key={result._id}>
              <div className="tag__name">{result.name}</div>
              {edit && (
                <FontAwesomeIcon
                  onClick={() => removeTag(result._id)}
                  className="tag__delete"
                  icon={faTimes}
                />
              )}
            </div>
          );
        })}
        <FontAwesomeIcon onClick={() => setEdit(!edit)} className="tag__edit" icon={faEdit} />
      </div>
      {shopContext.tags.length && edit ? (
        <div className="tag-container flex-container__center w-100 mb-3">
          <SimpleSelect
            style={{ width: "200px" }}
            value={selectedTag}
            optionName="name"
            optionKey="_id"
            options={prepareOptions()}
            onChange={(e) => setSelectedTag(e.target.value)}
          />

          <FontAwesomeIcon
            onClick={addTag}
            className="tag__add ms-1"
            size="2x"
            icon={faPlusSquare}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
}
