import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {toast} from "react-toastify";

import CardDeviceHeader from "./cardDevice/cardDeviceHeader";
import CardDeviceButtonsPanel from "./cardDevice/cardDeviceButtonsPanel";
import CardDeviceNameView from "./cardDevice/cardDeviceNameView";
import CardDeviceTagView from "./cardDevice/cardDeviceTagView";
import CardDeviceWifiView from "./cardDevice/cardDeviceWifiView";

import CardDeviceFooter from "./cardDevice/cardDeviceFooter";
import BeepTableHeader from "./confControl/beepSettingsTableHeader";
import BeepSettingsControl from "./confControl/beepSettingsControl";
import DistanceSettingsBlock from "./confControl/distanceSettingsBlock";
import DetectionTimeoutSettingsControl from "./confControl/detectionTimeoutSettingsControl";

import * as beepOnChange from "../../utils/conf/beepOnChangeActions";
import * as sensorSettingsOnChange from "../../utils/conf/distanceSensorSettingsOnChangeAction";

import * as wsCommunication from "../../utils/wsCommunication";

import * as AuthDeviceService from "../../services/authDeviceService";
import * as deviceConf from "../../utils/conf/deviceConf";

import config from "../../config.json";
import CardDeviceAlwaysActiveView from "./cardDevice/cardDeviceAlwaysActiveView";
import {activeRangeMK} from "../../utils/conf/distanceSensorSettingsOnChangeAction";
import {DistanceSensorTableGenerateAndFillWithDataMK} from "../../utils/conf/deviceConf";

class CardDeviceMK extends Component /* ({ device, online, connectionStatus })  */ {
    state = {
        expanded: false,
        beepTable: [],
        distanceSensorTable: [],
        detectionTimeout: 120,
        sensorBlockedDetectionTimeout: 20,
        clientDetectionTimeout: 2,
    };

    componentDidMount = () => {
        const {device} = this.props;
        this.updateSettings(device);
    };

    updateSettings = (device) => {
        const beepTable = deviceConf.beepTableGenerateAndFillWithData(
            [
                config.beepTypes.beep1_ID,
                config.beepTypes.beep2_ID,
                config.beepTypes.beep3_ID,
                config.beepTypes.beep4_ID,
                config.beepTypes.beep5_ID,
                config.beepTypes.beep6_ID,
                config.beepTypes.beepDetectionScenerio1_ID,
            ],
            device,
            this.props.maDevices,
            this.props.files
        );
        const distanceSensorTable = deviceConf.DistanceSensorTableGenerateAndFillWithDataMK(
            [config.distanceSensorTypes.cashier_ID, config.distanceSensorTypes.client_ID],
            device
        );

        const detectionTimeout = deviceConf.DetectionTimeoutFillWithData(device);
        const sensorBlockedDetectionTimeout =
            deviceConf.sensorBlockedDetectionTimeoutFillWithData(device);
        const clientDetectionTimeout = deviceConf.clientDetectionTimeoutFillWithData(device);
        this.setState({
            beepTable,
            distanceSensorTable,
            detectionTimeout,
            sensorBlockedDetectionTimeout,
            clientDetectionTimeout,
        });
    };

    // handleAudioDevChange = (audioDev, id) => {
    //   this.setState({
    //     beepTable: beepOnChange.audioDev(this.state.beepTable, audioDev, id),
    //   });
    // };
    handleBlockSensorChange = (vlSensorNo, value, id) => {
        // console.log("Spk "+spk+" sn: "+audioDevSn+" value: "+value+ " id: "+id)
        this.setState({
            distanceSensorTable: sensorSettingsOnChange.blockSensor(
                this.state.distanceSensorTable,
                value,
                vlSensorNo,
                id
            ),
        });
    };

    handleFileChange = (file, id) => {
        this.setState({
            beepTable: beepOnChange.file(this.state.beepTable, file, id),
        });
    };

    handleSpkChange = (spk, audioDevSn, value, id) => {
        // console.log("Spk "+spk+" sn: "+audioDevSn+" value: "+value+ " id: "+id)
        this.setState({
            beepTable: beepOnChange.spk(this.state.beepTable, audioDevSn, spk, value, id),
        });
    };

    handleEnableChange = (audioDevSn, value, id) => {
        this.setState({
            beepTable: beepOnChange.enable(this.state.beepTable, audioDevSn, value, id),
        });
    };

    handleActiveMinRangeChange = (sensorNo,value, id) => {
        this.setState({
            distanceSensorTable: sensorSettingsOnChange.activeMinRangeMK(
                this.state.distanceSensorTable,
                sensorNo,
                Number(value),
                id
            ),
        });
    };

    handleActiveRangeChange = (sensorNo, value, id) => {
        this.setState({
            distanceSensorTable: sensorSettingsOnChange.activeRangeMK(
                this.state.distanceSensorTable,
                sensorNo,
                Number(value),
                id
            ),
        });
    };

    handleSensorEnableChange = (value, id) => {
        this.setState({
            distanceSensorTable: sensorSettingsOnChange.enable(this.state.distanceSensorTable, value, id),
        });
    };

    handleDetectionTimeoutChange = (value) => {
        this.setState({
            detectionTimeout: Number(value),
        });
    };

    handleSensorBlockedDetectionTimeoutChange = (value) => {
        this.setState({
            sensorBlockedDetectionTimeout: Number(value),
        });
    };

    handleClientDetectionTimeoutChange = (value) => {
        this.setState({
            clientDetectionTimeout: Number(value),
        });
    };

    handleSendConfiguration = async () => {
        const {
            beepTable,
            distanceSensorTable,
            detectionTimeout,
            sensorBlockedDetectionTimeout,
            clientDetectionTimeout,
        } = this.state;

        const conf = {
            beepTable,
            distanceSensorTable,
            dt: detectionTimeout,
            bdt: sensorBlockedDetectionTimeout,
            cdt: clientDetectionTimeout,
        };
        try {
            const {data} = await AuthDeviceService.postConf({
                _id: this.props.device._id,
                conf,
            });

            wsCommunication.forceStatusMessage(this.props.device.sn, this.props.onMessageToSent);
            toast.success("Nowe ustawienia zostały zapisane!");

            this.updateSettings(data);
        } catch (err) {
            toast.error("Błąd komunikacji z serwerem");
        }
    };

    handleDemoAction = (data) => {
        const {beepTable, distanceSensorTable} = this.state;

        wsCommunication.demoMessage(
            this.props.device.sn,
            data,
            {beepTable, distanceSensorTable},
            this.props.onMessageToSent
        );
    };

    handleDelete = async () => {
        const {data} = await AuthDeviceService.remove(this.props.device._id);

        this.props.forceDataRefresh();
    };

    handleExpand = () => {
    };

    render() {
        const {device, onlineDeviceData} = this.props;
        const {
            expanded,
            beepTable,
            distanceSensorTable,
            detectionTimeout,
            sensorBlockedDetectionTimeout,
            clientDetectionTimeout,
        } = this.state;

        const detectionAction = beepTable.find(
            (beep) => beep.id === config.beepTypes.beepDetectionScenerio1_ID
        );

        return (
            <Card className="mb-2 cursor-pointer" style={{minWidth: "800px"}}>
                <CardDeviceHeader
                    expanded={expanded}
                    onlineDeviceData={onlineDeviceData}
                    device={device}
                    settings={{
                        distanceSensorTable: distanceSensorTable,
                        dt: detectionTimeout,
                    }}
                    onAction={this.handleDemoAction}
                    onExpandedClick={() => {
                        this.setState({expanded: ~expanded});
                    }}
                />

                {expanded ? (
                    <React.Fragment>
                        <Card.Body>
                            <CardDeviceNameView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceNameView>
                            <CardDeviceAlwaysActiveView
                                device={device}
                                refreshData={this.props.forceDataRefresh}>
                            </CardDeviceAlwaysActiveView>
                            <CardDeviceTagView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceTagView>
                            <CardDeviceWifiView
                                device={device}
                                refreshData={this.props.forceDataRefresh}
                            ></CardDeviceWifiView>

                            <hr style={{width: "100%", margin: "auto", background: "black"}}></hr>

                            <Table responsive="xs" style={{width: "90%", margin: "auto"}}>
                                <BeepTableHeader/>
                                <tbody>
                                {[
                                    config.beepTypes.beep1_ID,
                                    config.beepTypes.beep2_ID,
                                    config.beepTypes.beep3_ID,
                                    config.beepTypes.beep4_ID,
                                    config.beepTypes.beep5_ID,
                                    config.beepTypes.beep6_ID,
                                ].map((x) => {
                                    const index = beepTable.findIndex((beep) => beep.id === x);

                                    return (
                                        <BeepSettingsControl
                                            device={device}
                                            value={beepTable[index]}
                                            id={beepTable[index].id}
                                            key={beepTable[index].id + "" + device.sn}
                                            label={`Komunikat ${beepTable[index].id}:`}
                                            // onAudioDevChange={this.handleAudioDevChange}
                                            onFileChange={this.handleFileChange}
                                            onSpkChange={this.handleSpkChange}
                                            onEnableChange={this.handleEnableChange}
                                        />
                                    );
                                })}
                                <BeepSettingsControl
                                    device={device}
                                    value={detectionAction}
                                    id={detectionAction.id}
                                    key={detectionAction.id}
                                    label={`Detekcja:`}
                                    // onAudioDevChange={this.handleAudioDevChange}
                                    onFileChange={this.handleFileChange}
                                    onSpkChange={this.handleSpkChange}
                                    onEnableChange={this.handleEnableChange}
                                />
                                </tbody>
                            </Table>

                            <hr style={{width: "90%", margin: "auto", background: "black"}}></hr>

                            <DistanceSettingsBlock
                                device={device}
                                onActiveRangeChange={this.handleActiveRangeChange}
                                onActiveMinRangeChange={this.handleActiveMinRangeChange}
                                onSensorEnableChange={this.handleSensorEnableChange}
                                onBlockSensorChange={this.handleBlockSensorChange}
                                sensorCashier={distanceSensorTable.find(
                                    (x) => x.id === config.distanceSensorTypes.cashier_ID
                                )}
                                sensorClient={distanceSensorTable.find(
                                    (x) => x.id === config.distanceSensorTypes.client_ID
                                )}
                            />

                            <DetectionTimeoutSettingsControl
                                label="Minimalny czas przerwy pomiędzy detektowanymi zdarzeniami [s]:"
                                device={device}
                                min="10"
                                max="10000"
                                value={detectionTimeout}
                                onDetectionTimeoutChange={this.handleDetectionTimeoutChange}
                            />

                            <DetectionTimeoutSettingsControl
                                label="Czas detekcji zablokowanego czujnika kasjera/klienta [s]:"
                                device={device}
                                min="5"
                                max="10000"
                                value={sensorBlockedDetectionTimeout}
                                onDetectionTimeoutChange={this.handleSensorBlockedDetectionTimeoutChange}
                            />

                            <DetectionTimeoutSettingsControl
                                label="Opóżnienienie reakcji na detekcję klienta [s]:"
                                device={device}
                                min="0"
                                max="100"
                                value={clientDetectionTimeout}
                                onDetectionTimeoutChange={this.handleClientDetectionTimeoutChange}
                            />

                            <CardDeviceButtonsPanel
                                onDelete={this.handleDelete}
                                onSendConfiguration={this.handleSendConfiguration}
                            />
                        </Card.Body>
                    </React.Fragment>
                ) : null}

                <CardDeviceFooter
                    device={device}
                    onlineDeviceData={onlineDeviceData}
                    onChangeConf={this.handleDemoAction}
                />
            </Card>
        );
    }
}

export default CardDeviceMK;
