import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";

import CardDeviceHeader from "./cardDevice/cardDeviceHeader";
import CardDeviceButtonsPanel from "./cardDevice/cardDeviceButtonsPanel";
import CardDeviceNameView from "./cardDevice/cardDeviceNameView";
import CardDeviceTagView from "./cardDevice/cardDeviceTagView";
import CardDeviceWifiView from "./cardDevice/cardDeviceWifiView";

import CardDeviceFooter from "./cardDevice/cardDeviceFooter";
import BeepTableHeader from "./confControl/beepSettingsTableHeader";
import BeepSettingsControl from "./confControl/beepSettingsControl";
import DistanceSettingsBlock from "./confControl/distanceSettingsBlock";
import DetectionTimeoutSettingsControl from "./confControl/detectionTimeoutSettingsControl";

import * as beepOnChange from "../../utils/conf/beepOnChangeActions";
import * as sensorSettingsOnChange from "../../utils/conf/distanceSensorSettingsOnChangeAction";

import * as wsCommunication from "../../utils/wsCommunication";

import * as AuthDeviceService from "../../services/authDeviceService";
import * as deviceConf from "../../utils/conf/deviceConf";

import config from "../../config.json";
import CardDeviceAlwaysActiveView from "./cardDevice/cardDeviceAlwaysActiveView";

class CardDeviceMS extends Component /* ({ device, online, connectionStatus })  */ {
  state = {
    expanded: false,
    beepTable: [],
    distanceSensorTable: [],
    detectionTimeout: 120,
  };

  componentDidMount = () => {
    const { device } = this.props;

    this.updateSettings(device);
  };

  updateSettings = (device) => {
    const beepTable = deviceConf.beepTableGenerateAndFillWithData(
      [config.beepTypes.beepDetectionScenerio1_ID],
      device,
      this.props.maDevices,
      this.props.files
    );
    const distanceSensorTable = deviceConf.DistanceSensorTableGenerateAndFillWithData(
      [config.distanceSensorTypes.ceiling_ID],
      device
    );
    const detectionTimeout = deviceConf.CeilingDetectionTimeoutFillWithData(device);
    this.setState({ beepTable, distanceSensorTable, detectionTimeout });
  };

  // handleAudioDevChange = (audioDev, id) => {
  //   this.setState({
  //     beepTable: beepOnChange.audioDev(this.state.beepTable, audioDev, id),
  //   });
  // };

  handleFileChange = (file, id) => {
    this.setState({
      beepTable: beepOnChange.file(this.state.beepTable, file, id),
    });
  };

  // handleSpkChange = (spk, value, id) => {
  //   this.setState({
  //     beepTable: beepOnChange.spk(this.state.beepTable, spk, value, id),
  //   });
  // };
  handleSpkChange = (spk, audioDevSn, value, id) => {
    // console.log("Spk "+spk+" sn: "+audioDevSn+" value: "+value+ " id: "+id)
    this.setState({
      beepTable: beepOnChange.spk(this.state.beepTable, audioDevSn, spk, value, id),
    });
  };

  handleEnableChange = (audioDevSn, value, id) => {
    this.setState({
      beepTable: beepOnChange.enable(this.state.beepTable, audioDevSn, value, id),
    });
  };

  handleActiveMinRangeChange = (value, id) => {
    this.setState({
      distanceSensorTable: sensorSettingsOnChange.activeMinRange(
        this.state.distanceSensorTable,
        value,
        id
      ),
    });
  };

  handleActiveRangeChange = (value, id) => {
    this.setState({
      distanceSensorTable: sensorSettingsOnChange.activeRange(
        this.state.distanceSensorTable,
        value,
        id
      ),
    });
  };

  handleSensorEnableChange = (value, id) => {
    this.setState({
      distanceSensorTable: sensorSettingsOnChange.enable(this.state.distanceSensorTable, value, id),
    });
  };

  handleDetectionTimeoutChange = (value) => {
    this.setState({
      detectionTimeout: Number(value),
    });
  };

  handleSendConfiguration = async () => {
    const { beepTable, distanceSensorTable, detectionTimeout } = this.state;

    const conf = { beepTable, distanceSensorTable, dt: detectionTimeout };

    try {
      const { data } = await AuthDeviceService.postConf({
        _id: this.props.device._id,
        conf,
      });

      wsCommunication.forceStatusMessage(this.props.device.sn, this.props.onMessageToSent);

      toast.success("Nowe ustawienia zostały zapisane!");

      this.updateSettings(data);
    } catch (err) {
      toast.error("Błąd komunikacji z serwerem");
    }
  };

  handleDemoAction = (data) => {
    const { beepTable, distanceSensorTable } = this.state;

    wsCommunication.demoMessage(
      this.props.device.sn,
      data,
      { beepTable, distanceSensorTable },
      this.props.onMessageToSent
    );
  };

  handleDelete = async () => {
    const { data } = await AuthDeviceService.remove(this.props.device._id);

    this.props.forceDataRefresh();
  };

  handleExpand = () => {};

  render() {
    const { device, onlineDeviceData } = this.props;
    const { expanded, beepTable, distanceSensorTable, detectionTimeout } = this.state;

    const detectionAction = beepTable.find(
      (beep) => beep.id === config.beepTypes.beepDetectionScenerio1_ID
    );

    return (
      <Card className="mb-2 cursor-pointer" style={{ minWidth: "800px" }}>
        <CardDeviceHeader
          expanded={expanded}
          onlineDeviceData={onlineDeviceData}
          device={device}
          settings={{
            distanceSensorTable: distanceSensorTable,
            dt: detectionTimeout,
          }}
          onAction={this.handleDemoAction}
          onExpandedClick={() => {
            this.setState({ expanded: ~expanded });
          }}
        />

        {expanded ? (
          <React.Fragment>
            <Card.Body>
              <CardDeviceNameView
                device={device}
                refreshData={this.props.forceDataRefresh}
              ></CardDeviceNameView>
              <CardDeviceAlwaysActiveView
                  device={device}
                  refreshData={this.props.forceDataRefresh}>
              </CardDeviceAlwaysActiveView>
              <CardDeviceTagView
                device={device}
                refreshData={this.props.forceDataRefresh}
              ></CardDeviceTagView>
              <CardDeviceWifiView
                device={device}
                refreshData={this.props.forceDataRefresh}
              ></CardDeviceWifiView>

              <hr style={{ width: "100%", margin: "auto", background: "black" }}></hr>

              <Table responsive="xs" style={{ width: "90%", margin: "auto" }}>
                <BeepTableHeader />
                <tbody>
                  <BeepSettingsControl
                    device={device}
                    value={detectionAction}
                    id={detectionAction.id}
                    key={detectionAction.id}
                    label={`Detekcja:`}
                    // onAudioDevChange={this.handleAudioDevChange}
                    onFileChange={this.handleFileChange}
                    onSpkChange={this.handleSpkChange}
                    onEnableChange={this.handleEnableChange}
                  />
                </tbody>
              </Table>

              <hr style={{ width: "90%", margin: "auto", background: "black" }}></hr>

              <DistanceSettingsBlock
                device={device}
                onActiveRangeChange={this.handleActiveRangeChange}
                onMaxRangeChange={this.handleActiveMinRangeChange}
                onSensorEnableChange={this.handleSensorEnableChange}
                sensorCeiling={distanceSensorTable.find(
                  (x) => x.id === config.distanceSensorTypes.ceiling_ID
                )}
              />

              <DetectionTimeoutSettingsControl
                label="Minimalny czas przerwy pomiędzy detektowanymi zdarzeniami:"
                device={device}
                value={detectionTimeout}
                onDetectionTimeoutChange={this.handleDetectionTimeoutChange}
              />

              <CardDeviceButtonsPanel
                onDelete={this.handleDelete}
                onSendConfiguration={this.handleSendConfiguration}
              />
            </Card.Body>
          </React.Fragment>
        ) : null}

        <CardDeviceFooter
          device={device}
          onlineDeviceData={onlineDeviceData}
          onChangeConf={this.handleDemoAction}
        />
      </Card>
    );
  }
}

export default CardDeviceMS;
