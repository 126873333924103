import React, { Component } from "react";
import Button from "react-bootstrap/Button";

import UTable from "./common/table";

import { prepareDate } from "../utils/dateFormat";

class UnauthDevicesTable extends Component {
  columns = [
    {
      path: "sn",
      label: "SN",
    },
    { path: "dev_type", label: "Typ" },
    {
      path: "last_seen",
      label: "Ostatnio widziany",
      content: (device) => {
        
        return <div>{prepareDate(device.last_seen)}</div>;
      },
    },
    { path: "fw_version", label: "FW" },
    { path: "hw_version", label: "HW" },
    {
      path: "ssid",
      label: "SSID",
    },
    // {
    //   path: "shop",
    //   label: "Nr. sklepu",
    //   content: (device) => {
    //     let result = "";
    //     this.props.shops.map((shop) => {
    //       if(device.ssid === shop.ssid) result = shop.name;
    //       return 0;
    //     });
    //
    //     return result;
    //   },
    // },
    // {
    //   path: "",
    //   key: "Add",
    //   content: (device) => (
    //     <Button
    //       variant="primary"
    //       className="my-0"
    //       size="sm"
    //       onClick={() => this.props.onAdd(device)}
    //     >
    //       Dodaj
    //     </Button>
    //   ),
    // },
    {
      path: "",
      key: "delete",
      content: (device) => (
        <Button
          variant="danger"
          className="my-0"
          size="sm"
          onClick={(event) => this.props.onDelete(event, device)}
        >
          Usuń
        </Button>
      ),
    },
  ];
  render() {
    const { unauthDevices, sortColumn, onSort, onRowClick } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={unauthDevices}
        dataId="_id"
        onRowClick={onRowClick}
      />
    );
  }
}

export default UnauthDevicesTable;
