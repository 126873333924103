import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import AddPlaylistForm from "../AddPlaylistForm";

function AddPlaylist({show,playlist, onClose, addPlaylist, forceRefresh}) {




    return (

        <Modal show={show}  size={"lg"} fullscreen={"lg-down"} onHide={onClose} className={"py-0 m-0"} >
            <Modal.Header style={{border: "0px"}}>
                <Modal.Title>Dodaj nowa playlistę</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"py-0 m-0"}>
                {/*<Form.Control type="text" value={playlistName} onChange={(e) => handlePlaylistNameChange(e.target.value)}/>*/}
                <AddPlaylistForm id={playlist ? playlist._id : "new"} onClose={onClose} forceRefresh={forceRefresh} />
            </Modal.Body>
            <Modal.Footer className={"d-flex justify-content-start align-items-stretch border-0 "} style={{ width: "700px"}}>

            </Modal.Footer>
        </Modal>

    );
}

export default AddPlaylist;