import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle as yesPicture,
  faCircle as noPicture,
} from "@fortawesome/free-solid-svg-icons";
import { identity } from "lodash";

import ConfirmationButton from "./common/confirmationButton";
import dictionary from "../PL.json";

import { isAdmin } from "../services/authService";
import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

export default class ShopsImportTable extends Component {
  columns = [
    { key: "id", label: "" },
    { key: "isChecked", content: () => this.generateSelectAllCheckbox() },
    { key: "inDb", label: "Obecny" },
    { key: "name", label: "Numer Sklepu" },
    { key: "city", label: "Miasto" },
    { key: "adress", label: "Ulica" },
    { key: "postCode", label: "Kod pocztowy" },
    { key: "pon", label: "Poniedziałek" },
    { key: "wt", label: "Wtorek" },
    { key: "sr", label: "Środa" },
    { key: "czw", label: "Czwartek" },
    { key: "pt", label: "Piątek" },
    { key: "sb", label: "Sobota" },
    { key: "nd", label: "Niedziela" },
    { key: "update", label: "Zapisz" },
  ];

  tdStyle = {
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0px",
  };

  generateSelectAllCheckbox = () => {
    return (
      <input
        onChange={() => this.props.onAllSelect()}
        type="checkbox"
        checked={this.props.allSelected}
      />
    );
  };

  generateTd = (data) => {
    const style = { ...this.tdStyle };

    if (data.diff !== null && data.diff !== undefined) {
      if (data.diff) {
        style.backgroundColor = "#b30707";
        style.color = "#ffffff";
        style.fontWeight = "bold";
      }
    }

    return <td style={style}>{data.new}</td>;
  };

  generateTdForHours = (data) => {
    const style = { ...this.tdStyle };

    if (data.diff !== null && data.diff !== undefined) {
      if (data.diff) {
        style.backgroundColor = "#b30707";
        style.color = "#ffffff";
        style.fontWeight = "bold";
      }
    }

    return (
      <td style={style}>
        <div style={style}>
          <div className="p-0 m-0">{data.new[0]}</div>
          <div className="p-0 m-0">{data.new[1]}</div>
        </div>
      </td>
    );
  };

  render() {
    let i = 0;

    return (
      <ConfirmationModalContextProvider>
        <div className="table-responsive">
          <table className="table table-striped table-bordered cursor-pointer text-block">
            {/* <thead>
              <tr>
                {this.props.cols.map(c => (
                  <th key={c.key}>{c.name}</th>
                ))}
              </tr>
            </thead> */}
            <thead>
              <tr>
                {this.columns.map((column) => {
                  return (
                    <th
                      key={column.key}
                      // onClick={() => this.raiseSort(column.path)}
                      scope="col"
                      // className="clickable"
                      style={{ textAlign: "center" }}
                    >
                      {column.label
                        ? column.label
                        : column.content
                        ? column.content()
                        : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((r) => {
                let inDb = false;
                if (r.inDb !== null && r.inDb !== undefined) {
                  if (r.inDb) inDb = true;
                }
                i++;
                let index = i - 1;
                return (
                  <tr key={r.name}>
                    <td style={this.tdStyle}>{i}</td>
                    <td
                      style={this.tdStyle}
                      onClick={() => this.props.onSelectToModify(index)}
                    >
                      <input
                        onChange={() => null}
                        type="checkbox"
                        checked={r.isChecked}
                      />
                    </td>
                    <td style={this.tdStyle}>
                      {inDb ? (
                        <FontAwesomeIcon
                          icon={yesPicture}
                          size="1x"
                          color={"green"}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={noPicture}
                          size="1x"
                          color={"red"}
                        />
                      )}
                    </td>
                    <td style={this.tdStyle}>{r.name}</td>
                    {this.generateTd(r.city)}
                    {/* <td style={this.tdStyle}>{r.city.new}</td> */}
                    {this.generateTd(r.adress)}
                    {this.generateTd(r.postCode ? r.postCode : "")}
                    {this.generateTdForHours(r.pn)}
                    {this.generateTdForHours(r.wt)}
                    {this.generateTdForHours(r.sr)}
                    {this.generateTdForHours(r.czw)}
                    {this.generateTdForHours(r.pt)}
                    {this.generateTdForHours(r.sb)}
                    {this.generateTdForHours(r.nd)}
                    <td style={this.tdStyle}>
                      {isAdmin() ? (
                         <ConfirmationButton
                         variant={inDb ? "warning" : "danger"}
                         label={dictionary.shopsImport.shopsImportTable.saveButton_confirmLabel}
                         yesLabel={dictionary.shopsImport.shopsImportTable.saveButton_confirmYesLabel}
                         noLabel={dictionary.shopsImport.shopsImportTable.saveButton_confirmNoLabel}
                         className="my-0"
                         size="sm"
                         onClick={(event) =>  this.props.onChangeOne(r)}
                       >
                         {inDb ? "Zapisz" : "Dodaj"}
                       </ConfirmationButton>
                       
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ConfirmationModalContextProvider>
    );
  }
}
