import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

class TableHeader extends Component {
  raiseSort = (path) => {
    const sortColumn = this.props.sortColumn;

    if (sortColumn.path === path) {
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    } else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }

    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;
    if (column.path !== sortColumn.path) return null;

    if (sortColumn.order === "asc")
      return <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faSortUp} />;

    if (sortColumn.order === "desc")
      return (
        <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faSortDown} />
      );
  };

  render() {
    const { columns } = this.props;

    return (
      <thead>
        <tr>
          {columns.map((column) => {
            return (
              <th
                key={column.path || column.key}
                onClick={() => this.raiseSort(column.path)}
                scope="col"
                className="clickable"
                style={{textAlign: "center"}}
              >
                {column.label}
                {this.renderSortIcon(column)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
