import React, { Component } from "react";
import { Redirect } from "react-router";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { toast } from "react-toastify";

import config from "../config.json";

import ApiNameView from "./common/apiNameView";

import ApiSettingsService from "../services/apiSettingsService";
import { isAdmin } from "../services/authService";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import _ from "lodash";

class ApiSettings extends Component {
  state = {
    apiSettings: [],
  };

  async componentDidMount() {
    await this.refreshData();
  }

  refreshData = async () => {
    try {
      const { data: apiSettings } = await ApiSettingsService.getAll();
      this.setState({ apiSettings });
    } catch (err) {
      toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  };


  render() {
    if (!isAdmin()) return <Redirect to="/shops" />;
    const { apiSettings } = this.state;

    return (
      <React.Fragment>
        <ConfirmationModalContextProvider>
          <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
            <Breadcrumb.Item
              style={{
                marginTop: 3,
                marginLeft: 3,
                marginBottom: 3,
                marginRight: 0,
              }}
              href="/"
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                marginTop: 3,
                marginLeft: 3,
                marginBottom: 3,
                marginRight: 0,
              }}
              active
            >
              Konfiguracja
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                marginTop: 3,
                marginLeft: 3,
                marginBottom: 3,
                marginRight: 0,
              }}
              active
            >
              Ustawienia API
            </Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            {config.apiSettings.map((apiModel) => {
              const apiSettingsFromState = apiSettings.find(
                (x) => x.what === apiModel.what
              );
              return (
                <ApiNameView
                  key={"apiNameView_"+apiModel.what}
                  api={
                    apiSettingsFromState ? apiSettingsFromState.value : null
                  }
                  enable={
                    apiSettingsFromState ? apiSettingsFromState.enable : false
                  }
                  model={apiModel}
                  onRefreshData={this.refreshData}
                ></ApiNameView>
              );
            })}
          </Container>
        </ConfirmationModalContextProvider>
      </React.Fragment>
    );
  }
}

export default ApiSettings;
