import React, {Component, useEffect, useState} from "react";
import {Redirect} from "react-router";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {toast} from "react-toastify";

import config from "../config.json";


import SettingsService from "../services/settingsService";
import {isAdmin} from "../services/authService";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";


import dictionary from "../PL.json";
import ConfirmationSwitch from "./common/confirmationSwitch";
import ConfirmationButton from "./common/confirmationButton";
import SimpleSelect from "./common/simpleSelect";
import {faSquare, faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function RestartSettings() {

    const [enableRestarts, setEnableRestarts] = useState(false);
    const [enableRestartsNew, setEnableRestartsNew] = useState(false);

    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [hourNew, setHourNew] = useState(0);
    const [minuteNew, setMinuteNew] = useState(0);


    const refreshData = async () => {
        try {
            const {data} = await SettingsService.getSetting("autoRestarts");
            const {name, enable, value} = data;
            console.log(name,enable,value)
            if(name !== undefined && enable !==undefined && value !== undefined) {
                setEnableRestarts(enable);
                setEnableRestartsNew(enable);
                setHour(value.hour);
                setHourNew(value.hour);
                setMinute(value.minute);
                setMinuteNew(value.minute);
            }

        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    const handleEnCheck = (e) => {
        setEnableRestartsNew(!enableRestartsNew);
    }

    const handleHourChange = (value) => {
        let result = value.split(":");
        if (result.length === 2) {
            if (Number(result[0]) >= 0 && Number(result[0]) <= 23 && Number(result[1]) >= 0 && Number(result[1]) <= 59) {
                setHourNew(result[0]);
                setMinuteNew(result[1]);
            }
        }
    }

    const handleActiveOrNot = () => {
        const style = {
            width: "auto",
            borderRadius: "8px",
            backgroundColor: "rgba(170,170,170,0.5)",
        };

        if (enableRestarts) style.backgroundColor = "rgba(171, 209, 181,0.5)";

        return style;
    };

    const handleSaveChanges = async () => {
        try {
            const result = await SettingsService.updateSetting("autoRestarts", {
                value: {
                    hour: hourNew,
                    minute: minuteNew,
                },
                enable: enableRestartsNew
            });

            refreshData();

        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    }

    if (!isAdmin()) return <Redirect to="/shops"/>;


    return (
        <React.Fragment>
            <ConfirmationModalContextProvider>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item
                        style={{
                            marginTop: 3,
                            marginLeft: 3,
                            marginBottom: 3,
                            marginRight: 0,
                        }}
                        href="/"
                    >
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        style={{
                            marginTop: 3,
                            marginLeft: 3,
                            marginBottom: 3,
                            marginRight: 0,
                        }}
                        active
                    >
                        Konfiguracja
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        style={{
                            marginTop: 3,
                            marginLeft: 3,
                            marginBottom: 3,
                            marginRight: 0,
                        }}
                        active
                    >
                        Ustawienia restartów
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Container>
                    <div className="py-3 my-3 " style={handleActiveOrNot()}>
                        <Row md="6" className="flex-container flex-container__center m-2"
                             style={{alignContent: "center"}}>
                            <Col style={{textAlign: "center"}}>
                                <Row className="mx-0 px-0 my-2 fw-bold h-100">
                                    <Col className="flex-container flex-container__center ">
                                        Godzina restartów:
                                    </Col>
                                </Row>
                            </Col>


                            <Col className="px-0" style={{textAlign: "center"}}>
                                <input
                                    style={{
                                        width: "100%",
                                        color: "black",
                                    }}
                                    value={hourNew.toString().padStart(2, "0") + ":" + minuteNew.toString().padStart(2, "0")}
                                    onChange={(e) => handleHourChange(e.currentTarget.value)}
                                    type={"time"}
                                ></input>
                            </Col>


                            <Col md={"1"} className="m-2" style={{textAlign: "center"}}>
                                <FontAwesomeIcon
                                    className={"mx-2 onCheck align-self-center "}
                                    onClick={handleEnCheck}
                                    icon={enableRestartsNew ? faSquareCheck : faSquare} size={"lg"}/>

                            </Col>
                            <Col className="p-0" style={{textAlign: "center"}}>
                                <ConfirmationButton
                                    label={dictionary.restartSettings.saveButton_confirmLabel}
                                    yesLabel={dictionary.restartSettings.saveButton_confirmYesLabel}
                                    noLabel={dictionary.restartSettings.saveButton_confirmNoLabel}
                                    variant="danger"
                                    className={"w-100"}
                                    size="sm"
                                    onClick={handleSaveChanges}
                                >
                                    Zapisz zmiany
                                </ConfirmationButton>

                            </Col>
                        </Row>
                    </div>
                    {/*<Form.Control type="text" value={folderName} onChange={(e) => handleFolderNameChange(e.target.value)}/>*/}
                </Container>
            </ConfirmationModalContextProvider>
        </React.Fragment>
    );

}

export default RestartSettings;
