import React from "react";
import Form from "react-bootstrap/Form";


const Check = ({ name, value, label, error, onChange, type, ...rest }) => {
  return (
    <Form.Group controlId={name} className="mt-3">
     
          <Form.Check
            type={type}
            name={name}
            onChange={onChange}
            checked={value}
            isValid={value && !error}
            isInvalid={error}
            label={label}
            {...rest}
          />
      
    </Form.Group>
  );
};

export default Check;
