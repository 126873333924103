import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ConfirmationButton from "./common/confirmationButton";

import dictionary from "../PL.json";
import { isAdminOrIsService, isAdmin } from "../services/authService";
import UTable from "./common/table";
import { prepareDate } from "../utils/dateFormat";

class AuthDevicesTable extends Component {
  renderTooltip = (props, device) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <div>H: {device?.meta?.heap}</div>
        <div>RSSI: {device?.meta?.rssi}</div>
        <div>Pamięć: {device?.meta?.msize}</div>
        <div className="fw-bold">Tags:</div>
        <div className="tag-container flex-container__center">
          {device.tags?.length > 0 &&
            device.tags.map((tag) => {
              const result = this.props.tags.find((x) => x._id === tag);
              if(!result) return null;

              return <div className="tag" key={result._id}>{result.name}</div>;
            })}
        </div>
      </div>
    </Tooltip>
  );

  columns = [
    {
      path: "sn",
      label: "SN",
      content: (device) => {
        let style = { color: "black" };
        if (device.conf_errors) {
          style.color = "red";
        }
        return <div style={style}>{device.sn}</div>;
      },
    },
    { path: "dev_type", label: "Typ" },
    // {
    //   path: "internal_id",
    //   label: "ID",
    // },
    {
      path: "last_seen",
      label: "Ostatnio widziany",
      content: (device) => {
        return <div>{prepareDate(device.last_seen)}</div>;
      },
    },
    { path: "fw_version", label: "FW" },
    { path: "hw_version", label: "HW" },
    {
      path: "meta",
      label: "IP",
      content: (device) => {
        let style = {};
        if(device.new_endpoint !== undefined && device.new_endpoint !==null)
        {
          style={backgroundColor: '#ABD69B'}
        }
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 0 }}
            overlay={(props) => this.renderTooltip(props, device)}
          >
            <div className="w-100 h-100" style={style} >{(device.meta) ? device.meta?.ip : "brak"}</div>
          </OverlayTrigger>
        );
      },
    },
    {
      path: "addedAt",
      label: "Dodany",
      content: (device) => {
        if (device.addedAt) {
          return <div>{prepareDate(device.addedAt)}</div>;
        } else {
          return null;
        }
      },
    },
    {
      path: "shop_name",
      label: "Nr. sklepu",
      // content: (device) => {
      //   if (this.props.shops.length === 0) return null;
      //   const shop = this.props.shops.filter(
      //     (shop) => shop._id === device.shop_id
      //   );
      //   if (shop.length !== 0) return shop[0].name;
      //   return null;
      // },
    },
    {
      path: "shop_city",
      label: "Miasto",
      // content: (device) => {
      //   if (this.props.shops.length === 0) return null;
      //   const shop = this.props.shops.filter(
      //     (shop) => shop._id === device.shop_id
      //   );
      //   if (shop.length !== 0) return shop[0].name;
      //   return null;
      // },
    },

    // {
    //   path: "",
    //   key: "Add",
    //   content: (device) => (
    //     <Button
    //       variant="primary"
    //       className="my-0"
    //       size="sm"
    //       onClick={() => this.props.onAdd(device)}
    //     >
    //       Dodaj
    //     </Button>
    //   ),
    // },
  ];

  checkedColumn = {
    path: "checked",
    label: "",
    content: (device) => {
      if (!this.props.checkedVisible) return null;
      return (
        <input
          onChange={(event) => this.props.onCheckClick(event, device)}
          type="checkbox"
          checked={device.checked ? true : false}
        />
      );
    },
  };

  deleteColumn = {
    path: "",
    key: "delete",
    content: (device) => (
      <ConfirmationButton
        label={dictionary.authDevices.authDevicesTable.deleteButton_confirmLabel}
        yesLabel={dictionary.authDevices.authDevicesTable.deleteButton_confirmYesLabel}
        noLabel={dictionary.authDevices.authDevicesTable.deleteButton_confirmNoLabel}
        variant="danger"
        className="my-0"
        size="sm"
        onClick={(event) => this.props.onDelete(event, device)}
      >
        {dictionary.authDevices.authDevicesTable.deleteButton_label}
      </ConfirmationButton>
    ),
  };

  detailsColumn = {
    path: "",
    key: "details",
    content: (device) => (
      <Button
        variant="primary"
        className="my-0"
        size="sm"
        onClick={(event) => this.props.onEdit(event, device)}
      >
        Szczegóły
      </Button>
    ),
  };

  constructor() {
    super();
    this.columns.push(this.detailsColumn);
    if (isAdminOrIsService()) {
      this.columns.push(this.deleteColumn);
    }
    if (isAdmin()) {
      this.columns.unshift(this.checkedColumn);
    }
  }

  render() {
    const { authDevices, sortColumn, onSort, onRowClick } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={authDevices}
        dataId="_id"
        // onRowClick={onRowClick}
      />
    );
  }
}

export default AuthDevicesTable;
