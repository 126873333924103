import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faFileAudio, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {prepareDate} from "../../utils/dateFormat";

function FileView({_id,no, name, date, onClick, selected, onDeleteFromPlaylist}) {
    // const [className, setClassName] = useState("file");
    //
    // useEffect(() => {
    //     setClassName( selected ? "selected_file" : "file");
    // },[selected]);
    const className = selected ? "selected_file" : "file";
    return (
        <div onClick={(e) => onClick(e, _id)} onContextMenu={(e) => onClick(e, _id)}
             className={className + " w-100 mx-auto border rounded-3 p-1 my-1 d-flex justify-content-start align-items-center"}>
            <div className={"mx-3"}>{no + "."}</div>
            <FontAwesomeIcon className={"mx-1"}
                             icon={faFileAudio} size={"2x"}/>
            <div className={" flex-grow-1 fs-6 text-start"}>{name}</div>
            {/*<div className={" me-2 fs-6 "} style={{fontSize: "1.2rem"}}>{`${Math.round(duration / 60)} min ${Math.round(duration % 60)} sek`}</div>*/}
            <div className={" me-2 fs-6 "} style={{fontSize: "1.2rem"}}>{prepareDate(date)}</div>

            {onDeleteFromPlaylist &&
                <FontAwesomeIcon
                    className={"mx-2 onDelete_trash"}
                    onClick={(e) => onDeleteFromPlaylist(e, _id)}
                    icon={faTrashAlt} size={"lg"}/>}
        </div>
    );
}

export default FileView;